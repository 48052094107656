import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Loader from '../Panel/loader';
import Rodal from 'rodal';
import { FilterList, Close } from '@material-ui/icons';
import Pagination from '../../Pagination';
import CurrencyFormat from 'react-currency-format';
import { getIdProfile } from '../../../../services/auth';
import { Dropdown } from "react-bootstrap";

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        business: false,
        delete: false,
        evaluation: false
    })
    const [data, setData] = useState([])
    const [dataDelete, setDataDelete] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showModalRespApi, setShowModalRespApi] = useState(false)
    const [showModalEvaluation, setShowModalEvaluation] = useState(false)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const [filter, setFilter] = useState({
        name: '',
        tax_code: false,
        type: false,
        active: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [idProfile, setIdProfile] = useState(null);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )

    const [evaluationListApi, setEvaluationListApi] = useState([]);
    const [evaluation, setEvaluation] = useState([]);

    const getCompanies = async () => {
        setLoaders({ ...loaders, business: true })

        try {
            let filters = '?'

            if (filter.name) {
                filters += `name=${filter.name}&`
            }
            if (filter.tax_code) {
                filters += `tax_code=${filter.tax_code}&`
            }
            if (filter.type) {
                filters += `type=${filter.type}&`
            }
            if (filter.active) {
                filters += `active=${filter.active}&`
            }

            filters = filters.substring(filters[0], filters.length - 1)
            let res

            if (filters !== '?') {
                res = await api.get(`/company${filters}`)
            } else {
                res = await api.get(`/company`)
            }
            setData(res.data)
            setCurrentPage(1)
            //console.log("list -> ", res.data);
        } catch (error) {
            console.log("error =>", error)
        }

        setLoaders({ ...loaders, business: false })
    }

    const deleteBusiness = async () => {
        setLoaders({ ...loaders, delete: true })

        try {
            const response = await api.delete(`/company/${dataDelete.id}`)
            setShowModal(false)
            setText(response.data.message)
            setIsError(false)
            setShowModalRespApi(true)
        } catch (err) {
            setShowModal(false)
            setText(err.response.data.message)
            setIsError(true)
            setShowModalRespApi(true)
        }

        setLoaders({ ...loaders, delete: false })
    }

    // const getEditCompany = async (data) => {
    //     let mdr_percent = data.mdr_percent + ' '
    //     let mdr_value = data.mdr_value + ' '
    //     data.mdr_percent = mdr_percent.replace('.', ',')
    //     data.mdr_percent = data.mdr_percent.replace(' ', '')
    //     data.mdr_value = mdr_value.replace('.', ',')
    //     data.mdr_value = data.mdr_value.replace(' ', '')
    //     // console.log(data)
    //     props.history.push('/edit-business', { data: data })
    // }

    const closeModalRespApi = async () => {
        setText('')
        setIsError(false)
        setShowModalRespApi(false)
        getCompanies()
    }

    const getEvaluation = async (companyId) => {
        setLoaders({ ...loaders, evaluation: false });
        try {
            const response = await api.get(`/company-evaluation/${companyId}`);
            setEvaluation(response.data);
            setShowModalEvaluation(true);
            setLoaders({ ...loaders, evaluation: true });
        } catch (err) {
            setLoaders({ ...loaders, evaluation: false });
            setShowModal(false);
            setText(err.response.data.message);
            setIsError(true);
            setShowModalRespApi(true);
        }
    }

    const updateEvaluation = async (companyId) => {
        setLoaders({ ...loaders, delete: true })
        try {
            handleEvaluationApi()
            const response = await api.put(`/company-evaluation/${companyId}`, evaluationListApi);
            setText(response.statusText)
            setShowModalEvaluation(false);
            setIsError(false)
            setShowModalRespApi(true)
        } catch (err) {
            setShowModalEvaluation(false);
            setText(err.response.data.message)
            setIsError(true)
            setShowModalRespApi(true)
        }

        setLoaders({ ...loaders, delete: false })
    }

    const handleRequiredEvaluation = (key, boolean) => {
        let evaluationField = {
            key: key,
            is_required: boolean === true ? 1 : 0,
            is_visible: null
        };
        let exitKey = evaluationListApi.some((item) => { return item.key === key ? true : false })
        if (!exitKey)
            setEvaluationListApi(((prevArray) => [...prevArray, evaluationField]))
        else {
            let index = evaluationListApi.findIndex((item) => { return item.key === key })
            evaluationListApi[index].is_required = evaluationField.is_required;
        }
        setEvaluation((prevValues) => {
            const newValues = [...prevValues];
            let index = newValues.findIndex((item) => { return item.key === key })
            newValues[index].is_required = evaluationField.is_required
            return newValues;
        })
    }

    function handleVisibleEvaluation(key, boolean) {
        let evaluationField = {
            key: key,
            is_required: null,
            is_visible: boolean === true ? 1 : 0
        };
        let exitKey = evaluationListApi.some((item) => { return item.key === key ? true : false })
        if (!exitKey)
            setEvaluationListApi(((prevArray) => [...prevArray, evaluationField]))
        else {
            let index = evaluationListApi.findIndex((item) => { return item.key === key })
            evaluationListApi[index].is_visible = boolean;
        }
        setEvaluation((prevValues) => {
            const newValues = [...prevValues];
            let index = newValues.findIndex((item) => { return item.key === key })
            newValues[index].is_visible = evaluationField.is_visible
            return newValues;
        })
    }

    function handleEvaluationApi() {
        evaluationListApi.map((item) => {
            if (item.is_required === null)
                item.is_required = 0;
            if (item.is_visible === null)
                item.is_visible = 0;
        });
    }

    useEffect(() => { getCompanies() }, [])
    useEffect(() => { setIdProfile(getIdProfile()) }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 5 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 5 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                />

                                <CurrencyFormat format="##.###.###/####-##" mask="_"
                                    placeholder="CNPJ"
                                    className="form-control"
                                    style={{ width: '150px', height: '35px', marginRight: 5 }}
                                    value={filter.tax_code}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, tax_code: formattedValue })
                                    }}
                                />

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '250px', height: '35px', marginRight: 5 }}
                                    onClick={(e) => setFilter({ ...filter, type: e.target.value })}
                                >
                                    <option value="">TODAS AS EMPRESAS</option>
                                    <option value="master">MASTERS</option>
                                    <option value="pc">PARCEIROS COMERCIAIS</option>
                                    <option value="pf">PARCEIROS FORNECEDORES</option>
                                </select>

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '190px', height: '35px' }}
                                    onClick={(e) => setFilter({ ...filter, active: e.target.value })}
                                >
                                    <option value="">TODAS AS EMPRESAS</option>
                                    <option value='true'>APENAS ATIVAS</option>
                                    <option value='false'>APENAS INATIVAS</option>
                                </select>

                                <button disabled={loaders.business} onClick={() => getCompanies()} style={{
                                    width: '150px',
                                    marginLeft: 10
                                }} type="button" className="btn btn-info">

                                    {
                                        loaders.business
                                            ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Processando...
                                            </>
                                            :
                                            'Pesquisar'

                                    }
                                </button>
                            </div>

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            {
                                loaders.business ?
                                    <div className="loader">
                                        <h5>
                                            <Loader />
                                            <br />
                                            Carregando Empresas...
                                        </h5>
                                    </div>
                                    :
                                    data.length > 0
                                        ?
                                        <section className="form-create-users" style={{ display: 'flex' }}>
                                            <label className="text-label-title"><i className="fas fa-building white"></i> &nbsp; Listagem de Empresas</label>
                                            {/* <div className="separator" /> */}
                                            <div className="row-forms" style={{ flexDirection: 'column' }}>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc">ID</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nome Fantasia</th>
                                                            <th className="sorting_asc">Tipo</th>
                                                            <th className="sorting_asc">Empresa Master</th>
                                                            <th className="sorting_asc">CNPJ</th>
                                                            <th className="sorting_asc">Estado</th>
                                                            <th className="sorting_asc">Ativo</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentPosts.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            row.company_type === 1 ? "Master" :
                                                                                row.company_type === 2 ? "Parceiro Comercial" :
                                                                                    row.company_type === 3 ? "Parceiro Fornecedor" :
                                                                                        "Não Identificado"
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {row.company !== null ? row.company.name : '---'}
                                                                    </td>
                                                                    <td className="sorting_asc">{row.tax_code}</td>
                                                                    <td className="sorting_asc">{row.state}</td>
                                                                    <td className="sorting_asc">{row.deleted_at ? "NÃO" : "SIM"}</td>
                                                                    <td className="sorting_asc" style={{ width: '100px' }}>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {
                                                                                    idProfile === '1' ?
                                                                                        <Dropdown.Item onClick={() => getEvaluation(row.id)}>
                                                                                            <i className="la la-list gray la-lg" /> Avaliações
                                                                                        </Dropdown.Item>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <Dropdown.Item onClick={() => props.history.push('/edit-business', { data: row })}>
                                                                                    <i className="la la-edit blue la-lg" /> Editar
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => { setDataDelete(row); setShowModal(true) }}>
                                                                                    {
                                                                                        row.deleted_at ? <div><i className="la la-check green la-lg" /> Ativar</div> : <div><i className="la la-times red la-lg" /> Remover</div>
                                                                                    }
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />

                                            </div>

                                        </section>
                                        :
                                        <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                            <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                        </section>

                            }
                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        dataDelete.deleted_at ?
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" />
                                                Tem certeza que deseja REATIVAR a Empresa
                                                <br />
                                                <h4 className="green">{dataDelete.name}</h4> ?
                                            </> :
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                                Tem certeza que deseja DESATIVAR essa Empresa?
                                                <br />
                                                <h4 className="red">{dataDelete.name}</h4>
                                            </>
                                    }

                                    <br />
                                    <button
                                        disabled={loaders.delete}
                                        onClick={() => deleteBusiness()}
                                        style={{ width: '25%' }}
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        {
                                            loaders.delete
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                dataDelete.deleted_at ? 'Sim, reativar' : 'Sim, desativar'

                                        }
                                    </button>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            {/* modal mensagem de retorno da api */}
                            < Rodal
                                visible={showModalRespApi}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => closeModalRespApi()}
                            >
                                <div className="close-rodal" onClick={() => { closeModalRespApi() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <div><i className="fas fa-times red fa-2x"></i></div>
                                            :
                                            <div><i className="far fa-check-circle green fa-2x"></i></div>
                                    }
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            {/* Mostrar as avaluações por empresa */}
                            <Rodal
                                visible={showModalEvaluation}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(70%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModalEvaluation(false)}

                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowModalEvaluation(false)
                                }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">Validações</div>
                                <div className="detal-modal">
                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                        <thead>
                                            <tr role='row'>
                                                <th className="sorting_asc"> Nome</th>
                                                <th className="sorting_asc"> Obrigátorio</th>
                                                <th className="sorting_asc"> Visível</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loaders.evaluation ?
                                                    evaluation.map((row, idx) => {
                                                        return (
                                                            <tr role="row" key={idx}>
                                                                <td className="left-align">{row.name}</td>
                                                                <td>
                                                                    {
                                                                        parseInt(row.is_required) === 1 ?
                                                                            <input
                                                                                type="checkbox"
                                                                                checked
                                                                                onChange={(e) => handleRequiredEvaluation(row.key, e.target.checked)}
                                                                            /> :
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(e) => handleRequiredEvaluation(row.key, e.target.checked)}
                                                                            />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row.is_visible === 1 ?
                                                                            <input
                                                                                type="checkbox"
                                                                                checked
                                                                                onChange={(e) => handleVisibleEvaluation(row.key, e.target.checked)}
                                                                            /> :
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(e) => handleVisibleEvaluation(row.key, e.target.checked)}
                                                                            />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* botoes */}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        onClick={() => updateEvaluation(evaluation[0].company)}
                                        style={{ marginLeft: 10, width: '120px', backgroundColor: '#5e72e4' }}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        Salvar
                                    </button>
                                    <button
                                        onClick={() => setShowModalEvaluation(false)}
                                        style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

