import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import Rodal from 'rodal'
import { FilterList, Close } from '@material-ui/icons'
import Pagination from '../../Pagination'

export default function App(props) {
    
    const title = 'Espaço';
    const titleName = `Gerenciar da ${title}`;
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        spaces: false,
        delete: false
    })
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState([])
    const [update, setUpdate] = useState({})
    const [filter, setFilter] = useState({
        name: '',
        tax_code: false,
        type: false,
        active: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const get = async () => {
        setLoaders({ ...loaders, spaces: true })

        try {
            let res = await api.get(`advertising/spaces`)
            setData(res.data.data);
            setCurrentPage(1);
        } catch (err) {
            console.log(err);
        }

        setLoaders({ ...loaders, spaces: false });
    }

    const deleteSpaces = async (id) => {
        setLoaders({ ...loaders, delete: true })
        try {
            let res = await api.delete(`advertising/spaces/${id}`);
            if(res.status === 200){
                setShowModal(false);
                get();
            }
            
        } catch (error) {
            console.log(error);
        }
        setLoaders({ ...loaders, delete: false })
    }

    useEffect(() => { get() }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">

                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                />

                                <button disabled={loaders.business} onClick={() => get()} style={{
                                    width: '150px',
                                    marginLeft: 10
                                }} type="button" className="btn btn-info">
                                    {
                                        loaders.business ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Processando...
                                            </>
                                            : 'Pesquisar'
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="menu-right">
                            <button
                                onClick={() => props.history.push('/create-advertising-spaces')}
                                style={{ marginLeft: 10, width: '150px', backgroundColor: '#5e72e4' }}
                                type="button" className="btn btn-success"
                            >
                                <i className="fas fa-plus" />&nbsp;&nbsp;Criar 
                            </button>
                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            {
                                loaders.spaces ?
                                    <div className="loader">
                                        <h5>
                                            <Loader />
                                            <br />
                                            Carregando {title}...
                                        </h5>
                                    </div>
                                    : data.length > 0 ?
                                        <section className="form-create-users" style={{ display: 'flex' }}>
                                            <label className="text-label-title"><i className="la la-handshake"></i>&nbsp;{titleName}</label>
                                            {/* <div className="separator" /> */}
                                            <div className="row-forms" style={{ flexDirection: 'column' }}>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nome</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Tipo</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Área</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Limite Semana</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Descrição</th>
                                                            <th className="sorting_asc">Ativo</th>
                                                            <th className="sorting_asc">Editar</th>
                                                            <th className="sorting_asc">Desativar</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentPosts.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.name ? row.name : 'SEM NOME'}</td>
                                                                    <td className="sorting_asc">{row.type}</td>
                                                                    <td className="sorting_asc">{row.area}</td>
                                                                    <td className="sorting_asc">{row.week_limit}</td>
                                                                    <td className="sorting_asc">{row.description}</td>
                                                                    <td className="sorting_asc">{row.active ? "SIM" : "NÃO"}</td>
                                                                    <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i
                                                                                onClick={() => props.history.push('/edit-advertising-spaces', { data: row })}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="la la la-edit blue la-2x"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        row.deleted_at ?
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setUpdate(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-check green la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setUpdate(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-times red la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />

                                            </div>
                                        </section>
                                        :
                                        <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                            <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                        </section>
                            }

                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        update.deleted_at ?
                                            <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" /> :
                                            <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                    }
                                    <p>Tem certeza que deseja alterar o estado do {title} {update.name}?</p>
                                    <br />

                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        update.deleted_at ?
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" />
                                                Tem certeza que deseja REATIVAR a {title}
                                                <br />
                                                <h4 className="green">{update.name}</h4> ?
                                            </> :
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                                Tem certeza que deseja DESATIVAR esse {title}?
                                                <br />
                                                <h4 className="red">{update.name}</h4>
                                            </>
                                    }

                                    <br />
                                    <button
                                        disabled={loaders.delete}
                                        onClick={() => deleteSpaces(update.id)}
                                        style={{ width: '25%' }}
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        {
                                            loaders.delete
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                update.deleted_at ? 'Sim, reativar' : 'Sim, desativar'
                                        }
                                    </button>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

