import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import api from '../../../../services/api'
import { login } from "../../../../services/auth"
import logo from '../../../../images/logo-01.png'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import "./login.scss"
import { version } from '../../../../../package.json'
import { Close } from '@material-ui/icons'

function SignIn(props) {

    const [email, setEmail] = useState('')
    const [emailForgotPassword, setEmailForgotPassword] = useState('')
    const [password, setPassword] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [text, setText] = useState('')
    const [updatePassword, setUpdatePassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isCloseContentModal, setIsCloseContentModal] = useState(true)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isCloseModalAtualize, setIsCloseModalAtualize] = useState(false)
    const [isError, setIsError] = useState(false)

    const handleSignIn = async () => {
        setIsLoader(true)
        setShowModal(false)
        setIsError(false)
        setText('')

        if (email === '' || password === '') {
            setShowModal(true)
            setIsLoader(false)
            setIsError(true)
            setText("ops, email ou senha incorretos!")
            return
        }

        try {
            const response = await api.post("/auth/login", { email, password })
            //console.log('response -> ', response.data)

            if (response.data.user.recovery_pass) {
                setIsCloseModalAtualize(true)
            } else if (response.data.access_token) {
                props.history.push("/panel")
                login(response.data.access_token, response.data.user)
            }
        } catch (err) {
            setIsLoader(true)
            setIsError(true)
            setShowModal(true)
            setText("Verifique suas credenciais: e-mail ou senha incorretos!")
        }

        setIsLoader(false)
    }

    const sendResetPass = async (email) => {
        setIsError(false)
        setText('')
        setIsLoader(true)

        try {
            const res = await api.post("/password/reset", { email })
            // console.log('response -> ', res.data)

            if (res.data) {
                setIsOpenModal(false)
                setText(res.data.success)
            }
        } catch (err) {
            setIsError(false)
            setText("Ocorreu um erro: ", err.getMessage())
        }
        setIsLoader(false)
        setShowModal(true)
        setIsLoader(false)
    }

    const refreshPass = async (email) => {
        setIsError(false)
        setText('')
        setIsLoader(true)

        try {
            const res = await api.post("/password/refresh", { email, updatePassword })
            // console.log('response -> ', res.data)

            if (res.data) {
                setPassword(updatePassword)
                setIsCloseModalAtualize(false)
                // handleSignIn()
                setText('Senha atualizada com sucesso. Por favor, acesse novamente.')
                setShowModal(true)
                setEmail('')
                setPassword('')
                setUpdatePassword('')
                setConfirmPassword('')
            }
        } catch (err) {
            setIsError(false)
            setText("Ocorreu um erro: ", err.getMessage())
        }
        setIsLoader(false)
        // setShowModal(true)
    }

    useEffect(() => {
        setIsCloseContentModal(true)
        // campos precisam estar preenchidos com ao menos 6 caracteres
        if (updatePassword.length >= 6 && confirmPassword.length >= 6) {
            // os dois campos tem que ter o mesmo valor
            if (updatePassword === confirmPassword) {
                setIsCloseContentModal(false)
            }
        }

        if (emailForgotPassword.length > 5) {
            setIsCloseContentModal(false)
        }
    }, [updatePassword, confirmPassword, emailForgotPassword])

  useEffect(() => {
    if (!isOpenModal) {
      setText('');
      setIsError(false);
      setIsLoader(false);
      setEmail('');
      setPassword('');
      setEmailForgotPassword('');
    }
  }, [isOpenModal])

  return (
    <div className="wrapper-login">
      <form className="form-login-screen" onSubmit={(e) => { e.preventDefault(); handleSignIn() }}>
        <div className="logo"><img src={logo} alt="Trix" /></div>
        <input
          type="email"
          placeholder="E-Mail..."
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="password"
          placeholder="Senha..."
          onChange={e => setPassword(e.target.value)}
          value={password}
        />

                <button onClick={() => handleSignIn()} disabled={isLoader} type="submit">
                    {
                        isLoader ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                &nbsp; Autenticando...
                            </>
                            : <><i className="fa fa-sign-in"></i>&nbsp;Entrar</>
                    }

                </button>
                <hr />

                <Link to="/login" onClick={e => setIsOpenModal(true)}>Esqueceu sua senha?</Link>

                <hr />
                <span className="version">Portal - Trix - v{version}</span>
            </form>

            {/* modal de retorno da senha */}
            <Rodal
                visible={showModal}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}
            >
                <div className="close-rodal">
                    <Close onClick={() => setShowModal(false)} fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="modal-error">
                    {
                        isError ? <div><i className="fas fa-times red fa-2x" /></div>
                            : <div><i className="far fa-check-circle green fa-2x" /></div>
                    }
                    <div>{text}</div>

                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                    <div  className="modal-error-buttons" />
                </div>
            </Rodal>

            {/* modal de esqueceu a senha */}
            <Rodal
                visible={isOpenModal}
                animation="flip"
                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                showCloseButton={true}
                onClose={() => (false)}
            >
                <div className="close-rodal" onClick={e => (false)}>
                    <Close onClick={e => setIsOpenModal(false)} fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                    <div className="content-modal">
                        {/* titulo */}
                        <div style={{ textAlign: "center", marginBottom: "15px" }}>
                            <h2 style={{ fontSize: "30px" }}>Esqueceu a senha?</h2>
                        </div>

                        <label style={{ fontWeight: 'bold' }}>Digite seu e-mail: </label>
                        <input
                            value={emailForgotPassword}
                            onChange={e => setEmailForgotPassword(e.target.value)}
                            type="email"
                            placeholder="exemplo@gmail.com"
                            minlength="4"
                            maxlength="43"
                        />
                    </div>

                    <div className="buttonsModal">
                        <button
                            className="buttonModal"
                            disabled={isCloseContentModal}
                            onClick={() => sendResetPass(emailForgotPassword)}
                        >
                            {
                                isLoader ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        &nbsp; Processando...
                                    </>
                                    : 'ENVIAR'
                            }
                        </button>
                        <button className="buttonModal" onClick={e => setIsOpenModal(false)}>
                            {
                                isLoader ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        &nbsp; Processando...
                                    </>
                                    : 'VOLTAR'
                            }
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                    <div className="modal-error-buttons" />
                </div>
            </Rodal>

            {/*modal atualizar senha  */}
            <Rodal
                visible={isCloseModalAtualize}
                animation="flip"
                // customStyles={{ width: '40%', height: 'calc(70%)', marginTop: '7%' }}
                customStyles={{ height: 360 }}
                showCloseButton={false}
            // onClose={() => (false)}
            >
                {/* <div className="close-rodal" onClick={() => (false)}>
          <Close onClick={() => setIsCloseModalAtualize(false)} fontSize="large" style={{ cursor: 'pointer' }} />
        </div> */}

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                    {/* titulo */}
                    <div className="content-modal" >
                        <div style={{ textAlign: 'center' }}>
                            <h2>Atualize sua senha</h2>
                        </div>

                        <label style={{ fontWeight: 'bold' }}>Digite sua nova senha: </label>
                        <input style={{ width: '100%' }}
                            type="password"
                            placeholder="Senha deve conter no mínimo 6 caracteres"
                            minlength="6"
                            required
                            value={updatePassword}
                            onChange={e => setUpdatePassword(e.target.value)}
                        />

                        <hr />

                        <label style={{ fontWeight: 'bold' }}>Confirmar senha: </label>
                        <input style={{ width: '100%' }}
                            type="password"
                            placeholder="Senha deve conter no mínimo 6 caracteres"
                            minlength="6"
                            required
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    {/* botões */}
                    <div className="buttonsModal" style={{ marginTop: '20px' }}>

                        <button
                            className="buttonModal"
                            disabled={updatePassword !== confirmPassword}
                            style={{
                                backgroundColor: '#1b1750',
                                color: '#fff',
                                padding: '0px 25px',
                                fontSize: '11px'
                            }}
                            onClick={() => { refreshPass(email) }}
                        >
                            ENVIAR
                        </button>

                        <button
                            className="buttonModal"
                            style={{
                                backgroundColor: '#1b1750',
                                color: '#fff',
                                padding: '0px 25px',
                                fontSize: '11px'
                            }}
                            onClick={e => setIsCloseModalAtualize(false)}
                        >
                            VOLTAR
                        </button>
                    </div>


                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                    <div className="modal-error-buttons" />
                </div>
            </Rodal>
        </div>
    )
}

export default withRouter(SignIn)
