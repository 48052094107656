import React, { useState, useEffect } from 'react'
// import Menu from '../Panel/menu'
// import api from '../../../../services/api'
// import Loader from '../Panel/loader'
// import { Dropdown } from "react-bootstrap"
// import Moment from 'moment'
// import utils from '../../../../utils/utils.js'
// import { Link } from 'react-router-dom'
// import Rodal from 'rodal'
// import { Close } from '@material-ui/icons'

export default function App(props) {

    // const [toggleMenu, setToggleMenu] = useState(false)
    // const [loaders, setLoaders] = useState({
    //     dataCredits: false
    // })
    // const [dataCredits, setDataCredits] = useState([])
    // const [isError, setIsError] = useState(false)
    // const [text, setText] = useState('')
    // const [showModal, setShowModal] = useState(false)

    // Moment.locale('pt')

    // const svg1 = (
    //     <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    //             <rect x="0" y="0" width="24" height="24"></rect>
    //             <circle fill="#000000" cx="5" cy="12" r="2"></circle>
    //             <circle fill="#000000" cx="12" cy="12" r="2"></circle>
    //             <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    //         </g>
    //     </svg>
    // )


    // const getCredits = async () => {
    //     setLoaders({ ...loaders, dataCredits: true })
    //     setIsError(false)

    //     try {

    //         const res = await api.get('/wallet-entry')
    //         console.log(`res`, res)
    //         setDataCredits(res.data)
    //     } catch (error) {
    //         setIsError(true)
    //         setText(error.response.data.message)
    //         console.log("error =>", error)
    //     }
    //     setLoaders({ ...loaders, dataCredits: false })
    // }

    // const toggleStatus = async (id, status) => {

    //     // setLoaders({ ...loaders, dataCredits: true })

    //     try {

    //         let change = [{
    //             id_credit: id,
    //             status: status
    //         }]

    //         const res = await api.post('/wallet-entry', { change })

    //         console.log('res => ', res)

    //         setText(res.data.message)
    //         setShowModal(true)

    //     } catch (error) {

    //         console.log("error =>", error)
    //     }

    //     getCredits()
    // }

    // useEffect(() => {

    //     getCredits()

    // }, [])

    return (
        <></>
    )
}

