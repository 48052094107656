import React, {useEffect, useState} from 'react'
import Menu from '../Panel/menu'
import {DateRange} from '@material-ui/icons'
import utils from '../../../../utils/utils.js'
import api from '../../../../services/api'
import ThreeDots from 'react-loader-spinner'
import Moment from 'moment'

export default function ReportInvoices(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [errors, setErroDiv] = useState([])
    const [data_inicial, setDateIni] = useState(utils.getDateBefore(7))
    const [data_final, setDateEnd] = useState(utils.getDate())
    const [cliente, setCliente] = useState('')
    const [exportar, setExportar] = useState('')
    const [loader, setLoader] = useState(false)
    const [list_export, setListExportFormatReportInvoices] = useState([])
    const [list_empresas, setListEmpresas] = useState([])
    const [link_file, setLinkFile] = useState('')

    const [loaders, setLoaders] = useState(false)
    const [data_report, setReportData] = useState([])

    const getDataInit = async () => {
        setListExportFormatReportInvoices(utils.listExportFormatReportInvoices())

        await api.get(`listing/company/with-invoices`)
            .then((response) => {
                setListEmpresas(response.data)
            })
    }

    const getData = async () => {
        setLoader(true)
        setReportData([])
        setErroDiv([])
        setLinkFile('')

        try {
            let data = {
                "date-start": Moment(data_inicial).format('YYYY-MM-DD'),
                "date-end": Moment(data_final).format('YYYY-MM-DD'),
            }

            if (cliente != '')
                data['customer'] = cliente

            if (exportar != '')
                data['export'] = exportar

            const res = await api.post(`/report/invoices`, data)
                .then((response) => {
                    response = response.data
                    setReportData(response.data)

                    if (response.link != '')
                        setLinkFile(response.link)
                })
                .catch(error => {
                    if (error.response.status == 422 && error.response.data.fields != undefined) {
                        let errors = Object.entries(error.response.data.fields).map(([k, v]) => {
                            return v[0]
                        });
                        setErroDiv(errors)
                    }
                })
        } catch (error) {

        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getDataInit()
        getData()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu}/>
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center align-left">

                            <select
                                className="form-control"
                                id="cliente"
                                defaultValue=""
                                style={{width: '35%', height: '35px', 'margin-right': '40px'}}
                                onChange={(e) => setCliente(e.target.value)}
                            >
                                <option value="">Todas as empresas</option>
                                {Object.keys(list_empresas).map(k => (<option value={k}>{list_empresas[k]}</option>))}
                            </select>

                            <select
                                className="form-control"
                                id="exportar"
                                defaultValue=""
                                style={{width: '30%', height: '35px', 'margin-right': '40px'}}
                                onChange={(e) => setExportar(e.target.value)}
                            >
                                <option value="">Exportar dados em...</option>
                                {Object.keys(list_export).map(k => (<option value={k}>{list_export[k]}</option>))}
                            </select>

                            <DateRange fontSize="large"/>
                            <input style={{width: '15%', height: '35px', 'margin-right': '10px'}}
                                   value={data_inicial}
                                   id="data_inicial"
                                   onChange={(e) => setDateIni(e.target.value)}
                                   type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy"/>

                            <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;

                            <input style={{width: '15%', height: '35px', 'margin-right': '40px'}}
                                   value={data_final}
                                   onChange={(e) => setDateEnd(e.target.value)}
                                   type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy"/>

                            <button type="button"
                                    className="btn btn-info"
                                    onClick={() => getData()}
                                    disabled={loader}
                                    style={{width: '100px', height: '38px'}}
                            >
                                {
                                    loader
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <i className="fas fa-search fa-rotate-90"></i>
                                }
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{padding: '1%'}}>
                            <section className="container-trix" style={{padding: '0.5%'}}>
                                <div className="row-forms" style={{height: '100%'}}>
                                    <div className="filters">
                                        <div className="grid">
                                            <div className="form-group margin-right">
                                                <label className="text-label green" style={{marginLeft: '0px', marginBottom: '5px'}}>Transações</label>
                                                <div className="separator-widget"/>
                                                <div className="balance"><i className="flaticon-381-network green "/>
                                                    &nbsp; R$ {data_report.map(item => item.total).reduce((prev, curr) => prev + curr, 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="form-group margin-left margin-right">
                                                <label className="text-label green" style={{marginLeft: '0px', marginBottom: '5px'}}>% MDR</label>
                                                <div className="separator-widget"/>
                                                <div className="balance"><i className="flaticon-381-network green "/>
                                                    &nbsp; R$ {data_report.map(item => item.total_mdr_percent_buyer).reduce((prev, curr) => prev + curr, 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="form-group margin-left margin-right">
                                                <label className="text-label green" style={{marginLeft: '0px', marginBottom: '5px'}}>R$ MDR</label>
                                                <div className="separator-widget"/>
                                                <div className="balance"><i className="flaticon-381-network green "/>
                                                    &nbsp; R$ {data_report.map(item => item.total_mdr_value_buyer).reduce((prev, curr) => prev + curr, 0).toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="container-trix" style={{marginTop: '20px', minHeight: '70%', justifyContent: 'center'}}>

                                <div className={errors.length == 0 ? ' invisible ' : 'alert alert-danger visible text-left'}>
                                    <div class="container">
                                        {errors.map((row) => <div>{row}</div>)}
                                    </div>
                                </div>

                                <div className={data_report.length > 0 ? ' invisible ' : 'alert alert-info visible text-left'}>
                                    <div class="container">
                                        A pequisa não retornou nenhum resultado!
                                    </div>
                                </div>

                                <div className={!link_file ? ' invisible ' : 'alert alert-success visible text-left'}>
                                    <div class="container">
                                        <a href={link_file} target='_blank'>
                                            <i className="flaticon-381-download white "/> Download do arquivo!
                                        </a>
                                    </div>
                                </div>

                                <div className={data_report.length == 0 ? ' invisible ' : ' row-forms '}>
                                    {
                                        loaders
                                            ?
                                            <div>
                                                Buscando Transações...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <div>
                                                {/* titulo */}
                                                <div className="row-forms" style={{flexDirection: 'row'}}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white "/>&nbsp;Relatório de Faturamento
                                                    </label>
                                                </div>

                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                    <tr role='row'>
                                                        <th className="">ID</th>
                                                        <th className="">Empresa (Seller)</th>
                                                        <th className="">Empresa (Buyer)</th>
                                                        <th className="">% MDR</th>
                                                        <th className="">R$ MDR</th>
                                                        <th className="">Transações</th>
                                                        <th className="">Dt. Fechamento</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        data_report.map((row, idx) =>
                                                            <tr>
                                                                <td>{row.id}</td>
                                                                <td>{row.company_seller_name}</td>
                                                                <td>{row.company_buyer_name}</td>
                                                                <td class='text-right'> R$ {(row.total_mdr_percent_buyer).toFixed(2)} ({(row.mdr_percent_buyer).toFixed(0)}%)</td>
                                                                <td class='text-right'> R$ {(row.total_mdr_value_buyer).toFixed(2)}</td>
                                                                <td class='text-right'> R$ {(row.total).toFixed(2)}</td>
                                                                <td>{Moment(row.date_payment).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                            </tr>
                                                        )

                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
