import React, { useState } from 'react'
import Menu from '../Panel/menu'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DateRange } from '@material-ui/icons'
import  utils from '../../../../utils/utils.js'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [dateIni, setDateIni] = useState(utils.getDate())
  const [dateEnd, setDateEnd] = useState(utils.getDate())
  const [loader, setLoaders] = useState(false)
  const [reports, setReports] = useState([
    {
      id: 1,
      name: 'SALDOS CONSUMIDOS',
      action: null
    },
    {
      id: 2,
      name: 'QT. DE PARCEIROS COMERCIAIS COM CRÉDITOS GERADOS',
      action: null
    },
    {
      id: 3,
      name: '10 FORNECEDORES COM MAIOR SAÍDA DE CRÉDITO',
      action: null
    },
    {
      id: 4,
      name: 'VALORES EM LOTES FECHADOS',
      action: null
    },
    {
      id: 5,
      name: 'VALORES EM LOTES ABERTO',
      action: null
    },
    {
      id: 6,
      name: 'FONECEDORES MAIS UTILIZADOS POR TRANSAÇÕES',
      action: null
    },

  ])

  const getReport = async (row) => {

    console.log(row)

  }

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">
          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">
              <DateRange fontSize="large" />
              <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                Selecione a aqui o período para pesquisa.</Tooltip>}>
                <input style={{ width: '15%', height: '35px' }}
                  value={dateIni}
                  id="dateIni"
                  type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              </OverlayTrigger>
              &nbsp;&nbsp;
              <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
              <input style={{ width: '15%', height: '35px' }}
                value={dateEnd}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <button type="button"
                className="btn btn-info"
                // onClick={() => getData()}
                disabled={loader}
                style={{ width: '150px', height: '38px' }}
              >
                {
                  loader
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>
            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>
          <div className="data">
            <div className="panel">
              <div className="container-dashboards" style={{ height: '70px' }}>
                <div className="title"> <i className="la la-file-excel-o fa-lg"></i>&nbsp;Relatório Master</div>
              </div>

              <div className="container-dashboards" style={{ marginTop: '20px' }}>
                <section className="form-create-users" style={{ display: 'flex', width: '100%' }}>
                  {
                    reports.map((row, idx) =>
                      <div key={idx} className="row-forms">
                        <div className="grid">
                          <div className="container" style={{ justifyContent: 'flex-start' }}>
                            <i className="la la-file-excel-o green fa-2x"></i>
                            &nbsp; {row.name}
                          </div>
                          <div className="container" style={{ justifyContent: 'flex-end' }}>
                            <button
                            key={idx}
                              onClick={() => getReport(row)} 
                              style={{ width: '40%', height: '38px' }} type="button" className="btn btn-info">
                              {
                                loader
                                  ?
                                  <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Processando...
                                  </>
                                  :
                                  <>
                                    <i className="la la-arrow-down white fa-lg"></i>
                                    Baixar
                                  </>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}