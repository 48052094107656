import React, { useState, useEffect } from 'react';
import "./panel.scss";
import "./panel.css";
import Menu from './menu';
import logo from '../../../../images/logo-01.png';

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const [banner, setBanner] = useState([]);

  const getBanners = () => {
    let bannersAux = JSON.parse(localStorage.getItem('COMPANY_ADVERTASIMENT'));
    if (bannersAux !== null) {
      setBanner(bannersAux);
      setLoader(true);
    }
  }

  function waitintBannerLoad(input) {
    if (!input)
      getBanners();
  }

  useEffect(() => { getBanners() }, [])

  waitintBannerLoad(loader)

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">
          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>
          <div className="data">
            <div className="panel" style={{ justifyContent: 'center' }}>
              <section className='panel-general-container'>
                <div className='row-forms'>
                  <div className='grid'>
                    {
                      loader ?
                        <>
                          <div className="form-group">{banner && banner[0] && <img src={banner[0].image} alt={banner[0].name} />}</div>
                          <div className="form-group"><img src={logo} alt="Trix" /></div>
                          <div className="form-group">{banner && banner[1] && <img src={banner[1].image} alt={banner[1].name} />}</div>
                        </>
                        :
                        <>
                          <div className="form-group"></div>
                          <div className="form-group"><img src={logo} alt="Trix" /></div>
                          <div className="form-group"></div>
                        </>
                    }
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
