import React, {useState, useEffect} from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import {Col, Container, Dropdown, Row} from "react-bootstrap"
import {FilterList, Close} from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'
import "./manage-items.css"
import {getCompanyType, getIdProfile} from "../../../../services/auth";
import FileInput from '../../../../utils/fileInput';

export default function App(props) {
    Moment.locale('pt')
    const [itens, setItens] = useState([])
    const [images, setImages] = useState([])
    const [companies, setCompanies] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        suppliers: false,
        saveItem: false,
        saveImage: false,
        subCategories: false,
        images: false
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false)
    const [showModalImage, setShowModalImage] = useState(false)
    const [showModalResp, setShowModalResp] = useState(false)
    const [showModalImportExcel, setShowModalImportExcel] = useState(false)
    const [text, setText] = useState('')
    const [titleButton, setTitleButton] = useState('')
    const [isError, setIsError] = useState('')
    const [isErrorFile, setIsErrorFile] = useState(null)
    const fields = {
        id: '',
        name: '',
        idSelectedCompany: '',
        idSelectedCategory: '',
        idSelectedSubCategory: '',
        sku: '',
        brand: '',
        idType: '',
        marketPrice: 0,
        trixPrice: 0,
        valueDiscount: 0,
        porcentageDiscount: 0,
        description: '',
        tamanho: '',
        cor: '',
        condicao: '',
        imagem: null,
        images: []
    }

    const [inputs, setInputs] = useState(fields)
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )
    const [title, setTitle] = useState('')
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentData = itens.slice(indexOfFirstPost, indexOfLastPost);
    const currentDataImages = images.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [styleImg, setStyleImg] = useState(null)
    const [styleBtn, setStyleBtn] = useState(null)
    const [filter, setFilter] = useState('')

    const [idItem, setIdItem] = useState('');
    const idField = 0;
    const [inputsImage, setInputsImage] = useState([])
    const fieldsImage = {
        id: '',
        active: true,
        description: '',
        image: ''
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const COMPANY_TYPE = localStorage.getItem('COMPANY_TYPE')

    const uploadImg = async () => {
        const inputFile = document.querySelector("#picture__input")
        const pictureImage = document.querySelector(".picture__image")
        const img = document.querySelector(".box__img")

        const pictureImageTxt = "Importar imagem"
        pictureImage.innerHTML = pictureImageTxt
        inputFile.addEventListener("change", async function (e) {
            const inputTarget = e.target;
            const file = inputTarget.files[0]

            if (file) {
                const reader = new FileReader()

                reader.addEventListener("load", function (e) {
                    const readerTarget = e.target

                    //const img = document.createElement("img")
                    img.src = readerTarget.result;
                    img.style.display = 'block';
                    //img.classList.add("picture__img")
                    setInputsImage({...inputsImage, image: img.src})
                    //pictureImage.innerHTML = ''

                    // pictureImage.appendChild(img)
                });
                reader.readAsDataURL(file)
            }
        });

    }

    const getItens = async () => {
        setLoaders({...loaders, users: true})
        setItens([])

        try {

            let filters = ''

            if (filter) {
                filters = `?status=${filter}`
            }

            const response = await api.get(`/item${filters}`)

            if (response.data.length > 0) {
                if (response.data) {
                    setItens(response.data)
                }
            }
        } catch (err) {
            console.error(err)
        }


        setLoaders({...loaders, users: false})
    }

    const getImages = async (item_id) => {
        setLoaders({...loaders, images: true})
        setImages([])
        clearIMG();
        try {

            const response = await api.get(`/item-images/${item_id}`)
            //console.log('response.data.data', response.data.data)

            if (response.data.data.length > 0) {
                if (response.data.data) {
                    setImages(response.data.data)
                }
            }
        } catch (err) {
            console.log(err)
        }

        setLoaders({...loaders, images: false})
    }

    const saveOrEditItem = async () => {
        setLoaders({...loaders, saveItem: true})
        setIsError(false)
        clearTexts()

        try {
            let res
            if (titleButton === 'Editar') {
                if (inputs.id === '') {
                    console.log('NÃO BUSCOU O ID DO ITEM')
                    return
                }

                console.table(inputs)

                res = await api.put(`/item/${inputs.id}`, {
                    name: inputs.name,
                    description: inputs.description,
                    brand: inputs.brand,
                    sku: inputs.sku,
                    id_company: inputs.idSelectedCompany,
                    id_category: inputs.idSelectedCategory,
                    id_sub_category: inputs.idSelectedSubCategory,
                    id_type: inputs.idType,
                    market_price: inputs.marketPrice,
                    trix_price: inputs.trixPrice,
                    size: inputs.tamanho,
                    color: inputs.cor,
                    condition: inputs.condicao,
                    image: inputs.imagem

                })
                setText('Item editado com sucesso')
            } else {
                res = await api.post('/item', {
                    name: inputs.name,
                    description: inputs.description,
                    brand: inputs.brand,
                    sku: inputs.sku,
                    id_company: inputs.idSelectedCompany,
                    id_category: inputs.idSelectedCategory,
                    id_sub_category: inputs.idSelectedSubCategory,
                    id_type: inputs.idType,
                    market_price: inputs.marketPrice,
                    trix_price: inputs.trixPrice,
                    size: inputs.tamanho,
                    color: inputs.cor,
                    condition: inputs.condicao,
                    image: inputs.imagem
                })
                setText('Item cadastrado com sucesso')
            }
            //console.log(`res=>`, res)
        } catch (err) {
            //console.log('err -> ', err)
            setIsError(true)
            setText('Não foi possivel cadastrar o item. Erro ' + err)
        } finally {
            setLoaders({...loaders, saveItem: false})
            setShowModalResp(true)
            setInputs(fields)
        }
    }

    const saveOrEditImage = async () => {
        setLoaders({...loaders, saveImage: true})
        setIsError(false)

        try {
            let res
            if (titleButton === 'Editar') {
                res = await api.put(`/item-images/${idItem}`, [{
                    id: inputsImage.id,
                    active: inputsImage.active,
                    image: inputsImage.image,
                    description: inputsImage.description
                }])
            } else {
                res = await api.post('/item-images', [{
                    id_item: idItem,
                    active: inputsImage.active,
                    image: inputsImage.image,
                    description: inputsImage.description
                }])
                //console.log(`res=>`, res)
            }
            if (res.status === 200) {
                setText('Operação realizada com sucesso!');
            } else
                setText(res.statusText);
        } catch (error) {
            //console.log('err -> ', error);
            setIsError(true);
            setText('Não foi possivel cadastrar a imagem. Erro ' + error);
        }
        setLoaders({...loaders, saveImage: true});
        setShowModalResp(true);
        handleCancelImage();
        handleImages(idItem);
    }

    const getCategories = async () => {
        setLoaders({...loaders, users: true})

        try {
            const response = await api.get(`/category?type=category`)
            setCategories(response.data)
        } catch (err) {
            console.log(err)
        }

        setLoaders({...loaders, users: false})
    }

    const getCompanies = async () => {
        setLoaders({...loaders, users: true})
        try {
            const response = await api.get(`listing/company/partner`)
            setCompanies(response.data)
        } catch (error) {
            console.error("Error: ", error);
        }
        setLoaders({...loaders, users: false})
    }

    const getSubCategories = async () => {
        setLoaders({...loaders, subCategories: true})

        try {
            const response = await api.get(`/category?type=subcategory&id=${inputs.idSelectedCategory}`)
            // console.log('response', response)
            setSubCategories(response.data)
        } catch (err) {
            console.log(err)
        }

        setLoaders({...loaders, subCategories: false})
    }

    const handleEdit = (row) => {
        setInputs({
            id: row.id,
            name: row.name,
            description: row.description,
            brand: row.brand,
            sku: row.sku,
            idSelectedCompany: row.id_company,
            idSelectedCategory: row.id_category,
            idSelectedSubCategory: row.id_sub_category,
            idType: row.id_type,
            marketPrice: row.market_price,
            trixPrice: row.trix_price,
            tamanho: row.size,
            cor: row.color,
            condicao: row.condition,
            imagem: row.image
        })
        showModalCreateOrEditItem(true, 'edit')
    }

    const handleEditImage = (row) => {
        fieldsImage.id = row.id;
        fieldsImage.active = row.active;
        fieldsImage.description = row.description;
        fieldsImage.image = row.image;

        const img = document.querySelector(".box__img");
        img.src = row.image;
        img.style.display = 'block';
        showModalCreateOrEditImages(true, 'edit')
    }

    const handleImages = async (idItem) => {
        await getImages(idItem);
        setIdItem(idItem);
        showModalCreateOrEditImages(true, 'create');
    }

    const handleRemove = async (id) => {
        setLoaders({...loaders, saveItem: true})
        setIsError(false)
        clearIMG()

        try {
            const res = await api.delete(`/item/${id}`)
            if (res) {
                setText('Item removido com sucesso')
            } else {
                console.log(`res=>`, res)
                setText('Não foi possível remover o item')
            }
        } catch (err) {
            console.log('err -> ', err)
            setIsError(true)
            setText('Não foi possivel remover o item. Erro ' + err)
        } finally {
            setLoaders({...loaders, saveItem: false})
            setShowModalResp(true)
            setInputs(fields)
        }
    }

    const handleRemoveImage = async (idImage) => {
        setLoaders({...loaders, saveImage: true})
        setIsError(false)
        clearIMG()

        try {
            const res = await api.delete(`/item-images/${idImage}`);
            if (res) {
                setText('Imagem removido com sucesso!')
            } else {
                console.log(`res=>`, res);
                setText('Não foi possível remover o Imagem!');
            }
        } catch (err) {
            console.log('err -> ', err)
            setIsError(true)
            setText('Não foi possivel remover o item. Erro ' + err)
        } finally {
            setLoaders({...loaders, saveImage: false});
            setShowModalResp(true);
            handleCancelImage();
            handleImages(idItem);
        }
    }

    function handleCancel() {
        showModalCreateOrEditItem(false)
        setInputs(fields)
        getItens()
        clearTexts()
    }

    function handleCancelImage() {
        showModalCreateOrEditImages(false);
        setInputsImage(fieldsImage);
        getItens();
        clearIMG();
    }

    function handleCalcDiscount() {
        if (inputs.marketPrice > 0 && inputs.trixPrice > 0) {
            const valueDiscount = (inputs.marketPrice - inputs.trixPrice).toFixed(2)
            const porcentage = Math.abs(100 - ((inputs.trixPrice / inputs.marketPrice) * 100)).toFixed(2)

            setInputs({...inputs, valueDiscount: valueDiscount, porcentageDiscount: porcentage})
        }
    }

    function showModalCreateOrEditItem(isShow, type = '') {
        if (isShow) {
            if (type === 'edit') {
                setTitle('Edição')
                setTitleButton('Editar')
            }
            if (type === 'create') {
                setTitle('Criação')
                setTitleButton('Salvar')
            }

            setShowModal(true)
        } else {
            setInputs(fields)
            setShowModal(false)
        }
    }

    function showModalCreateOrEditImages(isShow, type = '') {
        if (isShow) {
            if (type === 'edit') {
                setTitle('Edição');
                setTitleButton('Editar');
                setInputsImage(fieldsImage);
            }
            if (type === 'create') {
                setTitle('Criação')
                setTitleButton('Salvar');
                setShowModalImage(true);
            }
        } else {
            setInputsImage(fieldsImage);
            setShowModalImage(false);
        }
    }

    function showModalImport(isShow) {
        if (isShow) {
            setTitle('Importar excel')
            setTitleButton('Importar')
            setShowModalImportExcel(true)
        } else {
            setShowModalImportExcel(false)
            utils.refreshPage();
        }
    }

    const DownloadExcel = async () => {
        try {
            const response = await api.get(`/items/download`)
            if (response.data) {
                window.open(response.data["url-file"], "_self");
            }
        } catch (error) {
            setIsError(true);
            let msgError = '';
            let idx = 1;
            for (var member in error.response.data.errors) {
                msgError += '\n Erro' + [idx][member] + ':' + error.response.data.errors[member];
                idx++;
            }
            setText('Erro do download \n' + msgError ?? error.response.statusText);
            setShowModal(true);
        }
    }

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const uploadExcel = async () => {
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await api.post(`/items/upload`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            if (response.data) {
                // console.log(response.data);
                setIsErrorFile(false);
                setText(response.data.message);
                getItens();
            }
        } catch (error) {
            setIsErrorFile(true);
            let msgError = '';
            let idx = 1;
            if (error.response && error.response.data.errors.length > 0) {
                for (var member in error.response.data.errors) {
                    msgError += "\n\n" + error.response.data.errors[member];
                    idx++;
                }
            } else {
                msgError = error.response.statusText;
            }

            setText("Erro do download:" + msgError);

        }
    }

    function clearTexts() {
        setText('')
        setTitleButton('')
    }

    function clearIMG() {
        const img = document.querySelector(".box__img");
        img.url = '';
        img.style.display = 'none';
        setInputsImage({...inputsImage, image: ''});
    }

    useEffect(() => {
        setInputs({...inputs, image: itens.image})
    }, [])

    useEffect(() => {
        getItens()
        getCategories()
        getCompanies()
    }, [])

    useEffect(() => {
        handleCalcDiscount()
    }, [inputs.marketPrice, inputs.trixPrice])

    useEffect(() => {
        if (inputs.idSelectedCategory) {
            getSubCategories()
        }
    }, [inputs.idSelectedCategory])

    useEffect(() => {
        getItens()
        showModalCreateOrEditItem(false)
    }, [!showModalResp])

    useEffect(() => {
        if (inputs.imagem == null) {
            setStyleImg(null)
            setStyleBtn('none')
        } else {
            setStyleImg('none')
            setStyleBtn(null)
        }
    }, [inputs])

    useEffect(() => {
        // console.log('idSelectedCategory', inputs.idSelectedCategory)
    }, [inputs.idSelectedCategory])

    useEffect(() => {
        showModalCreateOrEditImages(false)
    }, [!showModalCreateOrEditItem])

    useEffect(() => {
        setInputsImage({...inputsImage, fieldsImage})
    }, [])

    useEffect(() => setIdItem({...idItem, idField}), []);

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu}/>
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu" style={{justifyContent: 'flex-start'}}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{height: '35px', marginLeft: 10}}>
                                        <FilterList fontSize="default" className="blue"/>
                                    </div>
                                </div>

                                {/* <select
                                    style={{ width: '15%' }}
                                    className="form-control"
                                    value={idSelectedCategory}
                                    onChange={(e) => { setIdSelectedCategory(e.target.value) }}
                                >
                                    <option value="">-- CATEGORIA -- </option>
                                    {
                                        categories.length > 0 &&
                                        categories.map((category) =>
                                            <option value={category.id}>{category.name}</option>
                                        )
                                    }
                                </select>

                                &nbsp;&nbsp;
                                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                                &nbsp;&nbsp;

                                {
                                    loaders.subCategories ?
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        :
                                        <select style={{ width: '15%' }} className="form-control">
                                            <option value="">-- SUB-CATEGORIA -- </option>
                                            {
                                                subCategories.length > 0 &&
                                                subCategories.map((subcat) =>
                                                    <option value={subcat.id}>{subcat.name}</option>
                                                )
                                            }
                                        </select>
                                }

                                &nbsp;&nbsp;
                                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                                &nbsp;&nbsp;
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    id="name"
                                    // value={inputs.name}
                                    // onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                    style={{ width: '15%' }}
                                    className="form-control"
                                    aria-describedby="inputGroupPrepend2"
                                    placeholder="NOME"
                                />
                                &nbsp;&nbsp;
                                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                                &nbsp;&nbsp; */}

                                <select
                                    style={{width: '15%'}}
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <option value="">-- STATUS --</option>
                                    <option value="">Todos</option>
                                    <option value="R">Rejeitado</option>
                                    <option value="A">Ativo</option>
                                    <option value="P">Pendente</option>
                                </select>

                                <button
                                    disabled={loaders.suppliers}
                                    onClick={() => getItens()}
                                    style={{marginLeft: 10, width: '120px'}}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.suppliers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            {
                                COMPANY_TYPE === "3" && (
                                    <>
                                        <button
                                            onClick={() => showModalImport(true)}
                                            style={{marginLeft: 10, width: '150px', backgroundColor: '#5e72e4'}}
                                            type="button" className="btn btn-info"
                                        >
                                            <i className="fas fa-upload"/>&nbsp;&nbsp;Importar
                                        </button>
                                        <button
                                            onClick={() => DownloadExcel(true)}
                                            style={{marginLeft: 10, width: '150px', backgroundColor: '#5e72e4'}}
                                            type="button" className="btn btn-info"
                                        >
                                            <i className="fas fa-download"/>&nbsp;&nbsp;Download
                                        </button>
                                    </>
                                )
                            }

                            <button
                                onClick={() => showModalCreateOrEditItem(true, 'create')}
                                style={{marginLeft: 10, width: '150px', backgroundColor: '#5e72e4'}}
                                type="button" className="btn btn-success"
                            >
                                <i className="fas fa-plus"/>&nbsp;&nbsp;Criar Item
                            </button>
                            &nbsp;&nbsp;<i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{padding: '1%'}}>
                            {/* grid item */}
                            <section className="container-trix" style={{marginTop: '20px', minHeight: '70%', justifyContent: 'center'}}>

                                <div className="row-forms">
                                    {
                                        loaders.suppliers
                                            ?
                                            <div>
                                                Buscando Itens...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{flexDirection: 'row'}}>
                                                    <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Gerenciar Itens</label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                    <tr role='row'>
                                                        {/*<th className="sorting_asc"> ID </th>*/}
                                                        {(getCompanyType() === '1' || getIdProfile() === '1') && (
                                                            <th className="sorting_asc"> Empresa</th>
                                                        )}
                                                        <th style={{textAlign: 'start'}} className="sorting_asc">Nome</th>
                                                        <th style={{textAlign: 'start'}} className="sorting_asc">Categoria</th>
                                                        <th style={{textAlign: 'start'}} className="sorting_asc">Sub-Categoria</th>
                                                        <th style={{textAlign: 'start'}} className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor eTrix</th>
                                                        <th className="sorting_asc">Valor Mercado</th>
                                                        <th className="sorting_asc">Status</th>
                                                        <th className="sorting_asc">Ações</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        currentData.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                {/*<td className="sorting_asc">{row.id}</td>*/}
                                                                {(getCompanyType() === '1' || getIdProfile() === '1') && (
                                                                    <td className="sorting_asc">{row.company.trading_name}</td>
                                                                )}
                                                                <td style={{textAlign: 'start'}}>{row.name}</td>
                                                                <td style={{textAlign: 'start'}}>
                                                                    {row.category ? row.category.name : ''}
                                                                </td>
                                                                <td style={{textAlign: 'start'}}>
                                                                    {row.sub_category ? row.sub_category.name : ''}
                                                                </td>
                                                                <td style={{textAlign: 'start'}}>{row.description}</td>
                                                                <td>
                                                                    <b style={{color: 'green'}}>R$ {utils.formatMoneyBRL(row.trix_price)}</b>
                                                                </td>
                                                                <td>R$ {utils.formatMoneyBRL(row.market_price)}</td>
                                                                <td>
                                                                    {
                                                                        row.active === 0 ?
                                                                            <div style={{color: 'orange'}}>
                                                                                <i className="la la-info-circle la-lg"/> Pendente
                                                                            </div>
                                                                            : row.active === 1 ?
                                                                                <div style={{color: 'green'}}>
                                                                                    <i className="la la-check-circle green la-lg"/> Aprovado
                                                                                </div>
                                                                                : <div style={{color: 'red'}}>
                                                                                    <i className="la la-times-circle red la-lg"/> Reprovado
                                                                                </div>
                                                                    }
                                                                </td>
                                                                <td className="sorting_asc" style={{width: '100px'}}>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            variant="success"
                                                                            className="light sharp i-false"
                                                                            style={{
                                                                                width: '150px',
                                                                                height: '20px'
                                                                            }}
                                                                        >
                                                                            {svg1}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => handleImages(row.id)}>
                                                                                <i className="la la-image gray la-lg"/> Imagens
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => handleEdit(row)}>
                                                                                <i className="la la-edit gray la-lg"/> Editar
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => handleRemove(row.id)}>
                                                                                <i className="la la-times-circle gray la-lg"/> Remover
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={itens.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                            </>
                                    }
                                </div>
                            </section>
                            {/* modal new item */}
                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%', height: '500px', marginTop: '1%', bottom: 'auto',
                                    top: '50%', transform: 'translateY(-50%)', overflowY: 'scroll', overflowX: 'none'
                                }}
                                showCloseButton={true}
                                onClose={() => showModalCreateOrEditItem(false)}
                            >
                                <div className="close-rodal" onClick={() => showModalCreateOrEditItem(false)}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>

                                <div className="title">{title} do Item</div>

                                <div className="detal-modal">
                                    <Container style={{marginBottom: '16px', textAlign: 'initial'}}>
                                        {(getCompanyType() === '1' || getIdProfile() === '1') && (
                                            <Row style={{marginBottom: '16px'}}>
                                                <Col>
                                                    <label className="text-label">Empresa</label>
                                                    <select
                                                        className="form-control"
                                                        value={inputs.idSelectedCompany}
                                                        onChange={(e) => setInputs({...inputs, idSelectedCompany: e.target.value})}
                                                    >
                                                        <option value="">-- EMPRESA --</option>
                                                        {Object.entries(companies).map(([id, company]) => (
                                                            <option key={id} value={id}>{company}</option>
                                                        ))}
                                                    </select>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row style={{marginBottom: '16px'}}>

                                            {/* titulo */}
                                            <Col>
                                                <label className="text-label">Titulo</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.name}
                                                    onChange={(e) => setInputs({...inputs, name: e.target.value})}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* categoria */}
                                            <Col>
                                                <label className="text-label">Categoria</label>
                                                <select
                                                    className="form-control"
                                                    value={inputs.idSelectedCategory}
                                                    onChange={(e) => setInputs({...inputs, idSelectedCategory: e.target.value})}
                                                >
                                                    <option value="">-- CATEGORIA --</option>
                                                    {
                                                        categories.length > 0 &&
                                                        categories.map((category) =>
                                                            <option value={category.id}>{category.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </Col>
                                            {/* subcategoria */}
                                            <Col>
                                                <label className="text-label">Sub-Categoria</label>
                                                <select
                                                    className="form-control"
                                                    value={inputs.idSelectedSubCategory}
                                                    onChange={(e) => setInputs({...inputs, idSelectedSubCategory: e.target.value})}
                                                >
                                                    <option value="">-- SUB-CATEGORIA --</option>
                                                    {
                                                        subCategories.length > 0 &&
                                                        subCategories.map((subcat) =>
                                                            <option value={subcat.id}>{subcat.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </Col>
                                        </Row>

                                        <Row style={{marginBottom: '16px'}}>
                                            {/* marca */}
                                            <Col>
                                                <label className="text-label">SKU</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.sku}
                                                    onChange={(e) => setInputs({...inputs, sku: e.target.value})}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            <Col>
                                                <label className="text-label">Marca</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.brand}
                                                    onChange={(e) => setInputs({...inputs, brand: e.target.value})}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* valor mercado */}
                                            <Col>
                                                <label className="text-label">Valor Mercado</label>
                                                <CurrencyFormat style={{color: '#4bc019'}}
                                                                currency="BRL"
                                                                prefix='R$ '
                                                                placeholder="Valor..."
                                                                thousandSeparator='.'
                                                                decimalSeparator=','
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className="form-control"
                                                                value={inputs.marketPrice}
                                                                onValueChange={(values) => {
                                                                    const {floatValue} = values
                                                                    setInputs({...inputs, marketPrice: floatValue > 0 ? floatValue : 0})
                                                                }}
                                                />
                                            </Col>
                                            {/* valor trix */}
                                            <Col>
                                                <label className="text-label">Valor Etrix</label>
                                                <CurrencyFormat style={{color: '#4bc019'}}
                                                                currency="BRL"
                                                                prefix='R$ '
                                                                placeholder="Valor..."
                                                                thousandSeparator='.'
                                                                decimalSeparator=','
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className="form-control"
                                                                value={inputs.trixPrice}
                                                                onValueChange={(values) => {
                                                                    const {floatValue} = values
                                                                    setInputs({...inputs, trixPrice: floatValue > 0 ? floatValue : 0})
                                                                }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row style={{marginBottom: '16px'}}>
                                            {/* tipo */}
                                            <Col>
                                                <label className="text-label">Tipo</label>
                                                <select
                                                    className="form-control"
                                                    value={inputs.idType}
                                                    onChange={(e) => setInputs({...inputs, idType: e.target.value})}
                                                >
                                                    <option value="">-- Tipo --</option>
                                                    <option value="1">Produto</option>
                                                    <option value="2">Serviço</option>
                                                </select>
                                            </Col>
                                            {/* valor desconto */}
                                            <Col>
                                                <label className="text-label">Valor Desconto</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.valueDiscount}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                    disabled
                                                />
                                                {/* <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    // thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    style={{ color: '#4bc019', fontSize: '1rem', fontWeight: '500' }}
                                                    value={inputs.valueDiscount}
                                                    // onValueChange={(values) => {
                                                    //     const { floatValue } = values
                                                    //     setInputs({ ...inputs, valueEtrix: floatValue > 0 ? floatValue : 0 })
                                                    // }}
                                                    disabled
                                                /> */}
                                            </Col>
                                            {/* percentual desconto */}
                                            <Col>
                                                <label className="text-label">% Desconto</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.porcentageDiscount}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            {/* tamanho */}
                                            {/* <Col>
                                                <label className="text-label">Tamanho</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.tamanho}
                                                    onChange={(e) => setInputs({ ...inputs, tamanho: e.target.value })}
                                                    className="form-control"
                                                />
                                            </Col> */}
                                            {/* cor */}
                                            {/* <Col>
                                                <label className="text-label">Cor</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.cor}
                                                    onChange={(e) => setInputs({ ...inputs, cor: e.target.value })}
                                                    className="form-control"
                                                />
                                            </Col> */}
                                            {/* Condição */}
                                            {/* <Col>
                                                <label className="text-label">Condição</label>
                                                <select
                                                    className="form-control"
                                                    value={inputs.condicao}
                                                    onChange={(e) => setInputs({ ...inputs, condicao: e.target.value })}
                                                >
                                                    <option value="">-- Condição -- </option>
                                                    <option value="novo">Novo</option>
                                                    <option value="semi">Seminovo</option>
                                                    <option value="usado">Usado</option>
                                                </select>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            {/* descrição */}
                                            <Col>
                                                <label style={{marginTop: '5px'}} className="text-label">Descrição</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    maxlength="300"
                                                    rows="12"
                                                    value={inputs.description}
                                                    onChange={(e) => setInputs({...inputs, description: e.target.value})}
                                                    style={{width: '100%', maxHeight: '200px', borderRadius: "5px"}}
                                                >
                                                    {inputs.description}
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <button
                                            onClick={() => saveOrEditItem(true)}
                                            style={{marginLeft: 10, width: '120px', backgroundColor: '#5e72e4'}}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            {titleButton}
                                        </button>
                                        <button
                                            onClick={handleCancel}
                                            style={{marginLeft: 10, width: '120px', backgroundColor: '#ff9900'}}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>
                            {/* modal new image item */}
                            <Rodal
                                visible={showModalImage}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%', height: '500px', marginTop: '1%', bottom: 'auto',
                                    top: '50%', transform: 'translateY(-50%)', overflowY: 'scroll', overflowX: 'none'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowModalImage(false)}
                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowModalImage(false)
                                }}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="title">{title} de imagens</div>
                                <div className="detal-modal">
                                    <Container style={{marginBottom: '16px', textAlign: 'initial'}}>
                                        <Row style={{marginBottom: '16px'}}>
                                            <Col className="container__image">
                                                <label className="picture" for="picture__input">
                                                    <span
                                                        className="picture__image"
                                                        style={{display: styleImg}}
                                                    >
                                                        Importar imagem
                                                    </span>
                                                </label>
                                                <input
                                                    onClick={() => {
                                                        uploadImg()
                                                    }}
                                                    type="file"
                                                    name="picture__input"
                                                    id="picture__input"
                                                />
                                                <i class="fa fa-trash" id="icon__trash"
                                                   onClick={() => clearIMG()}
                                                />
                                                <div className="div__img">
                                                    <img
                                                        className="box__img"
                                                        src={inputsImage.image}
                                                        style={{display: styleBtn}}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                {/* Descrição */}
                                                <label style={{marginTop: '5px'}} className="text-label">Descrição</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    maxlength="150"
                                                    rows="12"
                                                    value={inputsImage.description}
                                                    onChange={(e) => setInputsImage({...inputsImage, description: e.target.value})}
                                                    style={{width: '100%', maxHeight: '200px', borderRadius: "5px"}}
                                                >
                                                    {inputsImage.description}
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <button
                                            onClick={() => saveOrEditImage(true)}
                                            style={{marginLeft: 10, width: '120px', backgroundColor: '#5e72e4'}}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            {titleButton}
                                        </button>
                                        <button
                                            onClick={handleCancelImage}
                                            style={{marginLeft: 10, width: '120px', backgroundColor: '#ff9900'}}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="row-forms">
                                        {
                                            loaders.images
                                                ?
                                                <div>
                                                    Buscando Imagens...
                                                    <ThreeDots
                                                        color="#0ccbec"
                                                        height={40}
                                                        width={40}
                                                        type="ThreeDots"
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <div className="row-forms" style={{flexDirection: 'row'}}>
                                                        <label className="text-label-title"><i className="la la-image gray la-lg"></i>&nbsp;Gerenciar Imagens</label>
                                                    </div>
                                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                        <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc"> ID</th>
                                                            <th className="sorting_asc"> Imagem</th>
                                                            <th style={{textAlign: 'start', width: "100%"}} className="sorting_asc">Descrição</th>
                                                            <th style={{textAlign: 'start'}}>Ativo</th>
                                                            {/*  <th className="sorting_asc">Editar</th> */}
                                                            <th className="sorting_asc">Excluir</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {
                                                            currentDataImages.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td className="sorting_asc"><img style={{width: '50px'}} src={row.image}/></td>
                                                                    <td style={{textAlign: 'start'}}>{row.description}</td>
                                                                    <td style={{textAlign: 'start'}}>{row.active === 1 ? "SIM" : "NÃO"}</td>
                                                                    {/* <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i
                                                                                onClick={() => handleEditImage(row)}
                                                                                style={{cursor: 'pointer'}}
                                                                                className="la la la-edit blue la-2x"
                                                                            />
                                                                        </div>
                                                                    </td> */}
                                                                    <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i
                                                                                onClick={() => handleRemoveImage(row.id)}
                                                                                style={{cursor: 'pointer'}}
                                                                                className="la la la-trash red la-2x"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </table>
                                                    <Pagination
                                                        postsPerPage={postsPerPage}
                                                        totalPosts={itens.length}
                                                        paginate={paginate}
                                                        currentPage={currentPage}
                                                    />
                                                </>
                                        }
                                    </div>
                                </div>
                            </Rodal>
                            {/* modal mensagem retorno */}
                            <Rodal
                                visible={showModalResp}
                                animation="flip"
                                customStyles={{width: '50%', height: 'calc(35%)', marginTop: '10%'}}
                                showCloseButton={true}
                                onClose={() => setShowModalResp(false)}

                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowModalResp(false)
                                }}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <>
                                                <div><i className="fas fa-times error fa-2x"></i></div>
                                                <div> {text}</div>
                                            </>
                                            :
                                            <>
                                                <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                <div><i className="fas fa-coins green white"></i> {text}</div>
                                            </>
                                    }

                                </div>
                                <div style={{display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto'}}>
                                    <div className="modal-error-buttons"/>
                                </div>
                            </Rodal>
                            {/* modal import excel */}
                            <Rodal
                                visible={showModalImportExcel}
                                animation="flip"
                                customStyles={{width: '50%', height: 'calc(40%)', marginTop: '10%'}}
                                showCloseButton={true}
                                onClose={() => setShowModalImportExcel(false)}

                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowModalImportExcel(false)
                                }}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="title">{title}</div>
                                <div className="detal-modal">
                                    <Container style={{marginBottom: '16px', textAlign: 'initial'}}>
                                        <Row style={{marginBottom: '16px'}}>
                                            <FileInput onFileSelect={handleFileSelect}/>
                                        </Row>
                                        <div className="modal-error">
                                            {
                                                isErrorFile
                                                    ?
                                                    <>
                                                        <div><i className="fas fa-times error fa-2x"></i></div>
                                                        <div> {text}</div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            isErrorFile !== null
                                                                ?
                                                                <>
                                                                    <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                                    <div> {text}</div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Container>
                                </div>
                                {/* botoes */}
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button
                                        onClick={() => uploadExcel()}
                                        style={{marginLeft: 10, width: '120px', backgroundColor: '#5e72e4'}}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        {titleButton}
                                    </button>
                                    <button
                                        onClick={() => showModalImport(false)}
                                        style={{marginLeft: 10, width: '120px', backgroundColor: '#ff9900'}}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto'}}>
                                    <div className="modal-error-buttons"/>
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
