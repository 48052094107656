import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'
import utilsSelect from '../../../../utils/selects'


export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const [data] = useState(props.location.state.data)
    const [filteredData, setFilteredData] = useState({})
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isSaveSuccess, setSaveSuccess] = useState(false)
    const [list_select_yes_or_no, setListYesOrNo] = useState([])
    const title = 'Grupo';
    const titleName = `Edição da ${title}`;

    const edit = async () => {
        setLoader(true)
        setIsError(false)

        try {
            const res = await api.put(`/advertising/groups/${filteredData.id}`, {
                name: filteredData.name,
                description: filteredData.description,
                active: filteredData.active,
            })

            setText(res.data.message ?? res.statusText);
            setShowModal(true);
            setSaveSuccess(true);

        } catch (error) {
            setIsError(true);
            let msgError = '';
            let idx = 1;
            for (var member in error.response.data.errors) {
                msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
                idx++;
            }
            setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
            setShowModal(true);
        }
        setLoader(false)
    }

    useEffect(() => {
        setFilteredData({
            id: data.id,
            name: data.name,
            description: data.description,
            active: data.active
        })
    }, [data])

    useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])


    useEffect(() => {
        if (!showModal && isSaveSuccess) {
            props.history.push('/list-advertising-group')
        }
    }, [showModal])


    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>
                                <label className="text-label-title"><i className="la la-layer-group"></i>&nbsp;{titleName}</label>
                                <div className="separator" />
                                {/* linha */}
                                <div className="row-forms">
                                    {/* Id */}
                                    <div className="form-group">
                                        <label className="text-label">Id</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            value={filteredData.id}
                                            onChange={(e) => setFilteredData({ ...filteredData, name: e.target.value })}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* nome */}
                                    <div className="form-group">
                                        <label className="text-label">Nome*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            placeholder="Nome..."
                                            value={filteredData.name}
                                            onChange={(e) => setFilteredData({ ...filteredData, name: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* Description */}
                                    <div className="form-group">
                                        <label className="text-label">Descrição</label>
                                        <textarea
                                            className="form-control"
                                            style={{ width: '350px', height: '100px' }}
                                            value={filteredData.description}
                                            onChange={(e) => setFilteredData({ ...filteredData, description: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* status */}
                                <div className="row-forms">
                                    <div className="form-group">
                                        <label className="text-label">Ativo</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            style={{ width: '350px' }}
                                            onChange={(e) => setFilteredData({ ...filteredData, active: e.target.value })}
                                        >
                                            {
                                                list_select_yes_or_no.map((item, index) =>
                                                    <option key={index} value={item.value} selected={filteredData.active === item.value}>
                                                        {item.text}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row-forms" style={{ flexDirection: 'row', display: 'contents' }}>
                                    <div className="form-group" >
                                        <button disabled={loader} onClick={() => edit()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                            {
                                                loader
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </>
                                                    : 'Editar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => setShowModal(false)}
                        >
                            <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        : <div><i className="far fa-check-circle green fa-2x"></i></div>

                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div>
        </div>
    )
}

