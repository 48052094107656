import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import Chart from 'react-apexcharts'
import { DateRange } from '@material-ui/icons'
// import Loader from '../Panel/loader'
import utils from '../../../../utils/utils.js'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import ThreeDots from 'react-loader-spinner'
import api from '../../../../services/api'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [dateIni, setDateIni] = useState(utils.getDateBefore(30))
  const [dateEnd, setDateEnd] = useState(utils.getDate())
  const [loader, setLoader] = useState(false)
  const [radioBox, setRadioBox] = useState('1')
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const [data, setData] = useState({

    bestCreditOut: [],
    nameBestCreditOut: [],
    bestTransictions: [],
    nameBestTransiction: [],
    valuesCloseLotes: 0,
    valuesOpenLotes: 0

  })
  const ID_COMPANY = localStorage.getItem('ID_COMPANY')
  const [datavalues, setDatavalues] = useState([
    {
      name: 'PARC. FORNECEDOR 1',
      value: 1000
    },
    {
      name: 'PARC. FORNECEDOR 2',
      value: 2000
    },
    {
      name: 'PARC. FORNECEDOR 3',
      value: 5000
    },
    {
      name: 'PARC. FORNECEDOR 4',
      value: 7000
    },
    {
      name: 'PARC. FORNECEDOR 5',
      value: 8000
    },
    {
      name: 'PARC. FORNECEDOR 6',
      value: 3000
    },
    {
      name: 'PARC. FORNECEDOR 7',
      value: 2000
    },
    {
      name: 'PARC. FORNECEDOR 8',
      value: 10000
    },
    {
      name: 'PARC. FORNECEDOR 9',
      value: 1000
    },
    {
      name: 'PARC. FORNECEDOR 10',
      value: 1000
    },
  ])
  const firstOption = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#51C4C7', '#a27871'],
    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameBestCreditOut,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true

      },
      y: {


      }
    }
  }
  const firstSeries = [{
    name: 'TOP 10 FORNECEDORES COM MAIOR SAÍDA DE CRÉDITO',
    data: data.bestCreditOut
  }]

  const secondOption = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#e4881f', '#a27871'],

    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameBestTransiction,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true

      },
      y: {


      }
    }
  }
  const secondSeries = [{
    name: 'TOP 10 FORNECEDORES COM MAIOR SAÍDA DE TRANSAÇÕES',
    data: data.bestTransictions
  }]

  const optionPie = {

    title: {
      text: 'LOTES ABERTOS X LOTES FECHADOS',
      align: 'center',
      style: {
        fontSize: '15px',
        color: '#000',
        fontWeight: 'bold',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: true
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    stroke: {
      width: [1, 1, 1, 1, 1],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      offsetY: 10,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '15px',


    },
    labels: ['Aberto', 'Fechado'],
    colors: ['#51C4C7', '#e4881f'],
  }

  const options = {
    series: [data.valuesCloseLotes, data.valuesOpenLotes]
  }


  const optionsTmp = {
    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#51C4C7', '#a27871'],
    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      }
    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,
      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },
      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: ['LOTES FECHADOS', 'LOTES ABERTOS'],
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },
    yaxis: {
      showForNullSeries: true,
      labels: {
        style: {
          // color: '#0abab5',
        },
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: false,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: false
      },
      y: {
      }
    }
  }
  const firstSeriesTmp = [{
    name: 'LOTES',
    data: [data.valuesCloseLotes, data.valuesOpenLotes]
  }]





  const getData = async () => {

    setLoader(true)

    try {

      const bestCreditOut = await api.get(`/report/4/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      const bestTransictions = await api.get(`/report/5/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      const valuesCloseLotes = await api.get(`/report/6/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      const valuesOpenLotes = await api.get(`/report/7/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      // console.log('valuesCloseLotes ----> ', valuesCloseLotes.data)
      // console.log('valuesOpenLotes ----> ', valuesOpenLotes.data)

      let dataBestCreditOut = []
      let nameBestCreditOut = []
      let dataBestTransictions = []
      let nameBestTransiction = []

      bestCreditOut.data.map(row => {

        nameBestCreditOut.push(row.name)
        dataBestCreditOut.push(row.total)

      })

      bestTransictions.data.map(row => {

        nameBestTransiction.push(row.name)
        dataBestTransictions.push(row.total_transacoes)

      })


      // console.log(`getData =>`, bestCreditOut)

      setData({
        ...data,
        bestCreditOut: dataBestCreditOut,
        nameBestCreditOut: nameBestCreditOut,
        bestTransictions: dataBestTransictions,
        nameBestTransiction: nameBestTransiction,
        valuesCloseLotes: valuesCloseLotes.data[0].total,
        valuesOpenLotes: valuesOpenLotes.data[0].total
      })


    } catch (error) {

      console.log("error =>", error.response.data.message)
    }

    setLoader(false)

  }

  useEffect(() => {


    console.log('radioBox =>', radioBox)
    props.history.push("/report-master-supplier")

  }, [radioBox])


  useEffect(() => {

    getData()

  }, [])
  // useEffect(() => { console.log('option', options) }, [options])

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">
              <input type="radio" defaultChecked name="report" value="1" onClick={(e) => setRadioBox(e.target.value)} />&nbsp;<span className="gray">Créditos / Transações</span>
              &nbsp;&nbsp;
              <input type="radio" name="report" value="2" onClick={(e) => setRadioBox(e.target.value)} />&nbsp;<span className="gray">Créditos / Lotes</span>
              &nbsp;&nbsp;
              <DateRange fontSize="large" />
              <input style={{ width: '15%', height: '35px' }}
                value={dateIni}
                id="dateIni"
                onChange={(e) => setDateIni(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
              <input style={{ width: '15%', height: '35px' }}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <button type="button"
                className="btn btn-info"
                onClick={() => getData()}
                disabled={loader}
                style={{ width: '150px', height: '38px' }}
              >
                {
                  loader
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              <div className="indicator-top bold">
                <i className="la la-bar-chart-o blue fa-lg"></i> &nbsp; DASHBOARD GERENCIAL - PF
              </div>
              <div className="indicator-container" >
                <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                  Valores em Lotes Fechados</Tooltip>}>
                  <div className="dashbord">
                    <i className="flaticon-381-network green fa-2x"></i>&nbsp;
                    {
                      loader ?
                        <ThreeDots
                          color="#07aecc"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        <>R$ {utils.formatMoneyBRL(data.valuesCloseLotes)}</>
                    }

                  </div>
                </OverlayTrigger>
                <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                  Valores em Lotes Abertos</Tooltip>}>
                  <div className="dashbord">
                    <i className="flaticon-381-network green fa-2x"></i>&nbsp;
                    {
                      loader ?
                        <ThreeDots
                          color="#07aecc"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        <>R$ {utils.formatMoneyBRL(data.valuesOpenLotes)}</>
                    }
                  </div>
                </OverlayTrigger>
              </div>
              <div className="indicator-footer">

                <div className="dashbord">
                  <Chart options={firstOption} series={firstSeries} height={isBigScreen ? "100%" : "300px"} width={isBigScreen ? "750" : "530"} type={'bar'} />
                </div>

                <div className="dashbord" style={{
                  display: radioBox === '1' ?
                    'inline'
                    : 'none'
                }}>
                  <Chart options={secondOption} series={secondSeries} height={isBigScreen ? "100%" : "300px"} width={isBigScreen ? "750" : "530"} type={'bar'} />
                  {/* <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                    <thead>
                      <tr role='row'>
                        <th style={{ textAlign: 'start' }} className="sorting_asc">TOP 10 PARCEIROS COMERCIAIS</th>
                        <th style={{ textAlign: 'end' }} className="sorting_asc">VALOR</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        datavalues.map((row, idx) =>
                          <tr key={idx}>
                            <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                            <td style={{ textAlign: 'end' }} className="sorting_asc bold">&nbsp; R$ {utils.formatMoneyBRL(row.value)}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table> */}
                </div>
                <div className="dashbord" style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: radioBox === '2' ?
                    'inline'
                    : 'none'
                }}>
                  <Chart
                    // options={optionPie}
                    // series={options.series}
                    options={optionsTmp}
                    series={firstSeriesTmp}
                    height={isBigScreen ? "500" : "300"}
                    width={isBigScreen ? "500" : "500"}
                    type={'bar'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}