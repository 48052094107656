import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [categories, setCategories] = useState([])
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const fields = {
        name: '',
        type: '',
        id_category: '',
        status: 1
    }
    const [inputs, setInputs] = useState(fields)
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    const getCategories = async () => {

        try {
            const res = await api.get('/category?filter=category')
            console.log('categorias -> ', res.data)
            if (res.data) {
                setCategories(res.data)
            }
        }
        catch (error) {
            console.log("error =>", error)
        }
    }

    const create = async () => {
        setLoader(true)
        setIsError(false)

        try {
            const res = await api.post("/category", {
                name: inputs.name,
                type: inputs.type,
                id_category: inputs.id_category,
                status: inputs.status
            })

            console.log(`res =>`, res.data)
            setText(res.data.message)
            setInputs(fields)
            setShowModal(true)
        } catch (error) {
            console.log(error)
            setIsError(true)
            setShowModal(true)
            setText('Erro ao Cadastrar Categoria...')
        }
        setLoader(false)
        props.history.push('/list-categories')
    }

    useEffect(() => { getCategories() }, [])

    useEffect(() => {
        if (inputs.name !== '' && inputs.status !== '' && (
            (inputs.type === 'categoria' && inputs.id_category === '') ||
            (inputs.type === 'subcategoria' && inputs.id_category !== '')
        )) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }

        console.log('inputs => ', inputs)
    }, [inputs])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>

                                <label className="text-label-title">
                                    <i className="fas fa-user-plus"></i>&nbsp;Criação de Categoria
                                </label>
                                <div className="separator" />

                                {/* linha */}
                                <div className="row-forms">
                                    {/* tipo */}
                                    <div className="form-group">
                                        <label className="text-label">Tipo*</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            value={inputs.type}
                                            style={{ width: '350px' }}
                                            onChange={(e) => setInputs({ ...inputs, type: e.target.value })}
                                        >
                                            <option >-- TIPO --</option>
                                            <option value='categoria'>Categoria</option>
                                            <option value='subcategoria'>Subcategoria</option>
                                        </select>
                                    </div>
                                    {/* categoria pai */}
                                    {inputs.type === 'subcategoria' &&
                                        <div className="row-forms">
                                            <div className="form-group">
                                                <label className="text-label">Categoria Pai</label>
                                                <select
                                                    className='form-control form-control-lg'
                                                    id='inlineFormCustomSelect'
                                                    defaultValue={'option'}
                                                    value={inputs.id_category}
                                                    style={{ width: '350px' }}
                                                    onChange={(e) => setInputs({ ...inputs, id_category: e.target.value })}
                                                >
                                                    <option>-- Categoria --</option>
                                                    {
                                                        categories.map((category, index) =>
                                                            <option key={index} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* nome */}
                                    <div className="form-group">
                                        <label className="text-label">Nome*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            placeholder="Nome..."
                                            value={inputs.name}
                                            onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                        />
                                    </div>
                                    {/* status */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Ativo</label>
                                            <select
                                                defaultValue={'option'}
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                value={inputs.status}
                                                style={{ width: '350px' }}
                                                onChange={(e) => setInputs({ ...inputs, status: e.target.value })}
                                            >
                                                <option value='1' selected>Sim</option>
                                                <option value='0'>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-forms" style={{ flexDirection: 'row', display: 'contents' }}>
                                    <div className="form-group" >
                                        <button
                                            disabled={loader || isDisabled}
                                            onClick={() => create()}
                                            style={{ width: '17%', backgroundColor: '#5e72e4' }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            {
                                                loader ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </>
                                                    : 'Criar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => setShowModal(false)}

                        >
                            <div className="close-rodal" onClick={() => setShowModal(false)}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError
                                        ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        :
                                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div>
        </div>

    )
}

