import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import Chart from 'react-apexcharts'
import { DateRange } from '@material-ui/icons'
// import Loader from '../Panel/loader'
import utils from '../../../../utils/utils.js'
import { useMediaQuery } from 'react-responsive'
import api from '../../../../services/api'
import ThreeDots from 'react-loader-spinner'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [dateIni, setDateIni] = useState(utils.getDateBefore(89))
  const [dateEnd, setDateEnd] = useState(utils.getDate())
  const [loader, setLoader] = useState(false)
  const [radioBox, setRadioBox] = useState('1')
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const ID_COMPANY = localStorage.getItem('ID_COMPANY')
  const ID_MASTER = localStorage.getItem('ID_MASTER')
  const [data, setData] = useState({
    valuesOpenLots: [],
    amountOpenLots: [],
    nameValuesOpenLots: [],
    valuesCloseLots: [],
    nameValuesCloseLots: [],
    amountValuesCloseLots: []
  })
  const mes = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
  }

  const firstOption = {
    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#51C4C7', '#a27871'],
    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameValuesOpenLots,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: false,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: false

      },
      y: {


      }
    }
  }

  const firstSeries = [{
    name: 'VALORES LOTES ABERTOS',
    data: data.valuesOpenLots
  }]

  const secondOption = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#e4881f', '#a27871'],

    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameValuesOpenLots,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true

      },
      y: {


      }
    }
  }
  const secondSeries = [{
    name: 'QTD. LOTES ABERTOS',
    data: data.amountOpenLots
  }]

  const thirthOption = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#51C4C7', '#a27871'],

    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameValuesCloseLots,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true

      },
      y: {


      }
    }
  }
  const thirthSeries = [{
    name: 'VALORES LOTES FECHADOS',
    data: data.valuesCloseLots
  }]

  const fourthOption = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#e4881f', '#a27871'],

    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameValuesCloseLots,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true

      },
      y: {


      }
    }
  }
  const fourthSeries = [{
    name: 'QTD. LOTES FECHADOS',
    data: data.amountValuesCloseLots
  }]

  const getData = async () => {

    setLoader(true)

    try {


      const resValuesOpenLots = await api.get(`/report/10/${ID_MASTER}/${ID_COMPANY}/2/${dateIni}/${dateEnd}`)
      const resValuesCloseLots = await api.get(`/report/10/${ID_MASTER}/${ID_COMPANY}/7/${dateIni}/${dateEnd}`)
      console.log(`getData =>`, resValuesCloseLots)

      let dataValuesOpenLots = []
      let nameValuesOpenLots = []
      let amountValuesOpenLots = []
      let dataValuesCloseLots = []
      let nameValuesCloseLots = []
      let amountValuesCloseLots = []

      resValuesOpenLots.data.map(row => {
        nameValuesOpenLots.push(mes[row.mes])
        dataValuesOpenLots.push(row.total)
        amountValuesOpenLots.push(row.qtde)

      })

      resValuesCloseLots.data.map(row => {
        nameValuesCloseLots.push(mes[row.mes])
        dataValuesCloseLots.push(row.total)
        amountValuesCloseLots.push(row.qtde)

      })

      setData({
        ...data,
        valuesOpenLots: dataValuesOpenLots,
        nameValuesOpenLots: nameValuesOpenLots,
        amountOpenLots: amountValuesOpenLots,
        valuesCloseLots: dataValuesCloseLots,
        nameValuesCloseLots: nameValuesCloseLots,
        amountValuesCloseLots: amountValuesCloseLots

      })



    } catch (error) {

      console.log("error =>", error.response.data.message)
    }

    setLoader(false)
  }

  useEffect(() => {
    console.log('radioBox =>', radioBox)
    props.history.push("/report-partner-supplier")
  }, [radioBox])

  useEffect(() => {

    getData()

  }, [])


  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">
          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">

              <DateRange fontSize="large" />
              <input style={{ width: '15%', height: '35px' }}
                value={dateIni}
                id="dateIni"
                onChange={(e) => setDateIni(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
              <input style={{ width: '15%', height: '35px' }}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <button type="button"
                className="btn btn-info"
                onClick={() => getData()}
                disabled={loader}
                style={{ width: '150px', height: '38px' }}
              >
                {
                  loader
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>
          <div className="data">
            <div className="panel">
              <div className="indicator-top bold" style={{ alignItems: 'center', height: '50px' }}>
                <i className="la la-bar-chart-o blue fa-lg"></i> &nbsp; DASHBOARD - PARCEIRO FORNECEDOR &nbsp;
                {
                  loader &&
                  <ThreeDots
                    color="#07aecc"
                    height={40}
                    width={40}
                    type="ThreeDots"
                  />
                }
              </div>


              <div className="indicator-footer">
                <div className="dashbord">
                  <Chart
                    options={firstOption}
                    series={firstSeries}
                    height={isBigScreen ? "100%" : "300px"}
                    width={isBigScreen ? "750" : "530"}
                    type={'bar'}
                  />
                </div>

                <div className="dashbord">
                  <Chart options={secondOption} series={secondSeries} height={isBigScreen ? "100%" : "300px"} width={isBigScreen ? "750" : "530"} type={'bar'} />
                </div>
              </div>

              <div className="indicator-footer">
                <div className="dashbord">
                  <Chart
                    options={thirthOption}
                    series={thirthSeries}
                    height={isBigScreen ? "100%" : "300px"}
                    width={isBigScreen ? "750" : "530"}
                    type={'bar'}
                  />
                </div>

                <div className="dashbord">
                  <Chart options={fourthOption} series={fourthSeries} height={isBigScreen ? "100%" : "300px"} width={isBigScreen ? "750" : "530"} type={'bar'} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}