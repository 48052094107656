import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'
import './edit-users.scss'
import moment from 'moment'

export default function App(props) {
    moment.locale('pt')

    const [toggleMenu, setToggleMenu] = useState(false)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const [infoUser, setInfoUser] = useState(props.location.state.data)
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [passTransactions, setPassTransactions] = useState('')
    const [confirmPassTransactions, setConfirmPassTransactions] = useState('')
    // const [buttonPassword, setButtonPassword] = useState(true)
    const [deactivateButton, setDeactivateButton] = useState(false)
    const [contadorNumero, setContadorNumero] = useState(0)
    const [contadorLetra, setContadorLetra] = useState(0)
    const ID_PROFILE = localStorage.getItem('ID_PROFILE')
    const [permissions, setPermissions] = useState(false)
    const [valueButtons, setValueButtons] = useState('')
    const [styleButton, setStyleButton] = useState('pointer')
    const [styleCheckbox, setStyleCheckbox] = useState({
        style_products_services: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_purchases: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_companies: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_my_sales: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_category: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_budgets: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_reports: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_sales: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_user: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        // 3° Nível //
        style_financial_extract: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_negotiations: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_see_catalogs: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_my_sales_two: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_to_manage: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_sales_two: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_dashboard: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_see_items: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        },
        style_create: {
            pointer: 'not-allowed',
            borderColor: '#FE1B00',
            backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }

    })
    const [valuePermissions, setValuePermissions] = useState({
        activate_products_services: true,
        activate_purchases: true,
        activate_companies: true,
        activate_my_sales: true,
        activate_category: true,
        activate_budgets: true,
        activate_reports: true,
        activate_sales: true,
        activate_user: true,

        // 3° Nível //
        activate_financial_extract: true,
        activate_my_sales_two: true,
        activate_see_catalogs: true,
        activate_negotiations: true,
        activate_sales_two: true,
        activate_dashboard: true,
        activate_to_manage: true,
        activate_see_items: true,
        activate_create: true,
    })
    const [expiratedPass, setExpiratedPass] = useState(false)
    const [dateExpirate, setDateExpirate] = useState(null)

    const ID_USER = localStorage.getItem('ID_USER')

    const ativaSegundaColuna = async (id) => {
        if (id === 'productsServices') {
            setValuePermissions({
                ...valuePermissions,
                activate_see_catalogs: false,
                activate_see_items: false
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_see_catalogs: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },
                style_see_items: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }
            })
        } else {
            setValuePermissions({
                ...valuePermissions,
                activate_see_catalogs: true,
                activate_see_items: true
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_see_catalogs: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_see_items: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                }
            })
        }

        if (id === 'budgets') {
            setValuePermissions({
                ...valuePermissions,
                activate_negotiations: false
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_negotiations: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }
            })
        } else {
            setValuePermissions({
                ...valuePermissions,
                activate_negotiations: true
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_negotiations: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                }
            })
        }

    }

    const edit = async (user) => {

        setLoader(true)
        setIsError(false)

        try {
            let res

            if (passTransactions) {
                res = await api.put(`/user/${infoUser.uuid}`, {
                    name: infoUser.name,
                    cpf: infoUser.cpf,
                    email: infoUser.email,
                    id_company: infoUser.id_company,
                    id_profile: infoUser.id_profile,
                    pass_transactions: passTransactions
                })

            } else {
                res = await api.put(`/user/${infoUser.uuid}`, {
                    name: infoUser.name,
                    cpf: infoUser.cpf,
                    email: infoUser.email,
                    id_company: infoUser.id_company,
                    id_profile: infoUser.id_profile,
                })

            }

            console.log(`res =>`, res.data)
            setText(res.data.message)
            setShowModal(true)

            // props.history.push('/edit-users', { data: user })
            props.history.push('/list-users')

        } catch (error) {

            console.log(error)
            setIsError(true)
            setShowModal(true)
            setText('Erro ao Editar Usuário...')

        }
        setLoader(false)
        // props.history.push('/list-users')

    }

    // Senha para transações
    const validatePassword = async (password) => {
        setContadorNumero(0)
        setContadorLetra(0)

        let contadorNumero = 0
        let contadorLetra = 0

        for (let i = 0; i < password.length; i++) {
            let char = password[i]

            if (isNaN(char)) {
                contadorLetra++
            } else {
                contadorNumero++
            }

        }
        setContadorNumero(contadorNumero)
        setContadorLetra(contadorLetra)
        setPassTransactions(password)

    }

    // Senha para transações
    useEffect(() => {

        if ((contadorNumero == 2 && contadorLetra == 2) && confirmPassTransactions === passTransactions) {
            setDeactivateButton(false)
            setStyleButton('pointer')
        } else if (contadorNumero == 0 && contadorLetra == 0) {
            setDeactivateButton(false)
            setStyleButton('pointer')
        } else {
            setDeactivateButton(true)
            setStyleButton('not-allowed')
        }

    }, [contadorLetra, contadorNumero, passTransactions, confirmPassTransactions])

    useEffect(() => {
        if (valueButtons === "estoque") {
            setValuePermissions({
                ...valuePermissions,
                activate_products_services: false, //<--
                activate_purchases: true,
                activate_companies: true,
                activate_my_sales: true,
                activate_category: true,
                activate_budgets: true,
                activate_reports: true,
                activate_sales: true,
                activate_user: true,
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_products_services: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }, //<--
                style_purchases: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_companies: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_my_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_category: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_budgets: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_reports: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_user: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },

            })
        }

        if (valueButtons === "vendedor") {
            setValuePermissions({
                ...valuePermissions,
                activate_products_services: true,
                activate_purchases: true,
                activate_companies: true,
                activate_my_sales: true,
                activate_category: true,
                activate_budgets: false, //<--
                activate_reports: true,
                activate_sales: false, //<--
                activate_user: true,
                // 3° Nível //

            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_products_services: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_purchases: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_companies: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_my_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_category: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_budgets: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },  //<--
                style_reports: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_sales: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },  //<--
                style_user: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },

            })
        }

        if (valueButtons === "financeiro") {
            setValuePermissions({
                ...valuePermissions,
                activate_products_services: true,
                activate_purchases: true,
                activate_companies: true,
                activate_my_sales: false, //<--
                activate_category: true,
                activate_budgets: true,
                activate_reports: false, //<--
                activate_sales: true,
                activate_user: true
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_products_services: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_purchases: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_companies: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_my_sales: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },  //<--
                style_category: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_budgets: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_reports: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },  //<--
                style_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_user: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
            })
        }

        if (valueButtons === "comprador") {
            setValuePermissions({
                ...valuePermissions,
                activate_products_services: true,
                activate_purchases: false, //<--
                activate_companies: true,
                activate_my_sales: true,
                activate_category: true,
                activate_budgets: false, //<--
                activate_reports: true,
                activate_sales: true,
                activate_user: true
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_products_services: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_purchases: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }, //<--
                style_companies: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_my_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_category: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_budgets: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                },  //<--
                style_reports: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_user: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
            })
        }

        if (valueButtons === "administrador") {
            setValuePermissions({
                ...valuePermissions,
                activate_products_services: true,
                activate_purchases: true,
                activate_companies: false, //<--
                activate_my_sales: true,
                activate_category: false, //<--
                activate_budgets: true,
                activate_reports: true,
                activate_sales: true,
                activate_user: false //<--
            })
            setStyleCheckbox({
                ...styleCheckbox,
                style_products_services: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_purchases: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_companies: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }, //<--
                style_my_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_category: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }, //<--
                style_budgets: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_reports: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_sales: {
                    pointer: 'not-allowed',
                    borderColor: '#FE1B00',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)'
                },
                style_user: {
                    pointer: 'pointer',
                    borderColor: '#0AD6D9',
                    backgroundColor: 'rgba(212, 212, 212, 0.5)'
                }, //<--
            })
        }

    }, [valueButtons])

    function closePermissions() {
        setPermissions(false)

    }

    useEffect(() => {
        setDateExpirate(null)
        setExpiratedPass(null)

        if (!infoUser.expirated_at) {
            setExpiratedPass(true)
        } else {
            let expirated_date = moment(new Date(infoUser.expirated_at))
            let now = moment(new Date())

            const duration = moment.duration(now.diff(expirated_date));
            const days = duration.asDays();

            if (days > 0) {
                setExpiratedPass(true)
                setDateExpirate(moment(infoUser.expirated_at).format('DD/MM/YYYY'))
            } else {
                setDateExpirate(false)
            }
        }

    }, [infoUser])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <button
                                onClick={() => { props.history.push('/list-users') }}
                                style={{ width: '150px', marginLeft: 10 }}
                                type="button" className="btn btn-info"
                            >
                                Voltar
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%', display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <label className="text-label-title"><i className="fas fa-user-plus"></i>&nbsp;Edição de Usuário</label>
                                    <div className="separator" />
                                    {/* nome */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Nome*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: '350px' }}
                                                placeholder="Nome..."
                                                value={infoUser.name}
                                                onChange={(e) => setInfoUser({ ...infoUser, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    {/* cpf */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">CPF</label>
                                            <CurrencyFormat format="###.###.###-##" mask="_"
                                                placeholder="CPF..."
                                                style={{ width: '300px' }}
                                                value={infoUser.cpf}
                                                className="form-control"
                                                onValueChange={(values) => {
                                                    const { formattedValue } = values
                                                    setInfoUser({ ...infoUser, cpf: formattedValue })

                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* email */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">E-Mail</label>
                                            <input
                                                type="email"
                                                autoComplete="off"
                                                name="new-email"
                                                id="new-email"
                                                value={infoUser.email}
                                                onChange={(e) => setInfoUser({ ...infoUser, email: e.target.value })}
                                                style={{ width: '300px' }}
                                                className="form-control"
                                                aria-describedby="inputGroupPrepend2"
                                                placeholder="example@example.com"
                                                required
                                            />
                                        </div>
                                    </div>
                                    {/* empresa */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Empresa</label>
                                            <select
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                defaultValue={'option'}
                                                disabled
                                                value={infoUser.company.id}
                                                style={{ width: '350px' }}
                                            >
                                                <option key={infoUser.company.id} value={infoUser.company.id}>{infoUser.company.trading_name} </option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* perfil */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Perfil</label>
                                            <select
                                                defaultValue={'option'}
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                value={infoUser.id_profile}
                                                style={{ width: '350px' }}
                                                disabled={infoUser.id == ID_USER}
                                                onChange={(e) => setInfoUser({ ...infoUser, id_profile: e.target.value })}
                                            >
                                                {
                                                    infoUser.id != ID_USER ?
                                                        <>
                                                            {
                                                                ID_PROFILE == 1 || ID_PROFILE == 2 ?
                                                                    <>
                                                                        <option value='2'>Administrador</option>
                                                                        <option value='3'>Usuário</option>
                                                                    </>
                                                                    : <></>
                                                            }
                                                            {
                                                                ID_PROFILE == 3 ?
                                                                    <option value='3'>Usuário</option>
                                                                    : <></>
                                                            }
                                                            {
                                                                infoUser.id_company == 2 ?
                                                                    <option value='4'>Parceiro Usuário</option>
                                                                    : <></>
                                                            }

                                                            {
                                                                // data.profile_user.id == 4 ? 
                                                                //     <option value='4'>Parceiro Usuário</option>
                                                                //         : 
                                                                //         data.profile_user.id == 3 ?
                                                                //             <>
                                                                //             <option value='4'>Parceiro Usuário</option>
                                                                //             <option value='3'>Usuário</option>
                                                                //             </> 
                                                                //                 : data.profile_user.id == 2  ?
                                                                //                         <>
                                                                //                         <option value='2'>Administrador</option>
                                                                //                         <option value='3'>Usuário</option>
                                                                //                         <option value='4'>Parceiro Usuário</option>
                                                                //                         </> 
                                                                //                         : data.profile_user.id == 1  ?   
                                                                //                             <>
                                                                //                             <option value='2'>Administrador</option>
                                                                //                             <option value='3'>Usuário</option>
                                                                //                             <option value='4'>Parceiro Usuário</option>
                                                                //                             </> : <></>
                                                            }
                                                        </> :
                                                        infoUser.id_profile == 2 ?
                                                            <option value='2'>Administrador</option>
                                                            : infoUser.id_profile == 3 ?
                                                                <option value='3'>Usuário</option>
                                                                :
                                                                <option value='4'>Parceiro Usuário</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/* botao */}
                                    <div style={{ display: 'flex' }}>
                                        <div className="row-forms" style={{ flexDirection: 'row', display: 'contents', }}>

                                            <div className="form-group" style={{ width: '100%', marginRight: 10 }}>
                                                <button
                                                    disabled={loader || deactivateButton}
                                                    // onClick={() => alert("aaaa")}
                                                    onClick={() => edit()}
                                                    type="button"
                                                    className="btn btn-info"
                                                    style={{ cursor: styleButton }}
                                                >
                                                    {
                                                        loader
                                                            ?
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                &nbsp;Processando...
                                                            </>
                                                            :
                                                            'Editar'
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row-forms" style={{ flexDirection: 'row', display: 'contents', }}>

                                            <div className="form-group" style={{ width: '100%' }}>
                                                {

                                                    ID_PROFILE == 1 || ID_PROFILE == 2 ?
                                                        // <button
                                                        //     onClick={() => { setPermissions(true) }}
                                                        //     className="btn btn-info"
                                                        // >
                                                        //     Permissões
                                                        // </button>
                                                        <></>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Senha para transações */}
                                <div style={{ marginTop: 55, marginLeft: '25%', textAlign: 'start' }}>
                                    <label style={{ fontWeight: 'bold', textAlign: 'start' }}>Senha para transações</label>
                                    <div className="row-forms" style={{ display: 'flex', height: 150 }}>
                                        <div className="form-group">
                                            <label className="text-label">Senha:</label>
                                            <input
                                                type="password"
                                                maxlength="4"
                                                value={passTransactions}
                                                onChange={(e) => validatePassword(e.target.value)}
                                                style={{ width: '300px' }}
                                                className="form-control"
                                            />
                                            <p style={{
                                                fontSize: '11px',
                                                fontWeight: 'bold',
                                                width: '90%',
                                                marginTop: 5,
                                                marginLeft: 5,
                                                color: 'rgba(128,128,128, 0.9)'
                                            }}
                                            >
                                                Senha deve conter 2 Letras e 2 Números de 0 a 9.
                                            </p>
                                            {
                                                expiratedPass && dateExpirate ?
                                                    <p style={{
                                                        fontSize: '11px',
                                                        fontWeight: 'bold',
                                                        width: '90%',
                                                        marginTop: 5,
                                                        marginLeft: 5,
                                                        color: 'red'
                                                    }}
                                                    >
                                                        Senha expirada em <b>{dateExpirate}</b>!
                                                    </p>
                                                    : expiratedPass ?
                                                        <p style={{
                                                            fontSize: '11px',
                                                            fontWeight: 'bold',
                                                            width: '90%',
                                                            marginTop: 5,
                                                            marginLeft: 5,
                                                            color: 'red'
                                                        }}
                                                        >
                                                            É necessário atualizar sua senha de transações.
                                                        </p>
                                                        : <p style={{
                                                            fontSize: '11px',
                                                            fontWeight: 'bold',
                                                            width: '90%',
                                                            marginTop: 5,
                                                            marginLeft: 5,
                                                            color: 'green'
                                                        }}
                                                        >
                                                            Senha vai expirar em <b>{moment(infoUser.expirated_at).format('DD/MM/YYYY')}</b>!
                                                        </p>
                                            }

                                        </div>
                                    </div>

                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Confirmar senha:</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                maxlength="4"
                                                value={confirmPassTransactions}
                                                onChange={(e) => setConfirmPassTransactions(e.target.value)}
                                                style={{ width: '300px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* mensagem retorno */}
                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => setShowModal(false)}

                        >
                            <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError
                                        ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        :
                                        <div><i className="far fa-check-circle green fa-2x"></i></div>

                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                        {/* Permissões */}
                        <Rodal
                            visible={permissions}
                            animation="slideLeft"
                            customStyles={{ width: '60%', height: '80%', bottom: '80%', overflowY: 'scroll', marginTop: '7%' }}
                            showCloseButton={true}
                            onClose={() => {
                                setPermissions(false);
                            }}
                        >
                            <div className="close-rodal"
                                onClick={() => closePermissions()}
                            >
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>

                            <div className="title">PERMISSÕES</div>
                            <div
                                className="detal-modal "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                }}>
                                {/* 1° Nível */}
                                {/* Usuários botões */}
                                <div>
                                    {/* Estoque */}
                                    <button
                                        style={{ marginBottom: '15px' }}
                                        class="btn btn-info"
                                        value="estoque"
                                        onClick={(e) => setValueButtons(e.target.value)}
                                    >
                                        Estoque
                                    </button>
                                    {/* Vendedor */}
                                    <button
                                        style={{ marginBottom: '15px' }}
                                        class="btn btn-info"
                                        value="vendedor"
                                        onClick={(e) => setValueButtons(e.target.value)}
                                    >
                                        Vendedor
                                    </button>
                                    {/* Financeiro */}
                                    <button
                                        style={{ marginBottom: '15px' }}
                                        class="btn btn-info"
                                        value="financeiro"
                                        onClick={(e) => setValueButtons(e.target.value)}
                                    >
                                        Financeiro
                                    </button>
                                    {/* Comprador */}
                                    <button
                                        style={{ marginBottom: '15px' }}
                                        class="btn btn-info"
                                        value="comprador"
                                        onClick={(e) => setValueButtons(e.target.value)}
                                    >
                                        Comprador
                                    </button>
                                    {/*Administrador  */}
                                    <button
                                        style={{ marginBottom: '15px' }}
                                        class="btn btn-info"
                                        value="administrador"
                                        onClick={(e) => setValueButtons(e.target.value)}
                                    >
                                        Administrador
                                    </button>
                                </div>
                                {/* 2° Nível */}
                                {/* Menu pricipal */}
                                <div className="column">
                                    {/* usuário */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_user.borderColor,
                                            backgroundColor: styleCheckbox.style_user.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="user">
                                                <span>
                                                    Usuário
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_user.pointer }}
                                                id="user"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_user}
                                            />
                                        </div>
                                    </label>
                                    {/* Minhas Vendas */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_my_sales.borderColor,
                                            backgroundColor: styleCheckbox.style_my_sales.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="my-sales">
                                                <span>
                                                    Minhas Vendas
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_my_sales.pointer }}
                                                id="my-sales"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_my_sales}
                                            />
                                        </div>
                                    </label>
                                    {/* Relatórios */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_reports.borderColor,
                                            backgroundColor: styleCheckbox.style_reports.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="reports">
                                                <span>
                                                    Relatórios
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_reports.pointer }}
                                                id="reports"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_reports}
                                            />
                                        </div>
                                    </label>
                                    {/* Orçamentos */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_budgets.borderColor,
                                            backgroundColor: styleCheckbox.style_budgets.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="budgets">
                                                <span>
                                                    Orçamentos
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_budgets.pointer }}
                                                id="budgets"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_budgets}
                                                onClick={(e) => ativaSegundaColuna(e.target.checked && e.target.id)}
                                            />
                                        </div>
                                    </label>
                                    {/* Vendas */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_sales.borderColor,
                                            backgroundColor: styleCheckbox.style_sales.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="sales">
                                                <span>
                                                    Vendas
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_sales.pointer }}
                                                id="sales"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_sales}
                                                onClick={(e) => ativaSegundaColuna(e.target.checked && e.target.id)}
                                            />
                                        </div>
                                    </label>
                                    {/* Produtos e serviços */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_products_services.borderColor,
                                            backgroundColor: styleCheckbox.style_products_services.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="productsServices">
                                                <span>
                                                    Produtos e serviços
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_products_services.pointer }}
                                                id="productsServices"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_products_services}

                                                onClick={(e) => ativaSegundaColuna(e.target.checked && e.target.id)}
                                            />
                                        </div>
                                    </label>
                                    {/* Categoria */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_category.borderColor,
                                            backgroundColor: styleCheckbox.style_category.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="category">
                                                <span>
                                                    Categoria
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_category.pointer }}
                                                id="category"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_category}
                                            />
                                        </div>
                                    </label>
                                    {/* Empresas */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_companies.borderColor,
                                            backgroundColor: styleCheckbox.style_companies.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="companies">
                                                <span>
                                                    Empresas
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_companies.pointer }}
                                                id="companies"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_companies}
                                            />
                                        </div>
                                    </label>
                                    {/* Compras */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_purchases.borderColor,
                                            backgroundColor: styleCheckbox.style_purchases.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="purchases">
                                                <span>
                                                    Compras
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_purchases.pointer }}
                                                id="purchases"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_purchases}
                                            />
                                        </div>
                                    </label>
                                </div>
                                {/* 3° Nível */}
                                {/* Menu secundário */}
                                <div className="column centralize">
                                    {/* Gerenciar */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_to_manage.borderColor,
                                            backgroundColor: styleCheckbox.style_to_manage.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="to-manage">
                                                <span>
                                                    Gerenciar
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_to_manage.pointer }}
                                                id="to-manage"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_to_manage}
                                            />
                                        </div>
                                    </label>
                                    {/* Criar */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_create.borderColor,
                                            backgroundColor: styleCheckbox.style_create.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="create">
                                                <span>
                                                    Criar
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_create.pointer }}
                                                id="create"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_create}
                                            />
                                        </div>
                                    </label>
                                    {/* Negociações */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_negotiations.borderColor,
                                            backgroundColor: styleCheckbox.style_negotiations.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="negotiations">
                                                <span>
                                                    Negociações
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_negotiations.pointer }}
                                                id="negotiations"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_negotiations}
                                            />
                                        </div>
                                    </label>
                                    {/* Minhas vendas */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_my_sales_two.borderColor,
                                            backgroundColor: styleCheckbox.style_my_sales_two.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="my_sales_two">
                                                <span>
                                                    Minhas vendas
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_my_sales_two.pointer }}
                                                id="my_sales_two"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_my_sales_two}
                                            />
                                        </div>
                                    </label>
                                    {/* Ver itens */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_see_items.borderColor,
                                            backgroundColor: styleCheckbox.style_see_items.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="seeItems">
                                                <span>
                                                    Ver itens
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_see_items.pointer }}
                                                id="seeItems"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_see_items}
                                            />
                                        </div>
                                    </label>
                                    {/* Ver catálogos */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_see_catalogs.borderColor,
                                            backgroundColor: styleCheckbox.style_see_catalogs.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="seeCatalogs">
                                                <span>
                                                    Ver catálogos
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_see_catalogs.pointer }}
                                                id="seeCatalogs"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_see_catalogs}
                                            />
                                        </div>
                                    </label>
                                    {/* Vendas */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_sales_two.borderColor,
                                            backgroundColor: styleCheckbox.style_sales_two.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="sales-two">
                                                <span>
                                                    Vendas
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_sales_two.pointer }}
                                                id="sales-two"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_sales_two}
                                            />
                                        </div>
                                    </label>
                                    {/* Dashboard */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_dashboard.borderColor,
                                            backgroundColor: styleCheckbox.style_dashboard.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="dashboard">
                                                <span>
                                                    Dashboard
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_dashboard.pointer }}
                                                id="dashboard"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_dashboard}
                                            />
                                        </div>
                                    </label>
                                    {/* Extrato Financeiro */}
                                    <label className="select-container"
                                        style={{
                                            borderColor: styleCheckbox.style_financial_extract.borderColor,
                                            backgroundColor: styleCheckbox.style_financial_extract.backgroundColor,
                                        }}>
                                        <div className="elements-container">
                                            <label className="label-box" for="financial-extract">
                                                <span>
                                                    Extrato Financeiro
                                                </span>
                                            </label>
                                            <input
                                                style={{ cursor: styleCheckbox.style_financial_extract.pointer }}
                                                id="financial-extract"
                                                type="checkbox"
                                                disabled={valuePermissions.activate_financial_extract}

                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {/* Botões */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginRight: '1.09%'
                                }}
                            >
                                <button
                                    // onClick={() => handleRating()}
                                    style={{ marginLeft: '8px', width: '150px' }}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    Salvar
                                </button>
                                <button
                                    onClick={() => closePermissions()}
                                    style={{ marginLeft: '50px', width: '150px' }}
                                    type="button"
                                    className="btn btn-danger"
                                >
                                    Voltar
                                </button>
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div >
        </div >

    )
}

