import Loader from 'react-loader-spinner'

export default function App() {
    return (
  
            <Loader
                color="#0ccbec"
                height={60}
                width={60}
                type="Watch"
                className="pulse"
            />
   
    )
}