import React, {useState, useEffect} from 'react'
import Menu from '../Panel/menu'
import {Timeline, TimelineEvent} from 'lv-react-horizontal-timeline'
import {FaCheck, FaRegCalendarCheck, FaRegFileAlt, FaDolly, FaTimes} from 'react-icons/fa'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import {Col, Container, Dropdown, Row} from "react-bootstrap"
import {FilterList, Close} from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import ReactStars from 'react-rating-stars-component'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'
import "./purchases.scss"

export default function App(props) {
    Moment.locale('pt')
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        availableCatalogs: false,
        orders: false,
        updateSale: false
    })
    const [dataOrders, setDataOrders] = useState([])
    const [order, setOrder] = useState([])
    const [companyEvaluation, setCompanyEvaluation] = useState([])
    const [dataAvailableCatalogs, setDataAvailableCatalogs] = useState([])
    const [dataItensSelectedCatalog, setDataItensSelectedCatalog] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showRating, setShowRating] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const fieldsRatings = {
        price: 0,
        satisfaction: 0,
        experience: 0,
        quality: 0,
        speedResponse: 0,
        time: 0
    }
    // const [ratings, setRatings] = useState(fieldsRatings)
    // const fieldsEvaluationReview = {
    //     price: '',
    //     satisfaction: '',
    //     experience: '',
    //     quality: '',
    //     speedResponse: '',
    //     time: ''
    // }
    // const [reviews, setReviews] = useState(fieldsEvaluationReview)
    const fields = {
        id: 0,
        name: '',
        description: '',
        response: '',
        status: null,
        value: 0,
        idSupplier: ''
    }
    const [ratings, setRatings] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [text, setText] = useState('')
    const [isError, setIsError] = useState(false)
    const [showModalResp, setShowModalResp] = useState(false)
    const [showModalRespApi, setShowModalRespApi] = useState(false)
    const [inputs, setInputs] = useState(fields)
    const [idSelectedCategory, setIdSelectedCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // const currentDataOrders = dataOrders.slice(indexOfFirstPost, indexOfLastPost);
    // const currentDataOrder = dataOrder.slice(indexOfFirstPost, indexOfLastPost);
    // const currentDataCatalogs = dataAvailableCatalogs.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [showModalItens, setShowModalItens] = useState(false)
    const [tmpTotalizadores, setTmpTotalizadores] = useState({
        totalItens: 0,
        nroItens: 0,
        statusOrder: null
    })
    const [tmpIdOrder, setTmpIdOrder] = useState(0)
    const [tmpOrderStatus, setTmpOrderStatus] = useState()
    const [isReasonRefuse, setIsReasonRefuse] = useState(false)
    const [description, setDescription] = useState('')
    const [styleButton, setStyleButton] = useState('not-allowed')
    const [isEnableButton, setIsEnableButton] = useState(true)
    const [isEnabled, setIsEnabled] = useState(false)
    const [showTimeline, setShowTimeline] = useState(false)
    const fieldsTimeLine = {
        created_at: '',
        status: '',
        deliver_option: '',
        receipt_date: '',
        delivery_forecast_date: ''
    }
    const [timelineInfo, setTimelineInfo] = useState(fieldsTimeLine)
    const [showImageModal, setShowImageModal] = useState(false)
    const [itemImage, setItemImage] = useState('')

    const tooltips = [
        "O pedido foi recusado, e encontra-se finalizado.",
        "O pedido foi aprovado pelo comprador, e aguarda a confecção pelo Parceiro Fornecedor.",
        "O pedido está aguardando uma ação do Parceiro Fornecedor!",
        "O pedido está aguardando uma ação do Parceiro Comercial!",
        "O pedido está pronto para ser entregue.",
        "O pedido foi entregue, e encontra-se finalizado.",
        "", "", "", "O pedido foi estornado, e encontra-se finalizado."
    ]

    const getOrders = async () => {
        setLoaders({...loaders, orders: true})
        try {
            const res = await api.get('/purchase-order')
            setDataOrders(res.data)

        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({...loaders, orders: false})
    }

    const getAvailableCatalogs = async () => {
        setLoaders({...loaders, availableCatalogs: true})
        try {
            const res = await api.get('/get-catalogs-available')
            console.log(`resCat =>`, res.data)
            setDataAvailableCatalogs(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({...loaders, availableCatalogs: false})
    }

    const checkRequiredFieldsFilled = () => {
        return companyEvaluation.every((row) => {
            if (row.is_required === 1) {
                return ratings[row.key] && reviews[row.key] && reviews[row.key].length > 4;
            }
            return true;
        });
    }

    const updateSale = async (idSale, status) => {

        setLoaders({...loaders, updateSale: true})
        setShowRating(false)
        setShowDetails(false)

        try {

            const res = await api.put(`/purchase-order/${idSale}`, {
                "status": status,
                "description": description,
                "reviews": reviews,
                "ratings": ratings
            })

            if (res.data) {
                console.log('res.data', res.data)
                setText(res.data.message)
            }

        } catch (err) {
            setText('Não foi possível atualizar a venda. Erro ', err.response.data.message)
            setIsError(true)
        } finally {
            setLoaders({...loaders, updateSale: false})
            setShowModalRespApi(true)
        }
    }

    const getSuppliers = async () => {
        // setLoaders({ ...loaders, availableCatalogs: true })
        try {
            const res = await api.get('/company')
            console.log(`res =>`, res.data)
            setSuppliers(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
        // setLoaders({ ...loaders, availableCatalogs: false })
    }

    const getCategories = async () => {
        setLoaders({...loaders, users: true})
        try {
            const response = await api.get(`/category?filter=category`)
            setCategories(response.data)

        } catch (err) {
            console.log(err)
        }
        setLoaders({...loaders, users: false})
    }

    const getSubCategories = async () => {
        setLoaders({...loaders, subCategories: true})
        try {
            const response = await api.get(`/category?filter=subcategory&id=${idSelectedCategory}`)
            setSubCategories(response.data)

        } catch (err) {
            console.log(err)
        }
        setLoaders({...loaders, subCategories: false})
    }

    const getItensSelectedCatalog = async (idCatalog) => {
        setLoaders({...loaders, availableCatalogs: true})

        try {
            const res = await api.get('/get-catalog-available/' + idCatalog)
            setDataItensSelectedCatalog(res.data)
            setShowModalItens(true)
        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({...loaders, availableCatalogs: false})
    }

    const resetRatingsAndReviews = () => {
        let newRatings = {...ratings};
        let newReviews = {...reviews};
        companyEvaluation.map((row, idx) => {
            newRatings[row.key] = 0;
            newReviews[row.key] = '';
        });

        setRatings(newRatings);
        setReviews(newReviews);

        setResetKey(prevKey => prevKey + 1);
    }

    const getDetails = async (orderRow) => {
        setLoaders({...loaders, details: true})
        setTmpIdOrder(orderRow.id)

        try {
            const res = await api.get('/get-order-itens/' + orderRow.id)
            // return;
            if (res.data.item_orders) {
                setOrder(res.data.item_orders)
                setCompanyEvaluation(res.data.company_evaluation)
                let quantityItems = 0
                res.data.item_orders.forEach(o => {
                    quantityItems += o.quantity
                });
                setTimelineInfo({
                    created_at: res.data.created_at,
                    delivery_option: res.data.delivery_option,
                    status: res.data.status,
                    delivery_forecast_date: res.data.delivery_forecast_date,
                    receipt_date: res.data.receipt_date
                });

                setTmpTotalizadores({
                    totalItens: res.data.total_trix,
                    nroItens: quantityItems,
                    statusOrder: orderRow.status,
                    descriptionRefuse: "Pedido rejeitado. Motivo: " + orderRow.reason_for_refusal
                })
                setTmpOrderStatus(res.data.status)
                setShowDetails(true);

                resetRatingsAndReviews();
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({...loaders, details: false})
    }

    const handleRatingChange = (rating, key) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [key]: rating
        }));
    }

    const handleReviewChange = (event, key) => {
        const value = event.target.value;
        setReviews(prevReviews => ({
            ...prevReviews,
            [key]: value
        }));
    }

    const [resetKey, setResetKey] = useState(0);

    function handleModal() {
        setShowModal(!showModal);
    }

    function handleCancel() {
        setShowModal(false);
        setShowDetails(false)
        getOrders();
        setLoaders({...loaders, updateSale: false})
        setShowModalResp(true)
        setShowRating(false)
        resetRatingsAndReviews();
        setTmpTotalizadores({
            totalItens: 0,
            nroItens: 0,
            statusOrder: null
        })
    }

    function handleRating() {
        setShowDetails(false)
        setShowRating(true);
        //AQUI VAI FICAR O UPDATE
    }

    function closeModalRefused() {
        setIsReasonRefuse(false)
        setShowRating(false)
        setDescription('')
        resetRatingsAndReviews();
        setShowModalRespApi(false)
        getOrders()
        resetRatingsAndReviews();
    }

    useEffect(() => {
        getOrders()
        getAvailableCatalogs()
        getSuppliers()
        getCategories()
    }, [])

    useEffect(() => {
        if (idSelectedCategory) {
            getSubCategories()
        }
    }, [idSelectedCategory])

    useEffect(() => {
        if (description.length >= 5) {
            setIsEnableButton(false)
            setStyleButton('pointer')
        } else {
            setIsEnableButton(true)
            setStyleButton('not-allowed')
        }
    }, [description])

    useEffect(() => {
        console.log('order', order)
    }, [order])

    function handleTimeline() {
        setShowTimeline(true)
    }

    function handleShowImage(show, image) {
        setItemImage(image);
        setShowImageModal(show);
    }

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu}/>
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu">
                                <button disabled={loaders.orders}
                                        onClick={() => getOrders()}
                                        style={{marginLeft: 10, width: '120px'}}
                                        id="search-charge-back"
                                        type="button"
                                        className="btn btn-info"
                                >
                                    {
                                        loaders.orders
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{padding: '1%'}}>

                            <section className="container-trix" style={{marginTop: '20px', minHeight: '70%', justifyContent: 'center'}}>

                                <div className="row-forms">
                                    {
                                        loaders.orders
                                            ?
                                            <div>
                                                Buscando Compras...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{flexDirection: 'row'}}>
                                                    <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Gerenciar Compras</label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc"> ID</th>
                                                        <th className="sorting_asc">Data/Hora</th>
                                                        <th className="sorting_asc">Valor</th>
                                                        <th className="sorting_asc">Status</th>
                                                        <th className="sorting_asc">Fornecedor</th>
                                                        <th className="sorting_asc">Ações</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        dataOrders.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                <td className="sorting_asc">OC-{row.id}</td>
                                                                <td className="sorting_asc">
                                                                    {Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                                                                </td>
                                                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.total_trix)}</td>
                                                                <td className="sorting_asc">
                                                                    {
                                                                        row.status === 0 ?
                                                                            <div style={{color: 'red'}}>
                                                                                Negado <i className="la la-times-circle red la-lg" title={tooltips[row.status]}/>
                                                                            </div>
                                                                            : row.status === 1 ?
                                                                                <div style={{color: 'green'}}>
                                                                                    Pronto para Entrega <i className="la la-check-circle green la-lg" title={tooltips[row.status]}/>
                                                                                </div>
                                                                                : row.status === 2 ?
                                                                                    <div style={{color: 'orange'}}>
                                                                                        Aguardando Vendedor <i className="la la-info-circle la-lg" title={tooltips[row.status]}/>
                                                                                    </div>
                                                                                    : row.status === 3 ?
                                                                                        <div style={{color: 'gray'}}>
                                                                                            Aceito <i className="la la-check-circle green la-lg" title={tooltips[row.status]}/>
                                                                                        </div>
                                                                                        : row.status === 4 ?
                                                                                            <div style={{color: 'gray'}}>
                                                                                                Concluído <i className="la la-check-circle blue la-lg" title={tooltips[row.status]}/>
                                                                                            </div>
                                                                                            : row.status === 5 ?
                                                                                                <div style={{color: 'gray'}}>
                                                                                                    Produto Entregue <i className="la la-legal blue la-lg" title={tooltips[row.status]}/>
                                                                                                </div>
                                                                                                : row.status === 9 ?
                                                                                                    <div style={{color: 'blue'}}>
                                                                                                        Estornado <i className="la la-times-circle blue la-lg" title={tooltips[row.status]}/>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>Não Identificado</div>
                                                                    }
                                                                </td>

                                                                {/* Coluna Fornecedor */}
                                                                <td className="sorting_asc">
                                                                    {row.company_seller.name}
                                                                </td>

                                                                <td className="sorting_asc" style={{width: '300px'}}>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            variant="success"
                                                                            className="light sharp i-false"
                                                                            style={{
                                                                                width: '150px',
                                                                                height: '20px'
                                                                            }}
                                                                        >
                                                                            {svg1}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => getDetails(row)}>
                                                                                <i className="la la-info-circle yellow la-lg"/> Detalhes
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    </tbody>

                                                </table>
                                                {/* <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={dataOrders.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        /> */}
                                            </>
                                    }
                                </div>
                            </section>

                            {/* selecao itens catalogo */}
                            <Rodal
                                visible={showModalItens}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowModalItens(false)}
                            >
                                <div className="close-rodal" onClick={() => setShowModalItens(false)}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="title">Itens do Catálogo</div>
                                <div className="detal-modal">

                                    <Container style={{marginBottom: '16px', textAlign: 'initial'}}>
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                <tr role='row'>
                                                    <th className="sorting_asc"> ID</th>
                                                    <th className="sorting_asc">Nome</th>
                                                    <th className="sorting_asc">Categoria</th>
                                                    <th className="sorting_asc">Subcategoria</th>
                                                    <th className="sorting_asc">Descrição</th>
                                                    <th className="sorting_asc">Valor</th>
                                                    <th className="sorting_asc">Incluido</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    dataItensSelectedCatalog.length > 0 && dataItensSelectedCatalog.items ?
                                                        dataItensSelectedCatalog.items.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                <td className="sorting_asc">{row.id}</td>
                                                                <td className="sorting_asc">{row.name}</td>
                                                                <td className="sorting_asc">
                                                                    {row.category ? row.category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">
                                                                    {row.sub_category ? row.sub_category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">{row.description}</td>
                                                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                <td className="sorting_asc" style={{width: '100px'}}>
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        checked={row.isIncluded}
                                                                        onChange={(e) => handleChangeCheckbox(row.id, e.target.checked)}
                                                                    /> */}
                                                                </td>
                                                            </tr>
                                                        )
                                                        : <>
                                                            <tr>
                                                                <td>DDDD</td>
                                                            </tr>
                                                        </>}

                                                </tbody>

                                            </table>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <button
                                            // onClick={() => saveCatalog()}
                                            style={{marginLeft: 10, width: '120px'}}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Salvar
                                        </button>
                                        <button onClick={handleCancel} style={{marginLeft: 10, width: '120px'}} type="button" className="btn btn-danger">
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/* Avaliação */}
                            <Rodal
                                visible={showRating}
                                animation="slideLeft"
                                customStyles={{
                                    width: '60%',
                                    height: '505px',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                                showCloseButton={true}
                                onClose={() => closeModalRefused()}
                            >

                                {/* >Botão fechar Rodal */}
                                <div className="close-rodal" onClick={() => closeModalRefused()}>
                                    <Close fontSize="large" style={{cursor: 'pointer', marginTop: -4}}/>
                                </div>

                                {/* Container modal avaliações */}
                                <div className="modal-evaluation">
                                    {
                                        <Container className="card-rating card-body" style={{flexFlow: 'column'}}>
                                            <Row>
                                                {
                                                    companyEvaluation.map((row, idx) =>
                                                        (parseInt(row.is_required) === 1 || parseInt(row.is_visible) === 1) &&
                                                            <Col sm={4} key={idx}>
                                                                <div style={{marginBottom: '10px', backgroundColor: '#f9f9f9', padding: '10px', border: '1px solid #CCC'}}>
                                                                    <Row>
                                                                        <Col className="descricao" style={{minHeight: '40px'}}>{row.name}</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <ReactStars
                                                                                key={resetKey}
                                                                                count={5}
                                                                                value={ratings[row.key]}
                                                                                onChange={(rating) => handleRatingChange(rating, row.key)}
                                                                                size={30}
                                                                                activeColor="#ffd700"
                                                                            />
                                                                            <div>
                                                                                <textarea
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    required
                                                                                    value={reviews[row.key]}
                                                                                    onChange={(e) => handleReviewChange(e, row.key)}
                                                                                    placeholder="Mínimo 5 caracteres"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                    )
                                                }
                                            </Row>
                                        </Container>
                                    }
                                </div>

                                {/* botões */}
                                <div className="buttons-rodal">
                                    <button
                                        onClick={() => updateSale(tmpIdOrder, '1')}
                                        style={{marginLeft: 10, width: '110px', fontSize: 12, padding: '5px'}}
                                        type="button"
                                        className="btn btn-success"
                                        disabled={!checkRequiredFieldsFilled()}
                                    >
                                        Avaliar
                                    </button>
                                    <button
                                        onClick={handleCancel}
                                        style={{marginLeft: 10, width: '110px', fontSize: 12, padding: '5px'}}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Rodal>

                            {/* detalhes order */}
                            <Rodal
                                visible={showDetails}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => handleCancel()}>
                                <div className="close-rodal" onClick={() => handleCancel()}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="title">Compra</div>
                                <div className="detal-modal">
                                    <Container style={{marginBottom: '16px', textAlign: 'initial'}}>
                                        <Row style={{marginBottom: '16px'}}>
                                            {/* nro itens */}
                                            <Col>
                                                <label className="text-label">Nro. Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={tmpTotalizadores.nroItens}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* total itens */}
                                            <Col>
                                                <label className="text-label">Total Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={"R$" + utils.formatMoneyBRL(tmpTotalizadores.totalItens)}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                        </Row>
                                        {/* grid itens */}
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                <tr role='row'>
                                                    <th className="sorting_asc">Item</th>
                                                    <th className="sorting_asc">Categoria</th>
                                                    <th className="sorting_asc">Subcategoria</th>
                                                    <th className="sorting_asc">Descrição</th>
                                                    <th className="sorting_asc">Valor</th>
                                                    <th className="sorting_asc">
                                                        <i style={{fontSize: '20px'}} class="far fa-image"></i>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    loaders.details ?
                                                        <div>
                                                            Buscando Itens...
                                                            <ThreeDots
                                                                color="#0ccbec"
                                                                height={40}
                                                                width={40}
                                                                type="ThreeDots"
                                                            />
                                                        </div>
                                                        : order.length > 0 ?
                                                            order.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td className="sorting_asc">{row.name_category ? row.name_category : ''}</td>
                                                                    <td className="sorting_asc">{row.name_sub_category ? row.name_sub_category : ''}</td>
                                                                    <td className="sorting_asc">{row.description}</td>
                                                                    <td className="sorting_asc">
                                                                        R$ {utils.formatMoneyBRL(row.current_price)}
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        <i className="fas fa-eye"
                                                                           style={{cursor: 'pointer'}}
                                                                           onClick={() => handleShowImage(true, row.image)}
                                                                        >
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            : <></>
                                                }
                                                </tbody>

                                            </table>
                                        </Row>

                                        {
                                            tmpTotalizadores.statusOrder == 0 &&
                                            <Row>
                                                <label className="text-label">Detalhes do Pedido</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Motivo da recusa:"
                                                    disabled
                                                    value={tmpTotalizadores.descriptionRefuse}
                                                />
                                            </Row>
                                        }
                                    </Container>
                                    {/* botoes */}
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        {
                                            tmpOrderStatus == 1 || tmpOrderStatus == 2 ?
                                                <>
                                                    {/* <button
                                  onClick={() => setIsReasonRefuse(true)}
                                  style={{ marginLeft: 10, width: '150px' }}
                                  type="button"
                                  className="btn btn-warning"
                              >
                                  Rejeitar Compra
                              </button> */}
                                                    {
                                                        tmpOrderStatus == 1 &&
                                                        <button
                                                            onClick={() => handleRating()}
                                                            style={{marginLeft: 10, width: '150px'}}
                                                            type="button"
                                                            className="btn btn-success"
                                                        >
                                                            Retirar Produto
                                                        </button>
                                                    }
                                                </>
                                                : <></>
                                        }

                                        <button
                                            onClick={handleTimeline}
                                            style={{marginLeft: 10, width: '120px'}}
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            <b>Linha do tempo</b>
                                        </button>

                                        <button
                                            onClick={handleCancel}
                                            style={{marginLeft: 10, width: '120px'}}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Voltar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/*rejeitar compra */}
                            <Rodal
                                visible={isReasonRefuse}
                                animation="flip"
                                customStyles={{backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px'}}
                                showCloseButton={true}
                                onClose={() => (false)}
                            >
                                <div className="close-rodal" onClick={() => setIsReasonRefuse(false)} style={{top: 0}}>
                                    <Close onClick={e => (false)} fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>

                                <div className="detal-modal" style={{border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)'}}>
                                    {/* titulo */}
                                    <div className="content-modal">
                                        <div style={{textAlign: "center", marginBottom: "15px"}}>
                                            <h2 style={{fontSize: "30px",}}>Rejeitar Compra</h2>
                                        </div>

                                        <label style={{fontWeight: 'bold', textAlign: 'start'}}>Digite o motivo: </label>
                                        <textarea
                                            value={description}
                                            required
                                            onChange={e => setDescription(e.target.value)}
                                            type="text"
                                            placeholder="Mínimo 5 caracteres"
                                            minlength="5"
                                            style={{height: '80px', maxHeight: '80px'}}

                                        />

                                    </div>

                                    <div className="buttonsModal">
                                        <button className="buttonModal" style={{cursor: styleButton}} disabled={isEnableButton} onClick={() => updateSale(tmpIdOrder, '0')}>ENVIAR</button>
                                        <button className="buttonModal" onClick={() => setIsReasonRefuse(false)}>VOLTAR</button>
                                    </div>
                                </div>

                            </Rodal>

                            {/* modal timeline */}
                            <Rodal
                                visible={showTimeline}
                                animation="flip"
                                customStyles={{width: '70%', height: 'calc(50%)', marginTop: '10%'}}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}

                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowTimeline(false)
                                }}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="title">LINHA DO TEMPO</div>

                                <div style={{marginTop: '10px'}}>
                                    {
                                        timelineInfo.status == 0
                                            ?
                                            <Timeline minEvents={2} placeholder>
                                                <TimelineEvent
                                                    color='#2BC155'
                                                    icon={FaRegFileAlt}
                                                    title='Criado'
                                                    subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                                                />
                                                <TimelineEvent
                                                    color='#ff0000'
                                                    icon={FaTimes}
                                                    title='Negado'
                                                    // subtitle=""
                                                />
                                            </Timeline>
                                            :
                                            <Timeline minEvents={2} placeholder>
                                                <TimelineEvent
                                                    color='#2BC155'
                                                    icon={FaRegFileAlt}
                                                    title='Criado'
                                                    subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                                                />
                                                <TimelineEvent
                                                    color={timelineInfo.delivery_option != null ? '#2BC155' : '#e0e0e0'}
                                                    icon={FaDolly}
                                                    title='Forma de entrega'
                                                    subtitle={timelineInfo.delivery_option}
                                                />
                                                {/* Passo seleciona  de entrega ao realizar a venda */}
                                                <TimelineEvent
                                                    color={((timelineInfo.status == '1' || timelineInfo.status == '5') || Moment(timelineInfo.delivery_forecast_date).isSameOrBefore(Moment())) ? '#2BC155' : '#e0e0e0'}
                                                    icon={FaRegCalendarCheck}
                                                    title='Aguardando retirada'
                                                    subtitle={timelineInfo.delivery_forecast_date != null ? Moment(timelineInfo.delivery_forecast_date).format('DD/MM/YYYY') : ""}
                                                />
                                                <TimelineEvent
                                                    color={(timelineInfo.status == '4' || timelineInfo.status == '5') ? '#2BC155' : '#e0e0e0'}
                                                    icon={FaCheck}
                                                    title='Retirado'
                                                    subtitle={timelineInfo.receipt_date != null ? Moment(timelineInfo.receipt_date).format('DD/MM/YYYY') : ""}
                                                    // action={{
                                                    // label: 'Ver detalhes...',
                                                    // onClick: () => window.alert('Erro!')
                                                    // }}
                                                />
                                            </Timeline>
                                    }
                                </div>
                            </Rodal>

                            {/* modal imagem produto */}
                            <Rodal
                                visible={showImageModal}
                                animation="flip"
                                customStyles={{
                                    width: '50%',
                                    height: 'calc(60%)',
                                    marginTop: '10%'
                                }}
                                showCloseButton={true}
                            >
                                <div className="close-rodal" onClick={() => setShowImageModal(false)}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>

                                <img className="box_img" style={{width: '50%'}} src={itemImage}/>

                            </Rodal>

                            {/* modal mensagem de retorno da api */}
                            < Rodal
                                visible={showModalRespApi}
                                animation="flip"
                                customStyles={{width: '50%', height: 'calc(35%)', marginTop: '10%'}}
                                showCloseButton={true}
                                onClose={() => closeModalRefused()}
                            >
                                <div className="close-rodal" onClick={() => {
                                    closeModalRefused()
                                }}>
                                    <Close fontSize="large" style={{cursor: 'pointer'}}/>
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <div><i className="fas fa-times red fa-2x"></i></div>
                                            :
                                            <div><i className="far fa-check-circle green fa-2x"></i></div>
                                    }
                                    <div>{text}</div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto'}}>
                                    <div className="modal-error-buttons"/>
                                </div>
                            </Rodal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
