import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'

export default function App(props) {
    Moment.locale('pt')
    const typeAction = {
        lancamento: 1,
        estorno: 2,
    }
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        findUsers: false,
        wallet: false
    })
    const [users, setUsers] = useState([])
    const [listUsers, setListUsers] = useState([])
    const [wallet, setWallet] = useState([])
    const fields = {
        value: 0,
        balance: 0,
        description: ''
    }
    const [inputs, setInputs] = useState(fields)
    const [text, setText] = useState('')
    const [filter, setFilter] = useState({
        id: '',
        name: '',
        cpf: false
    })
    const [showModal, setShowModal] = useState(false)
    const [typeModal, setTypeModal] = useState(false)
    const [showModalResp, setShowModalResp] = useState(false)
    const [styleButton, setStyleButton] = useState('pointer')
    const [disabledButton, setDisabledButton] = useState(true)
    const [isError, setIsError] = useState(false)

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )

    const getUsers = async () => {
        setLoaders({ ...loaders, findUsers: true })

        try {
            let filters = '?'

            if (filter.id) {
                filters += `id=${filter.id}&`
            }
            if (filter.name) {
                filters += `name=${filter.name}&`
            }
            if (filter.cpf) {
                filters += `cpf=${filter.cpf}&`
            }
            filters = filters.substring(filters[0], filters.length - 1)
            let res

            if (filters) {
                res = await api.get(`/driver${filters}`)
                setUsers(res.data)
            } else {
                res = await api.get(`/driver`)
                setUsers(res.data)
                setListUsers(res.data)
            }
        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, findUsers: false })
    }

    // busca o valor total na carteira
    const getWallet = async () => {
        setLoaders({ ...loaders, wallet: true })

        try {
            const res = await api.get(`/financial-extract`)
            console.log(`res financial-extract =>`, res.data)

            if (res.data) {
                setWallet(res.data.balance)
            }

        } catch (err) {
            console.log("error =>", err)
        }

        setLoaders({ ...loaders, wallet: false })
    }

    function handleModal(typeAction, idUser, userCredit) {
        if (showModal) {
            setInputs(fields)
        } else {
            setInputs({ ...inputs, id_user: idUser, balance: userCredit })
        }
        setShowModal(!showModal);
        setTypeModal(typeAction)
    }

    function closeModalResp() {
        setIsError(false)
        setText('')
        setShowModalResp(false)
    }

    const driverCreditManagement = async () => {
        setIsError(false)
        setText('')

        try {
            let res

            if (typeModal == typeAction.lancamento) {
                res = await api.post('/wallet-entry-driver', {
                    "credits": [{
                        id_user: inputs.id_user,
                        description: inputs.description,
                        value: inputs.value
                    }]
                })
                setText('Créditos gerados com sucesso')
            } else {
                res = await api.post('/wallet-entry-driver', {
                    return: inputs.value,
                    id_user: inputs.id_user,
                    description: inputs.description
                })
                setText('Créditos devolvidos com sucesso')
            }
        } catch (err) {
            setText(err.response.data.message)
            setIsError(true)
            setText('Créditos não puderam ser gerados/devolvidos')
        }

        setShowModal(false)
        setShowModalResp(true)
        getWallet()
        getUsers()
        setInputs(fields)
        
    }

    useEffect(() => {
        getUsers()
        getWallet()
    }, [])

    useEffect(() => {
        if (inputs.value === 0) {
            setDisabledButton(true)
            setStyleButton('not-allowed')
        } else {
            setDisabledButton(false)
            setStyleButton('pointer')
        }
    }, [inputs])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    onClick={(e) => setFilter({ ...filter, id: e.target.value })}
                                >
                                    <option value="">USUÁRIO</option>
                                    {
                                        listUsers.length > 0 &&
                                        listUsers.map((user) =>
                                            <option value={user.id}>{user.name}</option>
                                        )
                                    }
                                </select>

                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                />

                                <CurrencyFormat format="###.###.###-##" mask="_"
                                    placeholder="CPF"
                                    className="form-control"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.cpf}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, cpf: formattedValue })
                                    }}
                                />

                                {/* botao */}
                                <button
                                    disabled={loaders.findUsers}
                                    onClick={() => getUsers()}
                                    style={{ marginLeft: 10, width: '120px' }}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.findUsers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right"><i className="fas fa-bell orange fa-lg" /></div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section
                                className="container-trix"
                                style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}
                            >

                                <div className="row-forms">
                                    {
                                        loaders.findUsers
                                            ?
                                            <div>
                                                Consultando extratos...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div
                                                    className="row-forms"
                                                    style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                                >
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white " />&nbsp;Gestão de Créditos
                                                    </label>
                                                    {/* saldo disponível */}
                                                    <div className="form-group" style={{ display: 'flex' }}>
                                                        <label
                                                            className="text-label green"
                                                            style={{ marginLeft: '0px', marginBottom: '5px' }}
                                                        >
                                                            Saldo Disponível
                                                        </label>
                                                        <div className="separator-widget" style={{ width: 30 }} />
                                                        <div className="balance">
                                                            <i className="flaticon-381-network green " />
                                                            &nbsp; R$ {utils.formatMoneyBRL(wallet)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            {/* <th className="sorting_asc"> ID </th> */}
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Usuário</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo Atual</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo Devolvido</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Status Usuário</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            users.map((user, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                                        {user.name}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            user.wallet ?
                                                                                `R$ ${utils.formatMoneyBRL(user.wallet.total)}`
                                                                                : `R$ 0,00`
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            user.wallet ?
                                                                                `R$ ${utils.formatMoneyBRL(user.wallet.returned)}`
                                                                                : `R$ 0,00`
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            user.active ?
                                                                                <div style={{ color: 'green' }}>Ativo</div>
                                                                                :
                                                                                <div style={{ color: 'red' }}>Inativo</div>
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc" style={{ width: '300px' }} >
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={() => handleModal(
                                                                                        typeAction.lancamento,
                                                                                        user.id,
                                                                                        user.wallet ? user.wallet.total : 0
                                                                                    )}
                                                                                >
                                                                                    <i className="la la-check-circle green la-lg" /> Gerar Saldo
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    onClick={() => handleModal(
                                                                                        typeAction.estorno,
                                                                                        user.id,
                                                                                        user.wallet ? user.wallet.total : 0
                                                                                    )}
                                                                                >
                                                                                    <i className="la la-close red la-lg" /> Estornar Saldo
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                                {/* <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={users.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                /> */}
                                            </>
                                    }
                                </div>
                            </section>

                            {/* modal de lançamento e estorno de créditos */}
                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => handleModal(null)}
                            >
                                <div className="close-rodal" onClick={() => handleModal(null)}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">
                                    {typeModal === typeAction.lancamento ? 'Lançamento' : 'Estorno de crédito'}
                                </div>
                                <div className="detal-modal">
                                    {/* valores */}
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row style={{ marginBottom: '16px' }}>
                                            {/* valor */}
                                            <Col>
                                                <label className="text-label">Valor</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={inputs.value}
                                                    onValueChange={(values) => {
                                                        const { floatValue } = values
                                                        setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                    }}
                                                />
                                            </Col>
                                            {/* saldo usuario */}
                                            <Col>
                                                <label className="text-label">Saldo Usuário</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    disabled
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={inputs.balance}
                                                    onValueChange={(values) => {
                                                        const { floatValue } = values
                                                        setInputs({ ...inputs, balance: floatValue > 0 ? floatValue : 0 })
                                                    }}
                                                />
                                            </Col>
                                            {/* limite */}
                                            <Col>
                                                <label className="text-label">Limite</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    disabled
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={wallet ? utils.formatMoneyBRL(wallet) : 0}
                                                />
                                            </Col>
                                        </Row>
                                        {/* descrição */}
                                        <Row>
                                            <Col>
                                                <label className="text-label">Descrição</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    maxlength="300"
                                                    rows="5"
                                                    value={inputs.description}
                                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                                >
                                                    {
                                                        inputs.description
                                                    }
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {/* botao */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => driverCreditManagement()}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#5e72e4', cursor: styleButton }}
                                            type="button"
                                            className="btn btn-success"
                                            disabled={disabledButton}
                                        >
                                            {typeModal === typeAction.lancamento ? 'Gerar' : 'Estornar'}
                                        </button>
                                        <button
                                            onClick={handleModal}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/* modal da resposta da api */}
                            <Rodal
                                visible={showModalResp}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => closeModalResp()}
                            >
                                <div className="close-rodal" onClick={() => { closeModalResp() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <div><i className="fas fa-times red fa-2x"></i></div>
                                            :
                                            <div><i className="far fa-check-circle green fa-2x"></i></div>
                                    }
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}