import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Rodal from 'rodal';
import { Close } from '@material-ui/icons';
import utilsSelect from '../../../../utils/selects';
import Editor from '../../../../utils/editorQuill';
import ImageImport from '../../../../utils/images';
import { format } from 'date-fns';

export default function App(props) {

   const title = 'Publicidade';
   const titleName = `Criação da ${title}`;
   const [toggleMenu, setToggleMenu] = useState(false);
   const fields = {
      space: '',
      advertisings_space: '',
      advertising_group: '',
      name: '',
      message: '',
      messageEmail: '',
      date: format(new Date(), "yyyy-MM-dd"),
      time: format(new Date(), "hh:mm:ss"),
      active: '1',
      image: '',
      link: ''
   }
   
   const [inputs, setInputs] = useState(fields);
   const [dataSpaces, setDataSpaces] = useState([]);
   const [dataGruop, setDataGruop] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [isError, setIsError] = useState(false);
   const [isSaveSuccess, setSaveSuccess] = useState(false);
   const [loader, setLoader] = useState(false);
   const [text, setText] = useState('');
   const [isDisabledGroup, setIsDisabledGroup] = useState(true);
   const [list_select_yes_or_no, setListYesOrNo] = useState([]);

   const getSpaces = async () => {
      try {
         let res = await api.get(`advertising/spaces`);
         setDataSpaces(res.data.data)
         //console.log("res -> ", res.data.data);
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   const getGruops = async () => {
      try {
         let res = await api.get(`listing/advertising/groups`)
         const objAux = [];
         for (var member in res.data) {
            objAux.push({ Id: member, Name: res.data[member] })
         }
         setDataGruop(objAux);
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   const create = async () => {
      setLoader(true)
      setIsError(false)
      try {
         const imgField = document.getElementsByClassName('box__img')[0].src;
         const qlEditor = document.getElementsByClassName('ql-editor')[0].innerHTML;
         
         if(inputs.type === 'banner'){
            inputs.image = imgField;
            inputs.message = "";
            inputs.messageEmail = "";
         }else{
            if(inputs.type === 'email'){
               inputs.messageEmail = qlEditor;
            }else{
               inputs.messageEmail = ""
            }
            inputs.link = ''
            inputs.image = ''
         }
         
         const res = await api.post("/advertising/customer", {
            advertisings_space: inputs.advertisings_space,
            advertising_group: inputs.advertising_group,
            name: inputs.name,
            message: (inputs.messageEmail === "" || inputs.messageEmail === undefined) ? inputs.message : inputs.messageEmail,
            date: inputs.date,
            time: inputs.time,
            image: inputs.image,
            active: inputs.active,
            link: inputs.link
         });
         setText(res.data.message ?? res.statusText);
         setInputs(fields);
         setShowModal(true);
         setSaveSuccess(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
            idx ++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
         setShowModal(true);
         setSaveSuccess(false);
      }
      setLoader(false)
   }

   const handleFieldSpace = (typeSpaceId) => {
      let space  = dataSpaces.find((item) =>  item.id === Number.parseInt(typeSpaceId));
      const fieldTypeEmail = document.getElementById('fieldTypeEmail');
      const fieldTypeWhatapp = document.getElementById('fieldTypeWhatapp');
      const fieldTypeBanner = document.getElementById('fieldTypeBanner');
      typeSpaceId === "0" ? inputs.type = "0" : inputs.type = space.type;
      inputs.advertisings_space = typeSpaceId;
      if (inputs.type === 'email') {
         fieldTypeEmail.style.display = 'block';
         fieldTypeWhatapp.style.display = 'none';
         fieldTypeBanner.style.display = 'none';
      } else if (inputs.type === 'whatsapp') {
         fieldTypeEmail.style.display = 'none';
         fieldTypeWhatapp.style.display = 'block';
         fieldTypeBanner.style.display = 'none';
      } else if(inputs.type === 'banner') {
         fieldTypeEmail.style.display = 'none';
         fieldTypeWhatapp.style.display = 'none';
         fieldTypeBanner.style.display = 'block';
      }else{
         fieldTypeEmail.style.display = 'none';
         fieldTypeWhatapp.style.display = 'none';
         fieldTypeBanner.style.display = 'none';
      }
   }

   useEffect(() => {
      if (!showModal && isSaveSuccess) {
         props.history.push('/list-advertising-customer')
      }
   }, [showModal])


   // habilita botao criar
   useEffect(() => {
      setIsDisabledGroup(false)
      
      if (inputs.name === '' || inputs.advertising_group === '0' || inputs.advertisings_space === '0' || inputs.date === ''|| inputs.time === '')
      {
         setIsDisabledGroup(true)
      }
      if(inputs.type === 'banner')
      {
         if (inputs.link === '' || (!inputs.link.includes("http")))
         setIsDisabledGroup(true)
      }
   }, [inputs])

   useEffect(() => { getSpaces() }, [])
   useEffect(() => { getGruops() }, [])
   useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising-customer') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Nome *</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome..."
                                    value={inputs.name}
                                    onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>

                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Data *</label>
                                 <input
                                    type="date"
                                    className="form-control"
                                    value={inputs.date}
                                    onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>

                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Hora *</label>
                                 <input
                                    type="time"
                                    className="form-control"
                                    value={inputs.time}
                                    onChange={(e) => setInputs({ ...inputs, time: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>

                           <div className="row-forms">
                              {/* Gruop */}
                              <div className="form-group">
                                 <label className="text-label">Grupo *</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, advertising_group: e.target.value })}
                                 >
                                    <option value='0' selected> -- Escolha uma opção -- </option>
                                    {
                                       dataGruop.map((item) =>
                                          <option key={item.Id} value={item.Id}>
                                             {item.Name}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>

                           <div className="row-forms">
                              {/* Space */}
                              <div className="form-group">
                                 <label className="text-label">Espaço *</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => handleFieldSpace(e.target.value)}
                                    //onChange={(e) => console.log(e.target)}
                                 >
                                    <option value='0' selected> -- Escolha uma opção -- </option>
                                    {
                                       dataSpaces.map((item, index) =>
                                          <option key={index} value={item.id} typespace={item.type}>
                                             {item.name}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>

                           <div className="row-forms">
                              {/* Type */}
                              <div className="form-group" id="fieldTypeEmail" style={{ display: 'none' }}>
                                 <label className="text-label">Mensagem E-mail </label>
                                 <Editor />
                              </div>
                              <div className="form-group" id="fieldTypeWhatapp" style={{ display: 'none' }}>
                                 <label className="text-label">Mensagem Whatsapp</label>
                                 <textarea
                                    className="form-control"
                                    style={{ height: '100px' }}
                                    value={inputs.message}
                                    onChange={(e) => setInputs({ ...inputs, message: e.target.value })}
                                 />
                              </div>
                              <div className="form-group" id="fieldTypeBanner" style={{ display: 'none' }}>
                                 <label className="text-label">Banner </label>
                                 <ImageImport />
                                 <div className="grid">
                                 <div className="form-group">
                                    <label className="text-label">Link *</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="http://www.site.com"
                                       value={inputs.link}
                                       onChange={(e) => setInputs({ ...inputs, link: e.target.value })}
                                       setIsDisabled={false}
                                    />
                                 </div>
                              </div>
                           </div>
                           </div>

                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                 >
                                    {
                                       list_select_yes_or_no.map((item, index) =>
                                          <option key={index} value={item.value} selected={item.value === 1}>
                                             {item.text}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabledGroup} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Criar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => setShowModal(false)}

                  >
                     <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

