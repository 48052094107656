import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import Moment from 'moment'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import { Dropdown } from "react-bootstrap"
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'
import ThreeDots from 'react-loader-spinner'
import utils from '../../../../utils/utils.js'
import ModalLoader from '../Loader/modalLoader'

export default function App(props) {

  Moment.locale('pt')
  const [toggleMenu, setToggleMenu] = useState(false)
  const [loader, setLoader] = useState({
    getLots: false,
    invoiceDetails: false
  })
  const [data, setData] = useState([])
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const companyType = localStorage.getItem('COMPANY_TYPE')
  const [showModal, setShowModal] = useState({
    newLot: false,
    getInvoices: false,
    apiResp: false,
    invoiceDetails: false,
    closeLot: false,
  })
  const [partners, setPartners] = useState([])
  const newLotFields = {
    description: '',
    idPartner: ''
  }
  const [inputs, setInputs] = useState(newLotFields)
  const [invoices, setInvoices] = useState([])
  const [invoiceDetails, setInvoiceDetails] = useState([])
  const [dataCheckbox, setDataCheckbox] = useState([])
  const [isError, setIsError] = useState(false)
  const [text, setText] = useState('')
  const [idLot, setIdLot] = useState('')
  const [total, setTotal] = useState(0)
  const [lot, setLot] = useState({})
  const fields = {
    nameFile: '',
    hash_invoice: '',
    typeFile: '',
    amountFile: 0,
    extension: ''
  }
  const [dataFile, setDataFile] = useState(fields)
  const [dataFileNf, setDataFileNf] = useState(fields)
  const [dataFileBoleto, setDataFileBoleto] = useState(fields)
  const [styleButton, setStyleButton] = useState('not-allowed')
  const [filter, setFilter] = useState({
    id: '',
    status: ''
  })
  const [modalLoader, setModalLoader] = useState(false)

  const getLots = async () => {
    setData([])
    setLoader({ loader, getLots: true })

    try {
      let filters = '?'

      if (filter.status) {
        filters += `status=${filter.status}&`
      }

      filters = filters.substring(filters[0], filters.length - 1)
      let res

      if (filters) {
        res = await api.get(`/lot${filters}`)

        if (res.data) {
          setData(res.data)
        }
      } else {
        res = await api.get(`/lot`)

        if (res.data) {
          setData(res.data)
        }
      }
      console.log('res', res.data)
    }
    catch (error) {
      console.log("error =>", error)
    }
    setLoader({ loader, getLots: false })
  }

  const getCustomers = async () => {
    setPartners([])

    try {
      const res = await api.get('/customer-lot')

      if (res.data) {
        setPartners(res.data)
      }
    }
    catch (error) {
      console.log("error =>", error)
    }
  }

  const getInvoices = async () => {
    setLoader({ ...loader, getInvoices: true })
    setInvoices([])

    try {
      if (inputs.idPartner) {
        const res = await api.get(`/invoice-lot?customer=${inputs.idPartner}`)

        if (res.data) {
          setInvoices(res.data)
        }
      }
    }
    catch (error) {
      console.log("error =>", error)
    }

    setLoader({ ...loader, getInvoices: false })
  }

  const toggleCheckbox = async (keyBox) => {
    keyBox = Number(keyBox)

    if (dataCheckbox.includes(keyBox)) {
      setDataCheckbox(dataCheckbox.filter(key => key !== keyBox))
    } else {
      setDataCheckbox(dataCheckbox => [...dataCheckbox, keyBox])
    }
  }

  const createLot = async () => {
    setLoader({ ...loader, createLot: true })
    setIsError(false)
    setText('')

    let dataInvoices = []

    dataCheckbox.map(row => {
      dataInvoices.push({ id: row })
    })

    try {
      const res = await api.post('/lot', {
        description: inputs.description,
        invoices: dataInvoices
      })

      if (res.data) {
        setText(res.data.message)
      }
    } catch (error) {
      setIsError(true)
      setText('Error ao executar esta operação...')
    } finally {
      setShowModal({
        ...showModal,
        newLot: false,
        apiResp: true,
      })
      setLoader({ ...loader, createLot: false })
      setDataCheckbox([])
      setInputs(newLotFields)
      getLots()
    }
  }

  const getLotDetails = async (id) => {
    setLoader({ ...loader, invoiceDetails: true })
    setInvoiceDetails([])
    setIdLot(id)
    setTotal(0)

    try {
      const res = await api.get(`/lot/${id}`)
      console.log(`getLotDetails =>`, res.data)
      setInvoiceDetails(res.data)
      let total = 0

      if (res.data.invoices) {
        res.data.invoices.forEach(invoice => {
          total += invoice.total
        })
      }
      setTotal(total)
      setShowModal({ ...showModal, invoiceDetails: true })
    }
    catch (error) {
      console.log("error =>", error)
    } finally {
      setLoader({ ...loader, invoiceDetails: false })
      getInvoices()
    }
  }

  const changeInvoices = async (row, status) => {
    setIsError(false)
    setText('')
    console.log(row.id, status)
    try {
      const res = await api.put(`/lot/${idLot}`, {
        invoices: [{
          id: row.id,
          action: status
        }]
      })
      console.log('res.data', res)
      setText(res.data.message)
    } catch (error) {
      console.log('err', error)
      setIsError(true)
      setText(`Error ao executar esta operação... [${error.response.data.message}]`)
    }
    setShowModal({
      ...showModal,
      invoiceDetails: false,
      apiResp: true
    })
    // getLotDetails(idLot)
  }

  const showCloseLot = async (row) => {
    setLot(row)
    setShowModal({ ...showModal, closeLot: true })
  }

  const toggleStatus = async (row, status) => {
    setIsError(false)
    // setShowModal({ ...showModal, process: true })
    setModalLoader(true)

    try {

      const res = await api.put(`/lot/${row.id}`, {
        action: status
      })

      setText(res.data.message)
      getLots()

    } catch (error) {
      setIsError(true)

      setText(`Error ao executar esta operação... [${error.response.data.message}]`)
      console.log("error =>", error.response)
    }

    // setShowModal({ ...showModal, confirm: true })
    setModalLoader(false)


  }

  const convertBase64 = async (file) => {

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const sendFile = async (e, docType) => {

    setIsError(false)

    if (e.target.files[0]) {

      const file = e.target.files[0]
      const amountFile = file.size
      const typeFile = file.type
      const [extension, ...nameParts] = file.name.split('.').reverse()
      const base64 = await convertBase64(file)

      if (amountFile > 1024 * 1024 * 5) {
        setIsError(true)
        setText('O Arquivo deve ter no máximo 5MB')
        setShowModal({ ...showModal, confirm: true })
        setDataFile(fields)
        return
      }

      if (docType == 'nf') {
        setDataFileNf({
          ...dataFile,
          hash_invoice: base64,
          nameFile: nameParts[0] + '.' + extension,
          typeFile: typeFile,
          extension: extension
        })
        setStyleButton('not-allowed')

      }

      if (docType == 'boleto') {
        setDataFileBoleto({
          ...dataFile,
          hash_invoice: base64,
          nameFile: nameParts[0] + '.' + extension,
          typeFile: typeFile,
          extension: extension
        })
        setStyleButton('pointer')
      }
    } else {
      setDataFileNf(fields)
      setDataFileBoleto(fields)

      setStyleButton('not-allowed')
      console.log('no file selected....')
    }

  }

  const download = async (id) => {

    try {
      const res = await api.get(`/lot/${id}`)
      console.log('res.data', res.data)
      let a = document.createElement("a")
      a.href = res.data.hash_invoice
      a.download = `${res.data.name_file}.${res.data.extension}`
      a.click()
    }
    catch (error) {
      console.log("error =>", error)
    }
  }

  const closingLots = async (row, status) => {
    // setLoader({ ...loader, closeLot: true })
    setIsError(false)
    // setShowModal({ ...showModal, process: true })
    setModalLoader(true)

    try {
      const res = await api.put(`/lot/${row.id}`, {
        action: status,
        nf: {
          nameFile: dataFileNf.nameFile,
          hash_invoice: dataFileNf.hash_invoice,
          typeFile: dataFileNf.typeFile,
          extension: dataFileNf.extension,
        },
        boleto: {
          nameFile: dataFileBoleto.nameFile,
          hash_invoice: dataFileBoleto.hash_invoice,
          typeFile: dataFileBoleto.typeFile,
          extension: dataFileBoleto.extension
        }
      })

      setText(res.data.message)
    } catch (error) {
      setIsError(true)
      setText(`Error ao executar esta operação... [${error.response.data.message}]`)
      console.log("error =>", error.response)
    }
    // setLoader({ ...loader, closeLot: false })
    setShowModal({ ...showModal, apiResp: true, closeLot: false })
    setModalLoader(false)
    getLots()
  }

  useEffect(() => {
    getCustomers()
    setInputs(newLotFields)
  }, [showModal.newLot])

  useEffect(() => { getInvoices() }, [inputs.idPartner])
  useEffect(() => { getLots() }, [])

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>

            <div className="menu-center">
              <select
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                style={{ width: '15%' }}
                className="form-control"
              >
                <option value="">TODOS</option>
                <option value="1">APROVADOS</option>
                <option value="2">EM ABERTO</option>
                <option value="7">FECHADO</option>
                <option value="0">NEGADO</option>
              </select>

              <button
                disabled={loader.getLots}
                onClick={() => getLots()}
                style={{ marginLeft: 10, width: '120px' }}
                id="search-charge-back"
                type="button"
                className="btn btn-info"
              >
                {
                  loader.getLots
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    : <i className="fas fa-search fa-rotate-90" />
                }
              </button>
              <button
                onClick={() => setShowModal({ ...showModal, newLot: true })}
                style={{ width: '15%' }}
                type="button" className="btn btn-success"
              >
                <i className="la la la-plus la-lg" />&nbsp; Criar Lote
              </button>
            </div>

            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg" />
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              {
                loader.getLots ?
                  <div className="loader">
                    <h5>
                      <Loader />
                      <br />
                      Carregando Lotes...
                    </h5>
                  </div>
                  :
                  <section className="form-create-users" style={{ display: 'flex' }}>
                    <label className="text-label-title"><i className="fas fa-th-large" /> &nbsp;Fechamento de Lotes</label>

                    <div className="row-forms" style={{ flexDirection: 'column' }}>
                      <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                        <thead>
                          <tr role='row'>
                            <th className="sorting_asc">ID</th>
                            <th className="sorting_asc">DT. Transação</th>
                            <th className="sorting_asc">Parceiro Fornecedor</th>
                            <th className="sorting_asc">Parceiro Comercial</th>

                            <th className="sorting_asc">Tipo Serviço</th>
                            <th className="sorting_asc">Status</th>

                            <th className="sorting_asc">Ações</th>
                            <th className="sorting_asc">Notas</th>
                          </tr>
                        </thead>

                        <tbody>
                          {
                            data.map((row, idx) =>
                              <tr key={idx}>
                                <td className="sorting_asc">{row.id}</td>
                                <td className="sorting_asc">{Moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className="sorting_asc">{row.company_supplier.name}</td>
                                <td className="sorting_asc">{row.company_partner.name}</td>
                                <td className="sorting_asc">{row.description}</td>
                                <td className="sorting_asc">
                                  {
                                    row.status === 0 ?
                                      <div style={{ color: 'red' }}>Negado <i className="la la-times-circle red la-lg"></i></div>
                                      : row.status === 1 ?
                                        <div style={{ color: 'green' }}>Aprovado <i className="la la-check-circle green la-lg"></i></div>
                                        : row.status === 2 ?
                                          <div style={{ color: 'orange', display: 'flex', justifyContent: 'center' }}>
                                            Lote Aberto
                                          </div>
                                          : row.status === 3 ?
                                            <div style={{ color: 'gray' }}>Aceito <i className="la la-check-circle green la-lg"></i></div>
                                            : row.status === 4 ?
                                              <div style={{ color: 'gray' }}>Concluído <i className="la la-check-circle blue la-lg"></i></div>
                                              : row.status === 5 ?
                                                <div style={{ color: 'gray' }}>Finalizado <i className="la la-legal blue la-lg"></i></div>
                                                : row.status === 7 ?
                                                  <div style={{ color: 'gray' }}>Fechado <i className="la la-legal blue la-lg"></i></div>
                                                  : row.status === 8 ?
                                                    <div style={{ color: 'orange' }}>
                                                      <i className="la la-arrow-up la-lg" /> Parcial
                                                    </div>
                                                    :
                                                    <div>Não Identificado</div>
                                  }
                                </td>

                                <td className="sorting_asc">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className="light sharp i-false" >
                                      {svg1}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => { getLotDetails(row.id) }}
                                      >
                                        <i className="la la-info-circle yellow la-lg" /> Detalhes
                                      </Dropdown.Item>
                                      {
                                        companyType === '1' &&
                                        <>
                                          <Dropdown.Item
                                            onClick={() => toggleStatus(row, 1)}
                                          >
                                            <i className="la la-money green la-lg" /> Pagar
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => toggleStatus(row, 0)}
                                          >
                                            <i className="la la-close red la-lg" /> Rejeitar
                                          </Dropdown.Item>
                                        </>
                                      }
                                      {
                                        companyType === '3' && row.status !== 7 && row.status !== 1 &&
                                        <>
                                          <Dropdown.Item
                                            onClick={() => showCloseLot(row)}
                                          >
                                            <i className="la la-sign-out gray la-lg" /> Fechar
                                          </Dropdown.Item>
                                        </>
                                      }
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td className="sorting_asc">
                                  {
                                    row.extension ?
                                      <div
                                        onClick={() => download(row.id)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <i className="la la-cloud-download-alt yellow la-2x" />
                                      </div>
                                      :
                                      <div>
                                        <i class="las la-code red la-2x"></i>
                                      </div>
                                  }
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </section>
              }
            </div>

            {/* modal de lote novo */}
            <Rodal
              visible={showModal.newLot}
              animation="slideLeft"
              customStyles={{ width: '85%', height: 'calc(90%)', marginTop: '1%' }}
              showCloseButton={true}
              onClose={() => setShowModal({ ...showModal, newLot: false })}
            >
              <div className="close-rodal" onClick={() => setShowModal({ ...showModal, newLot: false })}>
                <Close fontSize="large" style={{ cursor: 'pointer' }} />
              </div>

              <div className="title">CRIAÇÃO DE LOTES</div>
              <div className="detal-modal">
                <div className="row-forms" style={{ textAlign: 'initial' }}>

                  <label className="text-label">Descrição *</label>
                  <textarea
                    type="text"
                    className="form-control"
                    value={inputs.description}
                    required
                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                  />
                  <hr />
                </div>

                <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                  <div className="form-group" >
                    <label className="text-label">Parceiro Comercial *</label>
                    <select
                      className='form-control form-control-lg'
                      id='inlineFormCustomSelect'
                      defaultValue={'option'}
                      style={{ width: '30%' }}
                      value={inputs.idPartner}
                      onChange={(e) => setInputs({ ...inputs, idPartner: e.target.value })}
                    >
                      <option value="">PARCEIRO COMERCIAL</option>
                      {
                        partners.map(row =>
                          <option value={row.id}>{row.name}</option>
                        )
                      }
                    </select>
                  </div>

                  <div className="row-forms" style={{ width: '100%', maxHeight: '200px', overflow: 'auto', padding: 10 }}>
                    <div className="form-group" >

                      {
                        loader.getInvoices ?
                          <ThreeDots
                            color="#0ccbec"
                            height={40}
                            width={40}
                            type="ThreeDots"
                          />
                          :
                          invoices.length > 0 ?
                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                              <thead>
                                <tr role='row'>
                                  <th style={{ textAlign: 'center' }} className="sorting_asc">--</th>
                                  <th className="sorting_asc">DT. Transação</th>
                                  <th className="sorting_asc">Nome</th>
                                  <th style={{ textAlign: 'right' }} className="sorting_asc">Total</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  invoices.length > 0 ?
                                    invoices.map((row, idx) =>
                                      <tr key={idx}>
                                        <td style={{ textAlign: 'center' }} className="sorting_asc">
                                          <input
                                            style={{ cursor: 'pointer' }}
                                            type="checkbox"
                                            onChange={(e) => toggleCheckbox(e.target.value)}
                                            name="invoicesId" value={row.id}
                                          // checked={dataCheckbox.includes(row.id) ? true : false}
                                          />
                                        </td>
                                        <td className="sorting_asc">
                                          {Moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </td>
                                        <td className="sorting_asc">{`Transação #${row.id}`}</td>
                                        <td
                                          style={{ textAlign: 'right' }}
                                          className="sorting_asc green bold">
                                          &nbsp; R$ {utils.formatMoneyBRL(row.total)}
                                        </td>

                                      </tr>
                                    )
                                    : <></>
                                }
                              </tbody>
                            </table>
                            :
                            'Nenhum resultado encontrado'
                      }
                    </div>
                  </div>

                  <div className="row-forms">
                    <div className="form-group" >
                      <button
                        disabled={loader.createLot || inputs.description === '' || inputs.idPartner === null}
                        onClick={() => createLot()}
                        style={{ width: '30%' }} type="button" className="btn btn-success"
                      >
                        {
                          loader.createLot ?
                            <>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                              &nbsp;Processando...
                            </>
                            :
                            'Criar '
                        }
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </Rodal>

            {/* modal de retorno da api */}
            <Rodal
              visible={showModal.apiResp}
              animation="flip"
              customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
              showCloseButton={true}
              onClose={() => setShowModal({ ...showModal, apiResp: false })}
            >
              <div className="close-rodal" onClick={() => setShowModal({ ...showModal, apiResp: false })}>
                <Close fontSize="large" style={{ cursor: 'pointer' }} />
              </div>

              <div className="modal-error">
                {
                  isError
                    ? <div><i className="fas fa-times red fa-2x" /></div>
                    : <div><i className="far fa-check-circle green fa-2x" /></div>
                }
                <div>{text}</div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                <div className="modal-error-buttons" />
              </div>
            </Rodal>

            {/* modal dos detalhes do lote */}
            <Rodal
              visible={showModal.invoiceDetails}
              animation="flip"
              customStyles={{ width: '60%', height: 'calc(80%)', marginTop: '7%' }}
              showCloseButton={true}
              onClose={() => setShowModal({ ...showModal, invoiceDetails: false })}
            >
              <div className="close-rodal" onClick={() => setShowModal({ ...showModal, invoiceDetails: false })}>
                <Close fontSize="large" style={{ cursor: 'pointer' }} />
              </div>

              <div className="detal-modal">
                {/* titulo */}
                <div className="row-forms">
                  <div className="form-group">
                    <label className="text-label blue">{`Detalhes do Lote #${invoiceDetails.id}`}</label>
                  </div>
                </div>
                {/* detalhamento cabeçalho */}
                <div className="row-forms" style={{ width: '100%', flexDirection: 'column' }}>
                  {
                    loader.invoiceDetails
                      ?
                      <ThreeDots
                        color="#0ccbec"
                        height={40}
                        width={40}
                        type="ThreeDots"
                      />
                      :
                      <div className="form-group" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                      }}>
                        <div>
                          <span className="bold">Data Criação: </span>
                          <span>{Moment(invoiceDetails.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                        </div>
                        <div>
                          <span className="bold">Descrição: </span><span>{invoiceDetails.description}</span>
                        </div>
                        <div>
                          <span className="bold">Status: </span>
                          {
                            invoiceDetails.status === 'Aberto' ?
                              <span style={{ color: 'blue' }}>{invoiceDetails.status}</span>
                              : invoiceDetails.status === 'Negado' ?
                                <span style={{ color: 'red' }}>{invoiceDetails.status}</span>
                                : invoiceDetails.status === 'Aprovado' ?
                                  <span style={{ color: 'green' }}>{invoiceDetails.status}</span>
                                  : invoiceDetails.status === 'Parcial' ?
                                    <span style={{ color: '#FFD700' }}>{invoiceDetails.status}</span>
                                    :
                                    <span>{invoiceDetails.status}</span>
                          }
                        </div>
                        <div>
                          <span className="bold">Total: </span><span>{`${utils.formatMoneyBRL(total)}`}</span>
                        </div>
                      </div>
                  }

                </div>
                {/* detalhamento notas */}
                <div className="row-forms" style={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 10 }}>
                  <div className="form-group" >
                    {
                      loader.invoiceDetails
                        ?
                        <ThreeDots
                          color="#0ccbec"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        invoiceDetails.invoices ?
                          <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                            <thead>
                              <tr role='row'>
                                <th className="sorting_asc">ID</th>
                                <th className="sorting_asc">DT. Transação</th>
                                <th style={{ textAlign: 'right' }} className="sorting_asc">Total</th>
                                <th style={{ textAlign: 'center' }} className="sorting_asc">Ações</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                invoiceDetails.invoices.length > 0 ?
                                  invoiceDetails.invoices.map((row, idx) =>
                                    <tr key={idx}>
                                      <td className="sorting_asc">{`I-${row.id}`}</td>
                                      <td className="sorting_asc">
                                        {Moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                      </td>
                                      <td className="sorting_asc green bold">
                                        &nbsp; R$ {utils.formatMoneyBRL(row.total)}
                                      </td>
                                      <td className="sorting_asc">
                                        {
                                          row.status !== 1 ?
                                            <Dropdown>
                                              <Dropdown.Toggle variant="success" className="light sharp i-false" >{svg1}</Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() => changeInvoices(row, 0)}
                                                >
                                                  <i className="la la-close red la-lg" /> Remover
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            '---'
                                        }
                                      </td>
                                    </tr>
                                  )
                                  : <></>
                              }
                            </tbody>
                          </table>
                          :
                          'Sem Transações para esta pesquisa...'
                    }

                    {
                      invoiceDetails.status == "Negado" &&
                      <>
                        <label className="text-label">Detalhes do Lote</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Motivo da recusa:"
                          disabled
                          value={invoiceDetails.reject}
                        />
                      </>
                    }

                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                <div className="modal-error-buttons" />
              </div>
            </Rodal>

            <Rodal
              visible={showModal.closeLot}
              animation="flip"
              customStyles={{ width: '50%', height: 'calc(80%)', marginTop: '7%' }}
              showCloseButton={true}
              onClose={() => setShowModal({ ...showModal, closeLot: false })}

            >
              <div className="close-rodal" onClick={() => setShowModal({ ...showModal, closeLot: false })}>
                <Close fontSize="large" style={{ cursor: 'pointer' }} />
              </div>
              <div className="detal-modal">
                <div className="row-forms">
                  <div className="form-group">
                    <label className="text-label blue">{`-- Fechamento de Lote #${lot.id} --`}</label>
                  </div>
                </div>
                <div className="row-forms" style={{ width: '100%', flexDirection: 'column' }}>
                  <div className="form-group" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',

                  }}>
                    <small> * Para fechar este Lote você precisa importar uma <span className="bold">Nota Fiscal.</span></small>
                  </div>
                </div>
                {/* upload docs */}
                <div className="row-forms" style={{ width: '100%', flexDirection: 'column' }}>
                  <div className="form-group" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',

                  }}>
                    {/* upload nf */}
                    <label>Anexar Nota Fiscal↴</label>
                    <input
                      type="file"
                      name="file"
                      style={{ marginBottom: '20px', paddingLeft: 25 }}
                      onChange={(e) => sendFile(e, 'nf')}
                    />
                    {/* upload boeto */}
                    <label >Anexar Boleto↴</label>
                    <input
                      style={{ paddingLeft: 25, }}
                      type="file"
                      disabled={dataFileNf.hash_invoice === ''}
                      onChange={(e) => sendFile(e, 'boleto')}
                    />

                  </div>
                </div>
                <div className="row-forms">
                  <div className="form-group" >
                    <button
                      disabled={dataFileBoleto.hash_invoice === '' || loader.closeLot ? true : false}
                      onClick={() => closingLots(lot, 7)}
                      style={{ width: '50%', cursor: styleButton }} type="button" className="btn btn-info"
                    >
                      {
                        loader.closeLot
                          ?
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp;Processando...
                          </>
                          :
                          <><i className="la la-sign-out white la-lg" /> Fechar Lote</>

                      }
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                <div className="modal-error-buttons" />
              </div>
            </Rodal>

            {/* Rodal loader */}
            <Rodal
              visible={modalLoader}
              animation="slideLeft"
              customStyles={{
                width: '30%',
                height: '140px',
                marginTop: '-5%',
                bottom: 'auto',
                top: '50%',
                transform: 'translateY(-50%)',

              }}
              showCloseButton={true}
            >
              <ModalLoader />
            </Rodal>
          </div>
        </div>
      </div>
    </div>
  )
}