import React, { useEffect, useState } from 'react'
import Menu from '../Panel/menu'
import Chart from 'react-apexcharts'
import { DateRange } from '@material-ui/icons'
// import Loader from '../Panel/loader'
import utils from '../../../../utils/utils.js'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import api from '../../../../services/api'
import { useMediaQuery } from 'react-responsive'
import ThreeDots from 'react-loader-spinner'

export default function App(props) {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [dateIni, setDateIni] = useState(utils.getDateBefore(89))
  const [dateEnd, setDateEnd] = useState(utils.getDate())
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState({
    amountCredits: 0,
    amountBalance: 0,
    amountComumer: 0,
    bestCredits: [],
    nameCompany: []
  })
  const ID_COMPANY = localStorage.getItem('ID_COMPANY')
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })

  console.log('screen =>', isBigScreen)

  const optionsAreaValue = {

    chart: {
      width: '100%',
      height: '100%',
      type: "bar",
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    title: {
      text: '...',
      align: 'left',
      style: {
        fontSize: '13px',
        color: '#666',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
      },
    },
    colors: ['#51C4C7', '#a27871', '#f19f40', '#e35b5c', '#794d75'],


    fill: {
      // type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],

      }

    },
    dataLabels: {
      //Enable legends in Grafics
      enabled: true,

      // formatter: function (val, opt) {
      //     console.log( opt.w.globals.labels[opt.dataPointIndex] + ":  " + val)
      //   },

      style: {
        fontSize: '13px'
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: 'straight',
      // dashArray: [0, 0, 0, 0, 0]
    },
    xaxis: {
      categories: data.nameCompany,
      labels: {

        style: {
          fontSize: '13px',
          fontWeight: 'bold'
        },

      }
    },

    yaxis: {
      showForNullSeries: true,

      labels: {
        style: {
          // color: '#0abab5',
        },
      }


    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetY: -5,
      fontWeight: 'bold',
      // height: 230,
      fontSize: '13px',


    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    // here
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        // legend on hover
        show: true,

        //     formatter: function (val, opt) {

        //         return 
        //     // console.log(opt.w.config.series[0].description[opt.dataPointIndex])
        //     // return(
        //     //     opt.w.config.series[0].description[opt.dataPointIndex] + '<br/>'+
        //     //     'SEGMENTO: '+ opt.w.config.series[0].segmento[opt.dataPointIndex] + '<br/>'+
        //     //     'MÉDIA ULT. 90 DIAS: '+ main.formatMoneyBRL(opt.w.config.series[0].media[opt.dataPointIndex]) + '<br/>'+
        //     //     'TOTAL DE TRANSAÇÕES: '+ main.formatMoneyBRL(opt.w.config.series[0].total[opt.dataPointIndex])+ '<br/>'
        //     //     )
        //   }

      },
      y: {


      }
    }
  }
  const series = [{
    name: 'TOP 10 PARCEIROS COMERCIAIS COM MAIS CRÉDITO RECEBIDO',
    data: data.bestCredits
  }]

  const getData = async () => {

    setLoader(true)

    try {

      const firstRes = await api.get(`/report/1/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      console.log('firstRes', firstRes.data[0])
      const secondRes = await api.get(`/report/2/${ID_COMPANY}/${dateIni}/${dateEnd}`)
      const thirdRes = await api.get(`/report/3/${ID_COMPANY}/${dateIni}/${dateEnd}`)

      let nameCompany = []
      let valueCompany = []

      thirdRes.data.map(row => {
        nameCompany.push(row.name)
        valueCompany.push(row.total)

      })
      console.log(`valueCompany =>`, valueCompany)

      setData({
        ...data,
        amountCredits: firstRes.data[0].total,
        amountBalance: firstRes.data[0].total_saldo,
        amountComumer: secondRes.data[0].total,
        nameCompany: nameCompany,
        bestCredits: [firstRes.data[0].total_saldo]
      })


    } catch (error) {

      console.log("error =>", error.response.data.message)
    }

    setLoader(false)

  }

  useEffect(() => {

    getData()

  }, [])


  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">
              <DateRange fontSize="large" />
              <input style={{ width: '15%', height: '35px' }}
                value={dateIni}
                id="dateIni"
                onChange={(e) => setDateIni(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
              <input style={{ width: '15%', height: '35px' }}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <button type="button"
                className="btn btn-info"
                onClick={() => getData()}
                disabled={loader}
                style={{ width: '150px', height: '38px' }}
              >
                {
                  loader
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>
            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">

              <div className="indicator-top bold">
                <i className="la la-bar-chart-o blue fa-lg"></i> &nbsp; DASHBOARD GERENCIAL - PC
              </div>

              <div className="indicator-container">
                <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                  QTD. de Parceiros Comerciais com Créditos Gerados</Tooltip>}>
                  <div className="dashbord" style={{ width: '32%' }}>
                    <i className="la la-users blue fa-2x"></i>&nbsp;
                    {
                      loader ?
                        <ThreeDots
                          color="#07aecc"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        data.amountCredits
                    }
                  </div>
                </OverlayTrigger>
                <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                  Saldos Gerados</Tooltip>}>
                  <div className="dashbord" style={{ width: '32%' }}>
                    <i className="flaticon-381-network green fa-2x"></i>&nbsp;
                    {
                      loader ?
                        <ThreeDots
                          color="#07aecc"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        <>R$ {utils.formatMoneyBRL(data.amountBalance)}</>
                    }
                  </div>
                </OverlayTrigger>
                <OverlayTrigger defaultShow={true} rootClose={true} placement="bottom" overlay={<Tooltip >
                  Saldos Consumidos</Tooltip>}>
                  <div className="dashbord" style={{ width: '32%' }}>
                    <i className="flaticon-381-network green fa-2x"></i>&nbsp;
                    {
                      loader ?
                        <ThreeDots
                          color="#07aecc"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        <>R$ {utils.formatMoneyBRL(data.amountComumer)}</>
                    }
                  </div>
                </OverlayTrigger>
              </div>

              <div className="indicator-footer">

                <div className="dashbord" >
                  <Chart options={optionsAreaValue} series={series} height={isBigScreen ? "100%" : "300"} width={isBigScreen ? "1500" : "1000"} type={'bar'} />
                </div>
                {/* <div className="dashbord" style={{ width: '35%' }}>
                  <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                    <thead>
                      <tr role='row'>
                        <th style={{ textAlign: 'start' }} className="sorting_asc">TOP 10 PARCEIROS COMERCIAIS</th>
                        <th style={{ textAlign: 'end' }} className="sorting_asc">VALOR</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        datavalues.map((row, idx) =>
                          <tr key={idx}>
                            <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                            <td style={{ textAlign: 'end' }} className="sorting_asc bold">&nbsp; R$ {utils.formatMoneyBRL(row.value)}</td>
                          </tr>
                        )

                      }
                    </tbody>
                  </table>
                </div> */}
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}