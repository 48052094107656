import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'

export default function App(props) {

   const title = 'Grupo';
   const titleName = `Criação da ${title}`;
   const [toggleMenu, setToggleMenu] = useState(false)
   const fields = {
      name: '',
      description: null,
      active: '1'
   }
   const [inputs, setInputs] = useState(fields)
   const [showModal, setShowModal] = useState(false)
   const [isError, setIsError] = useState(false)
   const [loader, setLoader] = useState(false)
   const [text, setText] = useState('')
   const [isDisabled, setIsDisabled] = useState(true)

   const create = async () => {
      setLoader(true)
      setIsError(false)

      try {
         let res = await api.post("/advertising/groups", {
            name: inputs.name,
            description: inputs.description,
            active: inputs.active
         });
         setText(res.data.message ?? res.statusText);
         setInputs(fields);
         setShowModal(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
            idx ++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
         setShowModal(true);
      }
      setLoader(false)
   }

   // habilita botao criar
   useEffect(() => {
      setIsDisabled(false)

      if (inputs.name === '') {
         setIsDisabled(true)
      }
   }, [inputs])

   const handleCancel = async () => {
      setShowModal(false)
      props.history.push('/list-advertising-group')
   }


   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising-group') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Nome*  </label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome"
                                    value={inputs.name}
                                    onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Description */}
                              <div className="form-group">
                                 <label className="text-label">Descrição</label>
                                 <textarea
                                    className="form-control"
                                    value={inputs.description}
                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                 >
                                    <option value='1'>Sim</option>
                                    <option value='0'>Não</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabled} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Criar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => handleCancel()}

                  >
                     <div className="close-rodal" onClick={() => handleCancel()}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

