import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Rodal from 'rodal';
import { Close } from '@material-ui/icons';
import utilsSelect from '../../../../utils/selects';

export default function App(props) {

   const title = 'Contrato';
   const titleName = `Edição da ${title}`;
   const [data] = useState(props.location.state.data)
   const [toggleMenu, setToggleMenu] = useState(false);
   const [filteredData, setFilteredData] = useState({})
   const [dataSpace, setDataSpace] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [isError, setIsError] = useState(false);
   const [isSaveSuccess, setSaveSuccess] = useState(false);
   const [loader, setLoader] = useState(false);
   const [text, setText] = useState('');
   const [isDisabled, setIsDisabled] = useState(true);
   const [list_select_yes_or_no, setListYesOrNo] = useState([]);

   const getSpaces = async () => {
      try {
         let res = await api.get(`advertising/spaces`);
         const objAux = [];
         res.data.data.forEach(element => {
            objAux.push({ Id: element.id, Name: element.name })
         });
         setDataSpace(objAux);
      } catch (err) {
         console.log(err);
      }
   }

   const edit = async () => {
      setLoader(true)
      setIsError(false)

      try {
         debugger
         const res = await api.put(`advertising/contract_company/${filteredData.id}`, {
            advertisings_space: filteredData.advertisings_space,
            validity_start: filteredData.validity_start,
            validity_end: filteredData.validity_end,
            active: filteredData.active
         })

         setText(res.data.message ?? res.statusText);
         setShowModal(true);
         setSaveSuccess(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
            idx++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError);
         setShowModal(true);
         setSaveSuccess(false);
      }
      setLoader(false)
   }

   useEffect(() => {
      if (!showModal && isSaveSuccess) {
         props.history.push('/list-advertising-contract')
      }
   }, [showModal])


   // habilita botao criar
   useEffect(() => {
      setIsDisabled(false)
      if (filteredData.advertisings_space === '0' || filteredData.validity_start === '' || filteredData.validity_end === '') {
         setIsDisabled(true)
      }
   }, [filteredData])

   useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])
   useEffect(() => { getSpaces() }, [])
   useEffect(() => {
      setFilteredData({
         id: data.id,
         advertisings_space: data.advertising_space.id,
         validity_start: data.validity_start,
         validity_end: data.validity_end,
         active: data.active
      })
   }, [data])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising-contract') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="row-forms">
                              {/* Type */}
                              <div className="form-group">
                                 <label className="text-label">Tipo *</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setFilteredData({ ...filteredData, advertisings_space: e.target.value })}
                                 >
                                    <option value='0' selected> -- Escolha uma opção -- </option>
                                    {
                                       dataSpace.map((item) =>
                                          <option key={item.Id} value={item.Id} selected={filteredData.advertisings_space === item.Id}>
                                             {item.Name}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                           <div className="form-group">
                              <label className="text-label">Data inicio validade* </label>
                              <input
                                 type="date"
                                 className="form-control"
                                 value={filteredData.validity_start}
                                 onChange={(e) => setFilteredData({ ...filteredData, validity_start: e.target.value })}
                                 setIsDisabled={false}
                              />
                           </div>
                           <div className="form-group">
                              <label className="text-label">Data final validade* </label>
                              <input
                                 type="date"
                                 className="form-control"
                                 value={filteredData.validity_end}
                                 onChange={(e) => setFilteredData({ ...filteredData, validity_end: e.target.value })}
                                 setIsDisabled={false}
                              />
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setFilteredData({ ...filteredData, active: e.target.value })}
                                 >
                                    {
                                       list_select_yes_or_no.map((item, index) =>
                                          <option key={index} value={item.value} selected={filteredData.active === item.value}>
                                             {item.text}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabled} onClick={() => edit()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Editar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => setShowModal(false)}

                  >
                     <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

