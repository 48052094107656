import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import { Dropdown } from "react-bootstrap"
import { Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils.js'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import CurrencyFormat from 'react-currency-format'
import ModalLoader from '../Loader/modalLoader'

export default function App(props) {


  const [toggleMenu, setToggleMenu] = useState(false)
  const [loaders, setLoaders] = useState({
    dataCredits: false,
    invoicesDetails: false
  })
  const [dataCredits, setDataCredits] = useState([])
  const [isError, setIsError] = useState(false)
  const [text, setText] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [dataLot, setDataLot] = useState([])
  const [filter, setFilter] = useState({
    id: '',
    status: ''
  })
  const [total, setTotal] = useState(0)
  const [showModalLotDetails, setShowModalLotDetails] = useState(false)
  const [isReasonRefuse, setIsReasonRefuse] = useState(false)
  const [description, setDescription] = useState('')
  const [isEnableButton, setIsEnableButton] = useState(true)
  const [showModalPartialItems, setShowModalPartialItems] = useState(false)
  const [showModalDenyAllItems, setShowModalDenyAllItems] = useState(false)
  const [styleButton, setStyleButton] = useState('not-allowed')
  const [approvedItems, setApprovedItems] = useState([])
  Moment.locale('pt')
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const [selectedRow, setSelectedRow] = useState([])
  const [modalLoader, setModalLoader] = useState(false)

  const getLots = async () => {
    setLoaders({ ...loaders, dataCredits: true })
    setIsError(false)

    try {
      let filters = '?'

      if (filter.status) {
        filters += `status=${filter.status}&`
      }

      filters = filters.substring(filters[0], filters.length - 1)
      let res

      if (filters) {
        res = await api.get(`/lot${filters}`)
        setDataCredits(res.data)
      } else {
        res = await api.get(`/lot`)
        setDataCredits(res.data)
      }
    } catch (error) {
      setIsError(true)
      setText(error.response.data.message)
      console.log("error =>", error)
    }
    setLoaders({ ...loaders, dataCredits: false })
  }

  const toggleStatus = async (row, status) => {
    setShowModalPartialItems(false)
    setShowModalLotDetails(false)
    setModalLoader(true)

    try {
      const res = await api.put(`/lot/${row.id}`, {
        action: status,
        description: description,
        approvedItems: approvedItems
      })

      if (res.data) {
        console.log(`toggleStatus =>`, res.data)
        setText(res.data.message)
        setShowModal(true)
      }
    } catch (error) {
      setModalLoader(false)
      console.log("error =>", error.response)
      setIsError(true)
      setText(`Error ao executar esta operação... [${error.response.data.message}]`)
      setShowModal(true)
    }

    setModalLoader(false)
  }

  useEffect(() => {
    if (!showModal) {
      getLots()
      setShowModalDenyAllItems(false)
      setShowModalPartialItems(false)
      closeModalRefused()
    }
  }, [showModal])

  const download = async (id) => {

    try {
      const res = await api.get(`/lot/${id}`)
      console.log('res.data', res.data)
      let a = document.createElement("a")
      a.href = res.data.hash_invoice
      if (res.data.name_file) {
        a.download = `${res.data.name_file}.${res.data.extension}`
      } else {
        a.download = `Nota_Fiscal.${res.data.extension}`
      }
      a.click()
    }
    catch (error) {
      console.log("error =>", error)
    }
  }

  // busca os detalhes do lote selecionado
  const getLotDetails = async (id) => {
    setLoaders({ ...loaders, invoicesDetails: true })
    setDataLot([])

    try {
      const res = await api.get(`/lot/${id}`)
      console.log(`getLotDetails =>`, res.data)
      setDataLot(res.data)
      let total = 0

      if (res.data.invoices) {
        res.data.invoices.forEach(invoice => {
          total += invoice.total
        })
      }
      setTotal(total)
      setShowModalLotDetails({ ...showModal, details: true })
    }
    catch (error) {
      console.log("error =>", error)
    }
    setLoaders({ ...loaders, invoicesDetails: false })
  }

  const openModalRefused = async (isVisible, row) => {
    setIsReasonRefuse(isVisible)
    setSelectedRow(row)
  }

  useEffect(() => { getLots() }, [])

  useEffect(() => {
    if (description.length >= 5) {
      setIsEnableButton(false)
      setStyleButton('pointer')
    } else {
      setIsEnableButton(true)
      setStyleButton('not-allowed')
    }

  }, [description])

  function closeModalRefused() {
    setIsReasonRefuse(false)
    setDescription('')
  }

  const handleApprovedChange = (id, event) => {

    let _approvedItemsTmp = [...approvedItems]
    if (event.target.checked) {
      const index = _approvedItemsTmp.findIndex((b) => b.id === id)
      if (index === -1) {
        _approvedItemsTmp.push({
          id: id
        });
      }
    } else {
      _approvedItemsTmp = _approvedItemsTmp.filter((as) => as.id !== id)
    }

    setApprovedItems(_approvedItemsTmp)

  }

  const validatedPartialItems = async (row) => {

    if (approvedItems.length > 0) {
      const statusPartial = (approvedItems.length < dataLot.invoices.length) ? true : false

      if (statusPartial) {
        setShowModalPartialItems(true)
      } else if (approvedItems.length === dataLot.invoices.length) {
        toggleStatus(dataLot, 1)
      }

    } else {
      setShowModalDenyAllItems(true)
    }
  }

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>

            <div className="menu-center">
              <select
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                style={{ width: '15%' }}
                className="form-control"
              >
                <option value="">TODOS</option>
                <option value="1">APROVADOS</option>
                <option value="2">EM ABERTO</option>
                <option value="7">FECHADO</option>
                <option value="0">NEGADO</option>
              </select>

              <button disabled={loaders.dataCredits}
                onClick={() => getLots()}
                style={{ marginLeft: 10, width: '120px' }}
                id="search-charge-back"
                type="button"
                className="btn btn-info"
              >
                {
                  loaders.dataCredits
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>
            </div>

            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              {
                loaders.dataCredits ?
                  <div className="loader">
                    <h5>
                      <Loader />
                      <br />
                      Carregando ...
                    </h5>
                  </div>
                  :
                  isError
                    ?
                    <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center' }}>
                      <div><i className="fas fa-times error fa-2x"></i></div>
                      <div> <h3>{text}</h3></div>
                    </section>
                    :
                    dataCredits.length > 0
                      ?
                      <section className="form-create-users" style={{ display: 'flex' }}>
                        <label className="text-label-title"><i className="fas fa-th-large"></i> &nbsp; Pagamento de Lotes</label>
                        <div className="separator" />
                        <div className="row-forms" style={{ flexDirection: 'column' }}>
                          <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                            <thead>
                              <tr role='row'>
                                <th className="sorting_asc">ID</th>
                                <th className="sorting_asc">Parceiro Fornecedor</th>
                                <th className="sorting_asc">Parceiro Comercial</th>
                                <th className="sorting_asc">Data Lote</th>
                                <th className="sorting_asc">Descrição</th>
                                <th className="sorting_asc">Vencimento</th>
                                <th className="sorting_asc">Status</th>
                                <th className="sorting_asc">Ações</th>
                                <th className="sorting_asc">Notas</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                dataCredits.length > 0 ?
                                  dataCredits.map((row, idx) => (

                                    <tr role='row' key={idx} className={Moment().isAfter(row.expiration) ? 'expired' : ''}>
                                      <td className="sorting_asc">{row.id}</td>
                                      <td className="sorting_asc">{row.company_supplier.name}</td>
                                      <td className="sorting_asc">{row.company_partner.name}</td>
                                      <td className="sorting_asc">
                                        {Moment(row.created_at).format('DD/MM/YYYY')}
                                      </td>
                                      <td className="sorting_asc">{row.description}</td>
                                      <td className="sorting_asc">{Moment(row.expiration).format('DD/MM/YYYY')}</td>
                                      <td className="sorting_asc">
                                        {
                                          row.status === 0 ?
                                            <div style={{ color: 'red' }}>
                                              <i className="la la-times-circle red la-lg" /> Negado
                                            </div>
                                            : row.status === 1 ?
                                              <div style={{ color: 'green' }}>
                                                <i className="la la-check-circle green la-lg" /> Aprovado
                                              </div>
                                              : row.status === 2 ?
                                                <div style={{ color: 'orange' }}>
                                                  <i className="la la-info-circle la-lg" /> Aguardando Aprovação
                                                </div>
                                                : row.status === 3 ?
                                                  <div style={{ color: 'gray' }}>
                                                    <i className="la la-check-circle green la-lg" /> Aceito
                                                  </div>
                                                  : row.status === 4 ?
                                                    <div style={{ color: 'gray' }}>
                                                      <i className="la la-check-circle blue la-lg" /> Concluído
                                                    </div>
                                                    : row.status === 5 ?
                                                      <div style={{ color: 'gray' }}>
                                                        <i className="la la-legal blue la-lg" /> Finalizado
                                                      </div>
                                                      : row.status === 7 ?
                                                        <div style={{ color: 'gray' }}>
                                                          <i className="la la-legal blue la-lg" /> Fechado
                                                        </div>
                                                        : row.status === 8 ?
                                                          <div style={{ color: 'orange' }}>
                                                            <i className="la la-arrow-up la-lg" /> Parcial
                                                          </div>
                                                          :
                                                          <div>Não Identificado</div>
                                        }
                                      </td>
                                      <td className="sorting_asc">
                                        <Dropdown >
                                          <Dropdown.Toggle
                                            variant="success"
                                            className="light sharp i-false"
                                            style={{
                                              width: '100px',
                                              height: '20px'
                                            }}
                                          >
                                            {svg1}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => { getLotDetails(row.id) }}>
                                              <i className="la la-info-circle yellow la-lg" /> Detalhes
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => toggleStatus(row, 1)} >
                                              <i className="la la-money green la-lg" /> Pagar
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => openModalRefused(true, row)}>
                                              <i className="la la-close red la-lg" /> Rejeitar
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                      <td className="sorting_asc">
                                        <div
                                          onClick={() => download(row.id)} style={{ cursor: 'pointer' }}
                                        >
                                          <i className="la la-newspaper-o yellow la-2x"></i>
                                          <i className="la la-arrow-down yellow la-2x"></i>
                                        </div>
                                      </td>
                                    </tr>

                                  ))
                                  : <></>
                              }
                            </tbody>

                          </table>
                        </div>
                      </section>
                      :
                      <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                        <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                      </section>
              }
              {/* modal rejeição venda  */}
              <Rodal
                visible={isReasonRefuse}
                animation="flip"
                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                showCloseButton={true}
                onClose={() => setIsReasonRefuse(false)}
              >
                <div className="close-rodal" onClick={() => setIsReasonRefuse(false)} style={{ top: 0 }}>
                  <Close onClick={e => (false)} fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                  {/* titulo */}
                  <div className="content-modal">
                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                      <h2 style={{ fontSize: "30px", }}>Rejeitar Pagamento</h2>
                    </div>

                    <label style={{ fontWeight: 'bold', textAlign: 'start' }}>Digite o motivo: </label>
                    <textarea
                      value={description}
                      required
                      onChange={e => setDescription(e.target.value)}
                      type="text"
                      placeholder="Mínimo 5 caracteres"
                      minlength="5"
                      style={{ height: '80px', maxHeight: '80px' }}

                    />

                  </div>

                  <div className="buttonsModal">
                    <button className="buttonModal" style={{ cursor: styleButton }} disabled={isEnableButton} onClick={() => toggleStatus(selectedRow, 0)}>ENVIAR</button>
                    <button className="buttonModal" onClick={() => setIsReasonRefuse(false)}>VOLTAR</button>
                  </div>
                </div>

              </Rodal>
              {/* modal de detalhes do lote */}
              <Rodal
                visible={showModalLotDetails}
                animation="flip"
                customStyles={{ width: '60%', height: 'calc(90%)', marginTop: '2%' }}
                showCloseButton={true}
                onClose={() => setShowModalLotDetails(false)}

              >
                <div className="close-rodal" onClick={() => setShowModalLotDetails(false)}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal">
                  {/* titulo */}
                  <div className="row-forms">
                    <div className="form-group">
                      <label className="text-label blue">{`Detalhes do Lote #${dataLot.id}`}</label>
                    </div>
                  </div>
                  {/* detalhamento cabeçalho */}
                  <div className="row-forms" style={{ width: '100%', flexDirection: 'column' }}>
                    {
                      loaders.details
                        ?
                        <ThreeDots
                          color="#0ccbec"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                        :
                        <div className="form-group" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'baseline',
                          justifyContent: 'flex-start',
                        }}>
                          <div>
                            <span className="bold">Data Criação: </span>
                            <span>{Moment(dataLot.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                          </div>
                          <div>
                            <span className="bold">Descrição: </span><span>{dataLot.description}</span>
                          </div>
                          <div>
                            <span className="bold">Status: </span>
                            <span>
                              {
                                dataLot.status === 'Aberto' ?
                                  <span style={{ color: 'blue' }}>{dataLot.status}</span>
                                  : dataLot.status === 'Negado' ?
                                    <span style={{ color: 'red' }}>{dataLot.status}</span>
                                    : dataLot.status === 'Aprovado' ?
                                      <span style={{ color: 'green' }}>{dataLot.status}</span>
                                      : dataLot.status === 'Parcial' ?
                                        <span style={{ color: '#FFD700' }}>{dataLot.status}</span>
                                        :
                                        <span>{dataLot.status}</span>
                              }
                            </span>
                          </div>
                          <div>
                            <span className="bold">Total: </span><span>{`${utils.formatMoneyBRL(total)}`}</span>
                          </div>
                          <div>
                            <span className="bold">Vencimento: </span><span>{Moment(dataLot.expiration).format('DD/MM/YYYY')}</span>
                          </div>
                        </div>
                    }

                  </div>
                  {/* detalhamento notas */}
                  <div className="row-forms" style={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 10 }}>
                    <div className="form-group" >
                      {
                        loaders.invoicesDetails
                          ?
                          <ThreeDots
                            color="#0ccbec"
                            height={40}
                            width={40}
                            type="ThreeDots"
                          />
                          :
                          dataLot.invoices
                            ?
                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                              <thead>
                                <tr role='row'>
                                  <th className="sorting_asc">ID</th>
                                  <th className="sorting_asc">Data Transação</th>
                                  <th className="sorting_asc">Comprador</th>
                                  <th className="sorting_asc">Fornecedor</th>
                                  <th className="sorting_asc">Total R$</th>
                                  <th>Aprovados</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  dataLot.invoices.length > 0 ?
                                    dataLot.invoices.map((row, idx) =>
                                      <tr key={idx}>
                                        <td className="sorting_asc">{`I-${row.id}`}</td>
                                        <td className="sorting_asc">
                                          {Moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </td>
                                        <td className="sorting_asc">{`${row.company_partner.name}`}</td>
                                        <td className="sorting_asc">{`${row.company_supplier.name}`}</td>
                                        <td className="sorting_asc green bold">
                                          &nbsp; R$ {utils.formatMoneyBRL(row.total)}
                                        </td>
                                        <td className="sorting_asc">
                                          {
                                            dataLot.status == "Parcial" || dataLot.status == "Aprovado" ?
                                              row.status == 1 ?
                                                <input
                                                  type='checkbox'
                                                  checked
                                                />
                                                :
                                                <input
                                                  type='checkbox'
                                                  disabled
                                                />
                                              : dataLot.status == "Fechado" ?
                                                <input
                                                  type='checkbox'
                                                  onChange={(e) => handleApprovedChange(row.id, e)}
                                                />
                                                : <></>
                                          }

                                        </td>
                                      </tr>
                                    )
                                    : <></>
                                }
                              </tbody>
                            </table>
                            :
                            'Nenhum resultado encontrado'
                      }

                      {
                        dataLot.status == "Negado" &&
                        <>
                          <label className="text-label">Detalhes do Lote</label>
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Motivo da recusa:"
                            disabled
                            value={dataLot.reject}
                          />
                        </>
                      }

                    </div>
                  </div>
                </div>
                {
                  dataLot.status == "Fechado" &&
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '20px' }}>
                      <button
                        onClick={() => setShowModalLotDetails(false)}
                        style={{ marginLeft: 10, width: '120px' }}
                        type="button"
                        className="btn btn-info"
                      >
                        Cancelar
                      </button>
                      <button
                        onClick={() => validatedPartialItems(dataLot)}
                        style={{ marginLeft: 10, width: '120px' }}
                        type="button"
                        className="btn btn-success"
                      >
                        Confirmar
                      </button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                      <div className="modal-error-buttons" />
                    </div>
                  </>
                }
              </Rodal>
              {/* modal de resposta da api */}
              <Rodal
                visible={showModal}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '5%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}

              >
                <br /><br />
                <div className="modal-error">
                  {
                    isError
                      ?
                      <>
                        <div><i className="fas fa-times error fa-2x"></i></div>
                        <div> {text}</div>
                      </>
                      :
                      <>
                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                        <div><i className="fas fa-coins green white"></i> {text}</div>
                      </>
                  }

                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                  <div className="modal-error-buttons" />
                </div>
              </Rodal>
              {/* Modal de confirmação de reprovação de items não selecionados */}
              <Rodal
                visible={showModalPartialItems}
                animation="flip"
                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                showCloseButton={true}
                onClose={() => (false)}
              >
                <div className="close-rodal" onClick={() => setShowModalPartialItems(false)} style={{ top: 0 }}>
                  <Close onClick={e => (false)} fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                  {/* titulo */}
                  <div className="content-modal">
                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                      <h2 style={{ fontSize: "30px", }}>Rejeitar Itens</h2>
                      <span>Ao não selecionar os itens eles serão automaticamente rejeitados.</span>
                    </div>

                  </div>

                  <div className="buttonsModal">
                    <button className="buttonModal" onClick={() => toggleStatus(dataLot, 8)}>CONFIRMAR</button>
                    <button className="buttonModal" onClick={() => setShowModalPartialItems(false)}>VOLTAR</button>
                  </div>
                </div>

              </Rodal>
              {/* Modal de confirmação de reprovação de todos os items */}
              <Rodal
                visible={showModalDenyAllItems}
                animation="flip"
                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                showCloseButton={true}
                onClose={() => (false)}
              >
                <div className="close-rodal" onClick={() => setShowModalDenyAllItems(false)} style={{ top: 0 }}>
                  <Close onClick={e => (false)} fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                  {/* titulo */}
                  <div className="content-modal">
                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                      <h2 style={{ fontSize: "30px", }}>Rejeitar Itens</h2>
                      <span>Ao não selecionar nenhum item todos eles serão automaticamente rejeitados.</span>
                    </div>

                  </div>

                  <div className="buttonsModal">
                    <button className="buttonModal" onClick={() => toggleStatus(dataLot, 0)}>CONFIRMAR</button>
                    <button className="buttonModal" onClick={() => setShowModalDenyAllItems(false)}>VOLTAR</button>
                  </div>
                </div>

              </Rodal>

              {/* Rodal loader */}
              <Rodal
                visible={modalLoader}
                animation="slideLeft"
                customStyles={{
                  width: '30%',
                  height: '140px',
                  marginTop: '-5%',
                  bottom: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)',

                }}
                showCloseButton={true}
              >
                <ModalLoader />
              </Rodal>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

