import React, { useState } from 'react';

function ImageUploading() {
    const [inputsImage, setInputsImage] = useState([]);
    const [styleImg, setStyleImg] = useState(null);
    const [styleBtn, setStyleBtn] = useState(null)

    const uploadImg = async () => {
        const inputFile = document.querySelector("#picture__input")
        const pictureImage = document.querySelector(".picture__image")
        const img = document.querySelector(".box__img")

        const pictureImageTxt = "Importar imagem"
        pictureImage.innerHTML = pictureImageTxt
        inputFile.addEventListener("change", async function (e) {
            const inputTarget = e.target;
            const file = inputTarget.files[0]

            if (file) {
                const reader = new FileReader()

                reader.addEventListener("load", function (e) {
                    const readerTarget = e.target
                    img.src = readerTarget.result;
                    img.style.display = 'block';
                    setInputsImage({ ...inputsImage, image: img.src })
                });
                reader.readAsDataURL(file)
            }
        });
    }

    function clearIMG() {
        const img = document.querySelector(".box__img");
        img.url = '';
        img.style.display = 'none';
        setInputsImage({ ...inputsImage, image: '' });
    }

    return <div>
        <label className="picture" for="picture__input">
            <span
                className="picture__image"
                style={{ display: styleImg }}
            >
                Importar imagem
            </span>
        </label>
        <input
            onClick={() => { uploadImg() }}
            type="file"
            name="picture__input"
            id="picture__input"
        />
        <i class="fa fa-trash" id="icon__trash"
            onClick={() => clearIMG()}
        />
        <div className="div__img">
            <img
                className="box__img"
                src={inputsImage.image}
                style={{ display: styleBtn }}
            />
        </div>
    </div>;

}

export default ImageUploading;