import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import Rodal from 'rodal'
import { FilterList, Close } from '@material-ui/icons'
import Pagination from '../../Pagination'

export default function App(props) {

   const [toggleMenu, setToggleMenu] = useState(false)
   const [loaders, setLoaders] = useState({
      users: false,
      delete: false
   })
   const [showModal, setShowModal] = useState(false)
   const [data, setData] = useState([])
   const [filteredData, setFilteredData] = useState([])
   const [dataDelete, setDataDelete] = useState({})
   const [type, setType] = useState('')
   const [active, setActive] = useState('')

   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(10);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
   const paginate = pageNumber => setCurrentPage(pageNumber);

   const getCategories = async () => {
      setLoaders({ ...loaders, users: true })

      try {
         let filters = '?'

         if (type != '') {
            filters += `type=${type}&`
         }

         if (active != '') {
            filters += `active=${active}&`
         }
         filters = filters.substring(filters[0], filters.length - 1)

         let res
         if (filters) {
            res = await api.get(`/category${filters}`)
            setData(res.data)
            setFilteredData(res.data)
            console.log("request Filters->>", res.data)
         } else {
            res = await api.get(`/category`)
            setData(res.data)
            setFilteredData(res.data)
            console.log("request->>", res.data)
            // setFilteredData(res.data)
         }
         setCurrentPage(1)
      } catch (err) {
         console.log(err)
      }
      setLoaders({ ...loaders, users: false })
   }

   const updateStatusCategory = async () => {
      setLoaders({ ...loaders, delete: true })
      console.log('dataDelete', dataDelete)
      try {
         const response = await api.delete(`/category/${dataDelete.id}`)
         getCategories()
         setShowModal(false)
      } catch (err) {
         console.log(err)
      }
      setLoaders({ ...loaders, delete: false })
   }

   useEffect(() => {
      getCategories()
   }, [])

   useEffect(() => {
      console.log(dataDelete)
   }, [dataDelete])

   useEffect(() => {
      let arr = []

      if (type === 'C') {
         currentPosts.map((row) => {
            if (row.id_category)
               arr.push(row)
         })
         setFilteredData(arr)
      } else if (type === 'S') {
         currentPosts.map((row) => {
            if (!row.id_category)
               arr.push(row)
         })
         setFilteredData(arr)
      } else {
         setFilteredData(currentPosts)
      }
   }, [type])

   useEffect(() => {
      let arr = []

      if (type === 'C') {
         currentPosts.map((row) => {
            if (row.id_category)
               arr.push(row)
         })
         setFilteredData(arr)
      } else if (type === 'S') {
         currentPosts.map((row) => {
            if (!row.id_category)
               arr.push(row)
         })
         setFilteredData(arr)
      } else {
         setFilteredData(currentPosts)
      }
   }, [active])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <div className="filters-menu">
                        <div className="input-group-prepend">
                           <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                              <FilterList fontSize="default" className="blue" />
                           </div>
                        </div>
                        <select
                           style={{ width: '20%' }}
                           className="form-control"
                           onChange={(e) => setType(e.target.value)}
                        >
                           <option value="">TODOS OS GRUPOS</option>
                           <option value="category">CATEGORIAS</option>
                           <option value="subcategory">SUBCATEGORIAS</option>
                        </select>
                        &nbsp;&nbsp;
                        <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                        &nbsp;&nbsp;
                        <select
                           style={{ width: '20%' }}
                           className="form-control"
                           onChange={(e) => setActive(e.target.value)}
                        >
                           <option value="">TODOS OS STATUS</option>
                           <option value="active">APENAS ATIVOS</option>
                           <option value="inactive">APENAS INATIVOS</option>
                        </select>

                        <button disabled={loaders.suppliers} onClick={() => getCategories()} style={{ marginLeft: 10, width: '120px' }} id="search-charge-back" type="button" className="btn btn-info">
                           {
                              loaders.suppliers
                                 ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                 : <i className="fas fa-search fa-rotate-90"></i>
                           }
                        </button>
                     </div>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     {
                        loaders.users ?
                           <div className="loader">
                              <h5>
                                 <Loader />
                                 <br />
                                 Carregando Categorias...
                              </h5>
                           </div>
                           : filteredData.length > 0 ?
                              <section className="form-create-users" style={{ display: 'flex' }}>
                                 <label className="text-label-title"><i className="fas fa-users"></i>&nbsp;Gerenciar Usuários</label>
                                 <div className="row-forms" style={{ flexDirection: 'column' }}>
                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                       <thead>
                                          <tr role='row'>
                                             <th className="sorting_asc">ID</th>
                                             <th style={{ textAlign: 'start' }} className="sorting_asc">Nome</th>
                                             <th style={{ textAlign: 'start' }} className="sorting_asc">Tipo</th>
                                             <th style={{ textAlign: 'start' }} className="sorting_asc">Categoria Pai</th>
                                             <th className="sorting_asc">Ativo</th>
                                             <th className="sorting_asc">Editar</th>
                                             <th className="sorting_asc">Desativar</th>
                                          </tr>
                                       </thead>

                                       <tbody>
                                          {
                                             currentPosts.map((row, idx) =>
                                                <tr role="row" key={idx}>
                                                   <td className="sorting_asc">{row.id}</td>
                                                   <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name ? row.name : 'NULL'}</td>
                                                   <td style={{ textAlign: 'start' }}>{row.id_category ? "SubCategoria" : "Categoria"}</td>
                                                   <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                      {row.father ? row.father.name : '---'}
                                                   </td>
                                                   <td className="sorting_asc">
                                                      {row.deleted_at ? "NÃO" : "SIM"}
                                                   </td>
                                                   <td className="sorting_asc">
                                                      <div className="items">
                                                         <i
                                                            onClick={() => props.history.push('/edit-categories', { data: row })}
                                                            style={{ cursor: 'pointer' }}
                                                            className="la la la-edit blue la-2x">
                                                         </i>
                                                      </div>
                                                   </td>
                                                   {
                                                      row.deleted_at ?
                                                         <td className="sorting_asc">
                                                            <div className="items">
                                                               <i
                                                                  onClick={() => { setDataDelete(row); setShowModal(true) }}
                                                                  style={{ cursor: 'pointer' }}
                                                                  className="la la la-check green la-2x"
                                                               >
                                                               </i>
                                                            </div>
                                                         </td>
                                                         :
                                                         <td className="sorting_asc">
                                                            <div className="items">
                                                               <i onClick={() => { setDataDelete(row); setShowModal(true) }}
                                                                  style={{ cursor: 'pointer' }}
                                                                  className="la la la-times red la-2x"
                                                               />
                                                            </div>
                                                         </td>
                                                   }
                                                </tr>
                                             )
                                          }
                                       </tbody>
                                    </table>

                                    <Pagination
                                       postsPerPage={postsPerPage}
                                       totalPosts={filteredData.length}
                                       paginate={paginate}
                                    />
                                 </div>
                              </section>
                              :
                              <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                 <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                 <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                              </section>
                     }

                     <Rodal
                        visible={showModal}
                        animation="flip"
                        customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                        showCloseButton={true}
                        onClose={() => setShowModal(false)}
                     >
                        <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                           <Close fontSize="large" style={{ cursor: 'pointer' }} />
                        </div>

                        <div className="modal-error">
                           {
                              dataDelete.deleted_at ?
                                 <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" /> :
                                 <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                           }
                           <p>Tem certeza que deseja alterar o estado desta categoria?</p>
                           <br />

                           <button
                              disabled={loaders.delete}
                              onClick={() => updateStatusCategory()}
                              style={{ width: '25%' }}
                              type="button"
                              className="btn btn-info"
                           >
                              {
                                 loaders.delete
                                    ?
                                    <>
                                       <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                       &nbsp;Processando...
                                    </>
                                    :
                                    dataDelete.deleted_at ? 'Sim. Reative.' : 'Sim. Desative.'
                              }
                           </button>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                           <div className="modal-error-buttons" />
                        </div>
                     </Rodal>

                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

