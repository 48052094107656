import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import { Dropdown } from "react-bootstrap"
import Moment from 'moment'
import utils from '../../../../utils/utils.js'
import { Link } from 'react-router-dom'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'

export default function App(props) {


  const [toggleMenu, setToggleMenu] = useState(false)
  const [loaders, setLoaders] = useState({ dataCredits: false })
  const [dataCredits, setDataCredits] = useState([])
  const [isError, setIsError] = useState(false)
  const [text, setText] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState('pending')
  Moment.locale('pt')
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )

  const getCredits = async () => {
    setLoaders({ ...loaders, dataCredits: true })
    setIsError(false)

    try {
      const res = await api.get(`/wallet-entry?status=${status}`)
      console.log(`res`, res)
      setDataCredits(res.data)
    } catch (error) {
      setIsError(true)
      setText(error.response.data.message)
      console.log("error =>", error)
    }
    setLoaders({ ...loaders, dataCredits: false })
  }

  const toggleStatus = async (id, status) => {
    try {
      let change = [{
        id_credit: id,
        status: status
      }]
      const res = await api.post('/wallet-entry', { change })
      setText(res.data.message)
      setShowModal(true)
    } catch (error) {
      console.log("error =>", error)
    }
    getCredits()
  }

  useEffect(() => { getCredits() }, [])

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>

            <div className="menu-center">
              <select
                defaultValue={'pending'}
                className='form-control form-control-lg'
                id='inlineFormCustomSelect'
                style={{ width: '200px', height: '35px', marginRight: 10 }}
                onClick={(e) => setStatus(e.target.value)}
              >
                <option value="">TODOS</option>
                <option value="pending" >PENDENTES</option>
                <option value="approved">APROVADOS</option>
                <option value='rejected'>REPROVADOS</option>
              </select>

              <button disabled={loaders.dataCredits}
                onClick={() => getCredits()}
                style={{ marginLeft: 10, width: '120px' }}
                id="search-charge-back"
                type="button"
                className="btn btn-info"
              >
                {
                  loaders.dataCredits
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>
            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              {
                loaders.dataCredits ?
                  <div className="loader">
                    <h5>
                      <Loader />
                      <br />
                      Carregando ...
                    </h5>
                  </div>
                  :
                  isError
                    ?
                    <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center' }}>
                      <div><i className="fas fa-times error fa-2x"></i></div>
                      <div> <h3>{text}</h3></div>
                    </section>
                    :
                    dataCredits.length > 0
                      ?
                      <section className="form-create-users" style={{ display: 'flex' }}>
                        <label className="text-label-title"><i className="fas fa-coins"></i> &nbsp; Aprovação de Créditos</label>
                        <div className="separator" />
                        <div className="row-forms" style={{ flexDirection: 'column' }}>
                          <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                            <thead>
                              <tr role='row'>
                                <th className="sorting_asc">ID</th>
                                <th className="sorting_asc">Empresa</th>
                                <th className="sorting_asc">DT Cadastro</th>
                                <th className="sorting_asc">Usuário Crédito</th>
                                <th className="sorting_asc">Identificador</th>
                                <th className="sorting_asc">Valor</th>
                                <th className="sorting_asc">Status</th>
                                <th className="sorting_asc">Ações</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                dataCredits.map((row, idx) => (

                                  <tr role='row' key={idx}>
                                    <td className="sorting_asc">{row.id}</td>
                                    <td className="sorting_asc">{row.company.name}</td>
                                    <td className="sorting_asc">
                                      {Moment(row.created_at).format('DD/MM/YYYY')}
                                    </td>
                                    <td className="sorting_asc">{row.user.name}</td>
                                    <td className="sorting_asc">{row.description}</td>
                                    <td className="sorting_asc bold green">R$ {utils.formatMoneyBRL(row.value)}</td>
                                    <td className="sorting_asc">
                                      {
                                        row.approved == 0 ?
                                          <div style={{ color: 'red' }}>Negado</div>
                                          : row.approved == 1 ?
                                            <div style={{ color: 'green' }}>Aprovado</div>
                                            : row.approved == 2 ?
                                              <div style={{ color: 'orange' }}>Pendente</div>
                                              : <div>Não Identificado</div>
                                      }
                                    </td>
                                    <td className="sorting_asc">
                                      <Dropdown >
                                        <Dropdown.Toggle
                                          variant="success"
                                          className="light sharp i-false"
                                          style={{
                                            width: '100px',
                                            height: '20px'
                                          }}
                                        >
                                          {svg1}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            {
                                              (row.approved == 0 || row.approved == 1) ?
                                                <Link style={{ cursor: 'not-allowed' }}>
                                                  <i className="la la-check-circle green la-lg" /> Liberar
                                                </Link>
                                                :
                                                <Link onClick={() => { toggleStatus(row.id, 1) }}>
                                                  <i className="la la-check-circle green la-lg" /> Liberar
                                                </Link>
                                            }
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            {
                                              (row.approved == 0 || row.approved == 1) ?
                                                <Link style={{ cursor: 'not-allowed' }}>
                                                  <i className="la la-close red la-lg" /> Bloquear
                                                </Link>
                                                :
                                                <Link onClick={() => { toggleStatus(row.id, 0) }}>
                                                  <i className="la la-close red la-lg" /> Bloquear
                                                </Link>
                                            }
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>

                                ))
                              }
                            </tbody>

                          </table>
                        </div>
                      </section>
                      :
                      <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                        <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                      </section>
              }
              <Rodal
                visible={showModal}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}

              >
                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="modal-error">
                  {
                    isError
                      ?
                      <>
                        <div><i className="fas fa-times error fa-2x"></i></div>
                        <div> {text}</div>
                      </>
                      :
                      <>
                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                        <div><i className="fas fa-coins green white"></i> {text}</div>
                      </>
                  }

                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                  <div className="modal-error-buttons" />
                </div>
              </Rodal>
            </div>
          </div>
        </div>
      </div>
    </div >

  )
}

