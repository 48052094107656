import React, { useState } from 'react';
import ImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import product_default from '../../../images/product-default.jpeg';

const ImageGallery = ({ images }) => {
  // debugger
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setIsOpen(true);
    setCurrentImageIndex(index);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMovePrev = () => {
    setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length);
  };

  const handleMoveNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  return (
    <div>
      <img src={images && images[0] ? images[0].image : product_default} alt="" className="rounded-circle" width="30" onClick={() =>images && images[0] ? setIsOpen(true) : setIsOpen(false)} />
      
      {/* Renderize o ImageLightbox */}
      {isOpen && (
        <ImageLightbox
          mainSrc={images[currentImageIndex].image}
          nextSrc={images[(currentImageIndex + 1) % images.length]}
          prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
};

export default ImageGallery;
