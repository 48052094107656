import React, { useState, useEffect } from 'react'
import { Timeline, TimelineEvent } from 'lv-react-horizontal-timeline'
import { FaCheck, FaRegCalendarCheck, FaRegFileAlt, FaDolly, FaTimes } from 'react-icons/fa'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'
import moment from 'moment'
import Countdown from 'react-countdown'
import ReactStars from 'react-rating-stars-component'
import ModalLoader from '../Loader/modalLoader'
import "./sales.css"

export default function App(props) {
  Moment.locale('pt')

  const [toggleMenu, setToggleMenu] = useState(false)
  const [orderStatus, setOrderStatus] = useState('')
  const [ordersList, setOrdersList] = useState([])
  const detailsOrderFields = {
    id: '',
    status: '',
    totalItems: 0,
    nroItems: 0,
    items: [],
    created_at: '',
    timer: 0,
    descriptionRefuse: null
  }
  const [detailsOrder, setDetailsOrder] = useState(detailsOrderFields)
  const loaderFields = {
    sales: false,
    details: false,
    updateSale: false,
    reverseSale: false
  }
  const [loaders, setLoaders] = useState(loaderFields)
  const modalFields = {
    details: false,
    reject: false,
    response: false,
    deliveryInfo: false
  }
  const [showModal, setShowModal] = useState(modalFields)
  const [text, setText] = useState('')
  const [reasonForRefusal, setReasonForRefusal] = useState('')
  const [isEnableButton, setIsEnableButton] = useState(true)
  const [showImageModal, setShowImageModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [purchaseDisabled, setPurchaseDisabled] = useState(false)
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const deliveryFields = {
    email: '',
    option: 'retirada',
    forecast: '',
    date_receipt: '',
    status: ''
  }
  const [deliveryInfos, setDeliveryInfos] = useState(deliveryFields);
  const [isDisabled, setIsDisabled] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentData = ordersList.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const [styleButton, setStyleButton] = useState('not-allowed')
  const [showRating, setShowRating] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const fieldsRatings = {
    experience: 0,
    quality: 0,
    speedResponse: 0,
  }
  const [ratings, setRatings] = useState(fieldsRatings)
  const fieldsEvaluationReview = {
    experience: '',
    quality: '',
    speedResponse: '',
  }
  const [reviews, setReviews] = useState(fieldsEvaluationReview)
  const [loaderMessage, setLoaderMessage] = useState('')
  const [showTimeline, setShowTimeline] = useState(false)
  const fieldsTimeLine = {
    created_at: '',
    status: '',
    deliver_option: '',
    receipt_date: '',
    delivery_forecast_date: ''
  }
  const [timelineInfo, setTimelineInfo] = useState(fieldsTimeLine)

  const tooltips = [
    "O pedido foi recusado, e encontra-se finalizado.",
    "O pedido foi concluído pelo fornecedor, e aguarda a retirada/entrega do Parceiro Comercial.",
    "O pedido está aguardando uma ação do Parceiro Fornecedor!",
    "O pedido está aguardando uma ação do Parceiro Comercial!",
    "O pedido está pronto para ser entregue.",
    "O pedido foi entregue, e encontra-se finalizado.",
    "", "", "", "O pedido foi estornado, e encontra-se finalizado."
  ]

  const getSales = async () => {
    setLoaders({ ...loaders, sales: true })

    try {
      let res

      if (orderStatus === "") {
        res = await api.get('/purchase-order')
      } else {
        res = await api.get(`/purchase-order?filter=${orderStatus}`)
      }

      if (res.data) {
        console.log('orders', res.data)
        setOrdersList(res.data)
      }
    } catch (error) {
      console.log("error =>", error.response.data.message)
    }
    setLoaders({ ...loaders, sales: false })
  }

  const getDetails = async (orderRow) => {
    setLoaders({ ...loaders, details: true })
    setDetailsOrder(detailsOrderFields)
    console.log(orderRow.id, orderRow.status)
    try {
      const res = await api.get('/purchase-order/' + orderRow.id)

      if (res.data.item_orders) {
        let total_order = 0
        let sum_items = 0
        let timer = moment().diff(moment(res.data.created_at), 'milliseconds');
        let countDown = timer > 900000 ? 0 : (900000 - timer);

        res.data.item_orders.forEach(item => {
          total_order += (parseFloat(item.current_price) * item.quantity)
          sum_items += item.quantity
        })

        setTimelineInfo({
          created_at: res.data.created_at,
          delivery_option: res.data.delivery_option,
          status: res.data.status,
          delivery_forecast_date: res.data.delivery_forecast_date,
          receipt_date: res.data.receipt_date
        });

        setDetailsOrder({
          id: orderRow.id,
          status: orderRow.status,
          totalItems: total_order,
          nroItems: sum_items,
          items: res.data.item_orders,
          timer: countDown,
          descriptionRefuse: "Pedido rejeitado. Motivo: " + orderRow.reason_for_refusal
        })
        setShowModal({ ...showModal, details: true });
      }
    } catch (error) {
      console.log("error =>", error.response.data.message)
    }

    setLoaders({ ...loaders, details: false })
  }

  const updateSale = async (idSale, status) => {
    setLoaderMessage('Atualizando transação. Por favor aguarde.')
    setLoaders({ ...loaders, updateSale: true })
    setIsError(false)

    try {
      const res = await api.put(`/purchase-order/${idSale}`, {
        "status": status,
        "reason_for_refusal": reasonForRefusal,
        "reviews": reviews,
        "ratings": ratings,
        "delivery_infos": deliveryInfos
      })

      if (res.data) {
        console.log('res.data', res.data)
        setText('Venda atualizada com sucesso.')
      }

    } catch (err) {
      setText('Não foi possível atualizar a venda. Erro ' + err.response.data.message)
      console.log(err)
      setIsError(true)
    }
    setLoaders({ ...loaders, updateSale: false })
    setLoaderMessage('')
    setShowModal({
      ...showModal,
      reject: false,
      response: true,
      deliveryInfo: false
    })
    setReasonForRefusal('')
    getSales()
    setShowRating(false)
    setReviews(fieldsEvaluationReview)
    setDeliveryInfos({
      email: '',
      option: 'retirada',
      forecast: '',
      date_receipt: '',
      status: ''
    })
  }

  function handleCancel() {
    setShowModal({ ...showModal, details: false });
    getSales();
    setShowRating(false)
    setReviews(fieldsEvaluationReview)
  }

  const reversePurchase = async (idSale) => {
    setLoaders({ ...loaders, reverseSale: true })

    try {
      const res = await api.post(`/purchase-order-reverse/${idSale}`)

      if (res.data) {
        console.log('res.data', res.data)
        setText('Venda estornada com sucesso.')
      }

    } catch (err) {
      setText('Não foi possível estornar a venda. \n Erro ' + err.response.data.message)
      console.log(err)
      setIsError(true)
    }
    setLoaders({ ...loaders, reverseSale: false })
    setShowModal({
      ...showModal,
      reject: false,
      response: true
    })
    getSales()
  }

  function closeModalRefused() {
    setShowModal({ ...showModal, reject: false, deliveryInfo: false })
    setReasonForRefusal('')
    setShowRating(false)
    setReviews(fieldsEvaluationReview)
    setReasonForRefusal('')
  }

  // precisei criar 3 métodos, pois o reactstarts é engessado nesse aspecto
  const ratingChangedExperience = (newRating) => {
    setRatings({ ...ratings, experience: newRating })
  }
  const ratingChangedQuality = (newRating) => {
    setRatings({ ...ratings, quality: newRating })
  }
  const ratingChangedSpeedResponse = (newRating) => {
    setRatings({ ...ratings, speedResponse: newRating })
  }

  const getModalDelivery = async (openModalDelivey) => {
    if (openModalDelivey) {
      console.log('detailsOrder', detailsOrder)
      setShowModal({ ...showModal, deliveryInfo: true, details: false })
    } else {
      setShowModal({ ...showModal, deliveryInfo: false, details: true })
      setDeliveryInfos(deliveryFields)
    }
  }

  function handleRating() {
    setShowModal(modalFields)
    setShowRating(true)
  }

  useEffect(() => { getSales() }, [])

  useEffect(() => {
    if (!showModal.response) {
      setShowModal({ ...showModal, details: false })
    }
  }, [showModal.response])

  useEffect(() => {
    if (reasonForRefusal.length >= 5) {
      setIsEnableButton(false)
      setStyleButton('pointer')
    } else {
      setIsEnableButton(true)
      setStyleButton('not-allowed')
    }
  }, [reasonForRefusal])

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      detailsOrder.timer = 0;
      setPurchaseDisabled(true)
      return <span></span>;
    } else {
      setPurchaseDisabled(false)
      return <span style={{ marginLeft: '10px' }}>{minutes}:{seconds}</span>;
    }
  };

  // confirma se o tamanho do input é >= 5 ⤵
  useEffect(() => {
    if ((reviews.experience.length >= 5 && ratings.experience > 0) &&
      (reviews.quality.length >= 5 && ratings.quality > 0) &&
      (reviews.speedResponse.length >= 5 && ratings.speedResponse > 0)) {
      setIsEnabled(true)
      setStyleButton('pointer')
    } else {
      setIsEnabled(false)
      setStyleButton('not-allowed')
    }

  }, [reviews])

  useEffect(() => {
    console.log(deliveryInfos.email, deliveryInfos.option, deliveryInfos.forecast)
    setIsDisabled(true)

    if (deliveryInfos.email !== '' && deliveryInfos.option !== '' && deliveryInfos.forecast !== '') {
      setIsDisabled(false)
    }
  }, [deliveryInfos])

  function handleTimeline() {
    setShowTimeline(true)
  }

  function handleModalDelivery() {
    getModalDelivery(false);
    setDeliveryInfos({
      email: '',
      option: 'retirada',
      forecast: '',
      date_receipt: '',
      status: ''
    })
  }

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">
              <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>
                <div className="input-group-prepend">
                  <div className="input-group-text" style={{ height: '45px', marginLeft: 10 }}>
                    <FilterList fontSize="default" className="blue" />
                  </div>
                </div>
                &nbsp;&nbsp;
                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                &nbsp;&nbsp;
                {/* status compra */}
                <select
                  style={{ width: '15%' }}
                  className="form-control"
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <option value="">-- STATUS -- </option>
                  <option value="AR">Aguardando Retirada</option>
                  <option value="AV">Aguardando Vendedor</option>
                  <option value="PE">Produto Entregue</option>
                  <option value="VC">Venda Cancelada</option>
                </select>

                <button disabled={loaders.suppliers} onClick={() => getSales()} style={{ marginLeft: 10, width: '120px' }} id="search-charge-back" type="button" className="btn btn-info">
                  {
                    loaders.suppliers
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <i className="fas fa-search fa-rotate-90"></i>
                  }
                </button>
              </div>
            </div>
          </nav>

          <div className="data">
            <div className="panel" style={{ padding: '1%' }}>

              <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >

                <div className="row-forms">
                  {
                    loaders.sales
                      ?
                      <div>
                        Buscando Vendas...
                        <ThreeDots
                          color="#0ccbec"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                      </div>
                      :
                      <>
                        <div className="row-forms" style={{ flexDirection: 'row' }}>
                          <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Gerenciar Vendas</label>
                        </div>

                        <table className='table display mb-4 dataTablesCard dataTable no-footer'>
                          <thead>
                            <tr role='row'>
                              <th className="sorting_asc"> ID </th>
                              <th className="sorting_asc">Comprador </th>
                              <th className="sorting_asc">Data/Hora</th>
                              <th className="sorting_asc">Valor</th>
                              <th className="sorting_asc">Status</th>
                              <th className="sorting_asc">Ações</th>

                            </tr>
                          </thead>

                          <tbody>
                            {
                              currentData.map((row, idx) =>
                                <tr role="row" key={idx}>
                                  <td className="sorting_asc">{row.id}</td>
                                  <td className="sorting_asc">{row.company_buyer.name}</td>
                                  <td className="sorting_asc">{Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                  <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.total_trix)}</td>
                                  <td className="sorting_asc">
                                    {
                                      row.status === 0 ?
                                        <div style={{ color: 'red' }}>
                                          Negado <i className="la la-times-circle red la-lg" title={tooltips[row.status]} />
                                        </div>
                                        : row.status === 1 ?
                                          <div style={{ color: 'green' }}>
                                            Aguardando Retirada <i className="la la-check-circle green la-lg" title={tooltips[row.status]} />
                                          </div>
                                          : row.status === 2 ?
                                            <div style={{ color: 'orange' }}>
                                              Aguardando Vendedor <i className="la la-info-circle la-lg" title={tooltips[row.status]} />
                                            </div>
                                            : row.status === 5 ?
                                              <div style={{ color: 'gray' }}>
                                                Produto Recebido <i className="la la-legal blue la-lg" title={tooltips[row.status]} />
                                              </div>
                                              : row.status === 9 ?
                                                <div style={{ color: 'blue' }}>
                                                  Estornado <i className="la la-times-circle blue la-lg" title={tooltips[row.status]} />
                                                </div>
                                                :
                                                <div>Não Identificado</div>
                                    }
                                  </td>
                                  <td className="sorting_asc" style={{ width: '300px' }} >
                                    <Dropdown >
                                      <Dropdown.Toggle
                                        variant="success"
                                        className="light sharp i-false"
                                        style={{
                                          width: '150px',
                                          height: '20px'
                                        }}
                                      >
                                        {svg1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => getDetails(row)}>
                                          <i className="la la-edit gray la-lg" /> Detalhes
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              )
                            }

                          </tbody>

                        </table>
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={ordersList.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      </>
                  }
                </div>
              </section>

              {/* detalhes order */}
              <Rodal
                visible={showModal.details}
                animation="slideLeft"
                customStyles={{ width: '70%', height: 'auto', marginTop: '1%', bottom: 'auto', top: '50%', transform: 'translateY(-50%)' }}
                showCloseButton={true}
                onClose={() => handleCancel()}>
                <div className="close-rodal" onClick={() => handleCancel(false)}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">Venda</div>
                <div className="detal-modal">
                  {/* cabeçalho */}
                  <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                    <Row style={{ marginBottom: '16px' }}>
                      {/* nro itens */}
                      <Col>
                        <label className="text-label">Nro. Itens</label>
                        <input
                          type="text"
                          autoComplete="off"
                          value={detailsOrder.nroItems}
                          disabled
                          className="form-control"
                          aria-describedby="inputGroupPrepend2"
                        />
                      </Col>
                      {/* total itens */}
                      <Col>
                        <label className="text-label">Total Itens</label>
                        <input
                          type="text"
                          autoComplete="off"
                          value={"R$" + utils.formatMoneyBRL(detailsOrder.totalItems)}
                          disabled
                          className="form-control"
                          aria-describedby="inputGroupPrepend2"
                        />
                      </Col>
                    </Row>
                    {/* grid itens */}
                    <Row>
                      <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                        <thead>
                          <tr role='row'>
                            <th className="sorting_asc">Item</th>
                            <th className="sorting_asc">Categoria</th>
                            <th className="sorting_asc">Subcategoria</th>
                            <th className="sorting_asc">Descrição</th>
                            <th className="sorting_asc">Valor</th>
                            {/* <th className="sorting_asc"><i style={{ fontSize: '20px' }} class="far fa-image"></i></th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {
                            loaders.details ?
                              <div>
                                Buscando Itens...
                                <ThreeDots
                                  color="#0ccbec"
                                  height={40}
                                  width={40}
                                  type="ThreeDots"
                                />
                              </div>
                              : detailsOrder.items.length > 0 ?
                                detailsOrder.items.map((row, idx) =>
                                  <tr role="row" key={idx}>
                                    <td className="sorting_asc">
                                      {row.item.id}
                                    </td>
                                    <td className="sorting_asc">{row.item.category ? row.item.category.name : ''}</td>
                                    <td className="sorting_asc">{row.item.sub_category ? row.item.sub_category.name : ''}</td>
                                    <td className="sorting_asc">{row.item.description}</td>
                                    <td className="sorting_asc">
                                      R$ {utils.formatMoneyBRL(row.current_price)}
                                    </td>
                                    {/* <td>
                                                                            <i className="fas fa-eye"
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => setShowImageModal(true)}
                                                                            >
                                                                            </i>
                                                                        </td> */}
                                  </tr>
                                )
                                : <></>
                          }
                        </tbody>

                      </table>
                    </Row>

                    {
                      detailsOrder.status == 0 &&
                      <Row>
                        <label className="text-label">Detalhes do Pedido</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Motivo da recusa:"
                          disabled
                          value={detailsOrder.descriptionRefuse}
                        />
                      </Row>
                    }
                  </Container>

                  {/* botoes */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                      detailsOrder.status == 2 ?
                        <>
                          <button
                            //onClick={() => updateSale(detailsOrder.id, '1')}
                            onClick={() => getModalDelivery(true)}
                            style={{ marginLeft: 10, width: '150px', backgroundColor: '#5e72e4' }}
                            type="button"
                            className="btn btn-success"
                          >
                            {/* Detalhes da Entrega */}
                            Realizar a Venda
                          </button>
                          <button
                            onClick={() => setShowModal({ ...showModal, reject: true })}
                            // setIsReasonRefuse(true)
                            style={{ marginLeft: 10, width: '150px', backgroundColor: '#ff9900' }}
                            type="button"
                            className="btn btn-warning"
                          >
                            Recusar Venda
                          </button>
                        </> :
                        detailsOrder.status == 5 ?
                          <>
                            <button
                              onClick={() => handleRating()}
                              style={{ marginLeft: 10, width: '150px', backgroundColor: '#ff9900' }}
                              type="button"
                              className="btn btn-warning"
                            >
                              Avaliar Venda
                            </button>
                            <button
                              onClick={() => reversePurchase(detailsOrder.id)}
                              style={{ marginLeft: 10, width: '200px' }}
                              type="button"
                              className="btn btn-success"
                              disabled={purchaseDisabled}
                            >
                              Estornar
                              {
                                detailsOrder.timer > 0 ?
                                  <>
                                    <Countdown date={Date.now() + detailsOrder.timer} renderer={renderer} />
                                  </> : <></>
                              }
                            </button>
                          </> : <></>
                    }

                    <button
                      onClick={handleTimeline}
                      style={{ marginLeft: 10, width: '140px' }}
                      type="button"
                      className="btn btn-info"
                    >
                      <b>Linha do tempo</b>
                    </button>

                    <button
                      onClick={handleCancel}
                      style={{ marginLeft: 10, width: '120px' }}
                      type="button"
                      className="btn btn-danger"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </Rodal>

              {/* Rodal descrição - Rejeitar venda */}
              <Rodal
                visible={showModal.reject}
                animation="flip"
                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                showCloseButton={true}
                onClose={() => closeModalRefused()}
              >
                <div className="close-rodal" onClick={() => closeModalRefused()} style={{ top: 0 }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                  {/* titulo */}
                  <div className="content-modal">
                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                      <h2 style={{ fontSize: "30px", }}>Rejeitar venda</h2>
                    </div>

                    <label style={{ fontWeight: 'bold', textAlign: 'start' }}>Digite o motivo: </label>
                    <textarea
                      value={reasonForRefusal}
                      required
                      onChange={e => setReasonForRefusal(e.target.value)}
                      type="text"
                      placeholder="Mínimo 5 caracteres"
                      minlength="5"
                      style={{ height: '80px', maxHeight: '80px' }}

                    />

                  </div>

                  <div className="buttonsModal">
                    <button
                      className="buttonModal"
                      style={{ cursor: styleButton }}
                      disabled={isEnableButton}
                      onClick={() => updateSale(detailsOrder.id, '0')}
                    >
                      ENVIAR
                    </button>
                    <button className="buttonModal" onClick={() => closeModalRefused()}>VOLTAR</button>
                  </div>
                </div>

              </Rodal>

              {/* Rodal informações de entrega */}
              <Rodal
                visible={showModal.deliveryInfo}
                animation="slideLeft"
                customStyles={{ width: '70%', height: 'auto', marginTop: '1%', bottom: 'auto', top: '50%', transform: 'translateY(-50%)' }}
                showCloseButton={true}
                onClose={() => handleModalDelivery()}
              >
                <div className="close-rodal" onClick={() => handleModalDelivery()}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="title">Informações de Entrega</div>
                <div className="detal-modal">
                  {/* cabeçalho */}
                  <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                    <Row style={{ marginBottom: '16px' }}>
                      {/* area de e-mail */}
                      <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                        <label className="text-label">E-mail*</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Digite a informação a ser enviada por e-mail ao cliente..."
                          style={{ height: 150 }}
                          value={deliveryInfos.email}
                          disabled={!detailsOrder.status == 2}
                          onChange={(e) => setDeliveryInfos({ ...deliveryInfos, email: e.target.value })}
                        />
                      </div>

                      <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 105 }}>
                          {/* forma de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                            <label className="text-label">Forma de Entrega*</label>
                            <select
                              value={deliveryInfos.option}
                              className='form-control form-control-lg'
                              disabled={!detailsOrder.status == 2}
                              onChange={(e) => setDeliveryInfos({ ...deliveryInfos, option: e.target.value })}
                            >
                              <option value="retirada">Entrega Balcão</option>
                              <option value="correios">Envio Correios</option>
                              <option value="terrestre">Despacho Terrestre</option>
                              <option value="aereo">Despacho Aéreo</option>
                            </select>
                          </div>
                          {/* previsão de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                            <label className="text-label">Previsão de Entrega*</label>
                            {/* data ini */}
                            <input style={{ width: '90%', height: 45 }}
                              value={deliveryInfos.forecast}
                              disabled={!detailsOrder.status == 2}
                              onChange={(e) => setDeliveryInfos({ ...deliveryInfos, forecast: e.target.value })}
                              type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
                            &nbsp;&nbsp;
                          </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {/* status */}
                          <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                            <label className="text-label">Status da Entrega</label>
                            <select
                              value={deliveryInfos.status}
                              disabled
                              className='form-control form-control-lg'
                            // onChange={(e) => setDeliveryInfos({ ...deliveryInfos, status: e.target.value })}
                            >
                              <option value="andamento">Em Andamento</option>
                              <option value="atraso">Atrasado</option>
                              <option value="finalizado">Finalizado</option>
                            </select>
                          </div>
                          {/* data de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                            <label className="text-label">Data de Entrega</label>
                            {/* data ini */}
                            <input style={{ width: '90%', height: 45 }}
                              value={deliveryInfos.date_receipt}
                              disabled
                              // onChange={(e) => setDeliveryInfos({ ...deliveryInfos, date_receipt: e.target.value })}
                              type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
                            &nbsp;&nbsp;
                          </div>
                        </div>
                      </div>
                    </Row>

                  </Container>
                  {/* botoes */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                      detailsOrder.status == 2 ?
                        <>
                          <button
                            onClick={() => updateSale(detailsOrder.id, '1')}
                            style={{ marginLeft: 10, width: '200px', height: '50px', fontSize: '0.9rem', backgroundColor: '#5e72e4' }}
                            disabled={isDisabled}
                            type="button"
                            className="btn btn-success"
                          >
                            Envia e-mail e Realiza a Venda
                          </button>
                          <button
                            onClick={() => getModalDelivery(false)}
                            // setIsReasonRefuse(true)
                            style={{ marginLeft: 10, width: '200px', height: '50px', fontSize: '0.9rem', backgroundColor: '#ff9900' }}
                            type="button"
                            className="btn btn-warning"
                          >
                            Retornar para o menu anterior
                          </button>
                        </> : <></>
                    }
                  </div>
                </div>
              </Rodal>

              {/* modal mensagem retorno */}
              <Rodal
                visible={showModal.response}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal({ ...showModal, response: false })}

              >
                <div className="close-rodal" onClick={() => { setShowModal({ ...showModal, response: false }) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="modal-error">
                  {
                    isError
                      ?
                      <>
                        <div><i className="fas fa-times error fa-2x"></i></div>
                        <div> {text}</div>
                      </>
                      :
                      <>
                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                        <div><i className="fas fa-coins green white"></i> {text}</div>
                      </>
                  }

                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                  <div className="modal-error-buttons" />
                </div>
              </Rodal>

              {/* modal imagem produto */}
              <Rodal
                visible={showImageModal}
                animation="flip"
                customStyles={{
                  width: '600px',
                  height: '300px',
                  marginTop: '10%'
                }}
                showCloseButton={true}
              >
                <div className="close-rodal" onClick={() => setShowImageModal(false)}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <Container className="container-img">
                  <img src="" alt="imagem produto" />
                </Container>

              </Rodal>

              {/* Rodal Avaliação */}
              <Rodal
                visible={showRating}
                animation="slideLeft"
                customStyles={{
                  width: '50%',
                  height: '230px',
                  bottom: 'auto',
                  top: '40%',
                  transform: 'translateY(-50%)',

                }}
                showCloseButton={true}
                onClose={() => closeModalRefused}>

                {/* >Botão fechar Rodal */}
                <div className="close-rodal" onClick={() => handleCancel()}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                {/* Container modal avaliações */}
                <div className="ContainerFilho">
                  {/* avaliação plataforma */}
                  <Container className="card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <Row>
                        <Col className="descricao">
                          AVALIE A  PLATAFORMA
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <ReactStars
                          count={5}
                          onChange={ratingChangedExperience}
                          size={25}
                          activeColor="#ffd700"
                        />
                      </Col>
                    </Row>

                    <textarea
                      type="text"
                      className="form-control"
                      required
                      value={reviews.experience}
                      onChange={(e) => setReviews({
                        ...reviews, experience: e.target.value
                      })}
                      placeholder="Mínimo 5 caracteres"

                    />

                  </Container>
                  {/* qualidade de atendimento */}
                  <Container className="card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <Row>
                        <Col className="descricao">
                          QUALIDADE DE ATENDIMENTO
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <ReactStars
                          count={5}
                          onChange={ratingChangedQuality}
                          size={25}
                          activeColor="#ffd700"
                        />
                      </Col>
                    </Row>

                    <textarea
                      type="text"
                      className="form-control"
                      required
                      value={reviews.quality}
                      onChange={(e) => setReviews({
                        ...reviews, quality: e.target.value
                      })}
                      placeholder="Mínimo 5 caracteres"

                    />

                  </Container>
                  {/* velocidade de resposta */}
                  <Container className="card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <Row>
                        <Col className="descricao">
                          VELOCIDADE DE RESPOSTA
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <ReactStars
                          count={5}
                          onChange={ratingChangedSpeedResponse}
                          size={25}
                          activeColor="#ffd700"
                        />
                      </Col>
                    </Row>

                    <textarea
                      type="text"
                      className="form-control"
                      required
                      value={reviews.speedResponse}
                      onChange={(e) => setReviews({
                        ...reviews, speedResponse: e.target.value
                      })}
                      placeholder="Mínimo 5 caracteres"

                    />

                  </Container>
                </div>
                {/* botões */}
                <div className="buttons-rodal">
                  <button
                    onClick={() => updateSale(detailsOrder.id, '5')}
                    style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px', cursor: styleButton }}
                    type="button"
                    className="btn btn-success"
                    disabled={!isEnabled}
                  >
                    Avaliar
                  </button>
                  <button
                    onClick={handleCancel}
                    style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px' }}
                    type="button"
                    className="btn btn-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </Rodal>

              {/* Rodal loader */}
              <Rodal
                visible={loaders.updateSale}
                animation="slideLeft"
                customStyles={{
                  width: '30%',
                  height: '140px',
                  marginTop: '-5%',
                  bottom: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)',

                }}
                showCloseButton={true}
              >
                <ModalLoader data={loaderMessage} />
              </Rodal>

              {/* modal timeline */}
              <Rodal
                visible={showTimeline}
                animation="flip"
                customStyles={{ width: '70%', height: 'calc(50%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}

              >
                <div className="close-rodal" onClick={() => { setShowTimeline(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">LINHA DO TEMPO</div>

                <div style={{ marginTop: '10px' }}>
                  {
                    timelineInfo.status == 0
                      ?
                      <Timeline minEvents={2} placeholder>
                        <TimelineEvent
                          color='#2BC155'
                          icon={FaRegFileAlt}
                          title='Criado'
                          subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                        />
                        <TimelineEvent
                          color='#ff0000'
                          icon={FaTimes}
                          title='Negado'
                        // subtitle=""
                        />
                      </Timeline>
                      :
                      <Timeline minEvents={2} placeholder>
                        <TimelineEvent
                          color='#2BC155'
                          icon={FaRegFileAlt}
                          title='Criado'
                          subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                        />
                        <TimelineEvent
                          color={timelineInfo.delivery_option != null ? '#2BC155' : '#e0e0e0'}
                          icon={FaDolly}
                          title='Forma de entrega'
                          subtitle={timelineInfo.delivery_option}
                        />
                        {/* Passo seleciona  de entrega ao realizar a venda */}
                        <TimelineEvent
                          color={((timelineInfo.status == '1' || timelineInfo.status == '5') || Moment(timelineInfo.delivery_forecast_date).isSameOrBefore(Moment())) ? '#2BC155' : '#e0e0e0'}
                          icon={FaRegCalendarCheck}
                          title='Aguardando retirada'
                          subtitle={timelineInfo.delivery_forecast_date != null ? Moment(timelineInfo.delivery_forecast_date).format('DD/MM/YYYY') : ""}
                        />
                        <TimelineEvent
                          color={(timelineInfo.status == '4' || timelineInfo.status == '5') ? '#2BC155' : '#e0e0e0'}
                          icon={FaCheck}
                          title='Retirado'
                          subtitle={timelineInfo.receipt_date != null ? Moment(timelineInfo.receipt_date).format('DD/MM/YYYY') : ""}
                        // action={{
                        // label: 'Ver detalhes...',
                        // onClick: () => window.alert('Erro!')
                        // }}
                        />
                      </Timeline>
                  }
                </div>
              </Rodal>

            </div >
          </div >

        </div >
      </div >
    </div >
  )
}
