import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'
import Moment from 'moment'
import utilsSelect from '../../../../utils/selects'
import FileBase64 from 'react-file-base64';

export default function App(props) {

    const title = 'Publicidade';
    const titleName = `Editar da ${title}`;
    const [toggleMenu, setToggleMenu] = useState(false)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const [data] = useState(props.location.state.data)
    const [filteredData, setFilteredData] = useState({})
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isSaveSuccess, setSaveSuccess] = useState(false)
    const [gruops, setGruops] = useState([])
    const [list_select_yes_or_no, setListYesOrNo] = useState([])

    console.log(data)

    const edit = async () => {
        setLoader(true)
        setIsError(false)

        try {
            const res = await api.put(`/advertising/advertisings/${filteredData.id}`, {
                name: filteredData.name,
                description: filteredData.description,
                link: filteredData.link,
                show_until: Moment(filteredData.show_until).format('YYYY-MM-DD hh:mm:ss'),
                active: filteredData.active,
                image: filteredData.image,
                id_advertising_group: filteredData.id_advertising_group,
                advertising_group: filteredData.advertising_group
            })

            setText(res.data.message ?? res.statusText);
            setShowModal(true);
            setSaveSuccess(true);

        } catch (error) {
            setIsError(true);
            let msgError = '';
            let idx = 1;
            for (var member in error.response.data.errors) {
                msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
                idx++;
            }
            setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
            setShowModal(true);
            setSaveSuccess(false);
        }
        setLoader(false)
    }

    const getGruops = async () => {
        try {
            const res = await api.get('/advertising/groups')
            if (res.data.data) {
                setGruops(res.data.data);
            }
        }
        catch (error) {
            console.log("error =>", error);
        }
    }

    useEffect(() => {
        console.log(data) 
        setFilteredData({
            id: data.id,
            name: data.name,
            description: data.description,
            link: data.link,
            show_until: data.show_until,
            active: data.active,
            id_advertising_group: data.id_advertising_group,
            advertising_group: data.advertising_group,
        })
    }, [data]);

    useEffect(() => { getGruops() }, [])

    useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])

    useEffect(() => {
        if (!showModal && isSaveSuccess) {
            props.history.push('/list-advertising-customer')
        }
    }, [isSaveSuccess, props.history, showModal])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">
                            <button
                                onClick={() => { props.history.push('/list-advertising-customer') }}
                                style={{ width: '150px', marginLeft: 10 }}
                                type="button" className="btn btn-info"
                            >
                                Voltar
                            </button>
                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>
                                <label className="text-label-title"><i className="la la-layer-group"></i>&nbsp;{titleName}</label>
                                <div className="separator" />
                                {/* linha */}
                                <div className="row-forms">
                                    {/* nome */}
                                    <div className="form-group">
                                        <label className="text-label">Nome*</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            placeholder="Nome..."
                                            value={filteredData.name}
                                            onChange={(e) => setFilteredData({ ...filteredData, name: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* Link */}
                                    <div className="form-group">
                                        <label className="text-label">Link*</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            placeholder="http://xpto.com..."
                                            value={filteredData.link}
                                            onChange={(e) => setFilteredData({ ...filteredData, link: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* show_until */}
                                    <div className="form-group">
                                        <label className="text-label">Mostrar até*</label>
                                        <input
                                            required
                                            type="datetime-local"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            value={filteredData.show_until}
                                            onChange={(e) => setFilteredData({ ...filteredData, show_until: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* Description */}
                                    <div className="form-group">
                                        <label className="text-label">Descrição</label>
                                        <textarea
                                            className="form-control"
                                            style={{ width: '350px', height: '100px' }}
                                            value={filteredData.description}
                                            onChange={(e) => setFilteredData({ ...filteredData, description: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* Group */}
                                    <div className="form-group">
                                        <label className="text-label">Grupo*</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            style={{ width: '350px' }}
                                            onChange={(e) => setFilteredData({ ...filteredData, id_advertising_group: e.target.value })}
                                        >
                                            {
                                                gruops.map((group, index) =>
                                                    <option key={index} value={group.id} defaultValue={group.id === filteredData.id_advertising_group}>
                                                        {group.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row-forms">
                                    {/* image */}
                                    <div className="form-group">
                                        <label className="text-label">Imagem </label>
                                        <div
                                            className="form-control"
                                            style={{ width: '350px' }}
                                        >
                                            <FileBase64
                                                type="file"
                                                multiple={false}
                                                onDone={({ base64 }) => setFilteredData({ ...filteredData, image: base64 })} />
                                        </div>
                                    </div>
                                </div>

                                {/* active */}
                                <div className="row-forms">
                                    <div className="form-group">
                                        <label className="text-label">Ativo</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            style={{ width: '350px' }}
                                            onChange={(e) => setFilteredData({ ...filteredData, active: e.target.value })}
                                        >
                                            {
                                                list_select_yes_or_no.map((item, index) =>
                                                    <option key={index} value={item.value} defaultValue={filteredData.active === item.value}>
                                                        {item.text}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row-forms" style={{ flexDirection: 'row', display: 'contents' }}>
                                    <div className="form-group" >
                                        <button disabled={loader} onClick={() => edit()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                            {
                                                loader
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </>
                                                    : 'Editar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => setShowModal(false)}
                        >
                            <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        : <div><i className="far fa-check-circle green fa-2x"></i></div>

                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div>
        </div>
    )
}

