import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Rodal from 'rodal';
import { Close } from '@material-ui/icons';
import utilsSelect from '../../../../utils/selects';

export default function App(props) {

   const title = 'Contrato';
   const titleName = `Criação da ${title}`;
   const [toggleMenu, setToggleMenu] = useState(false);
   const fields = {
      advertisings_space: null,
      validity_start: '',
      validity_end: '',
      active: 1
   }
   const [inputs, setInputs] = useState(fields);
   const [dataSpace, setDataSpace] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [isError, setIsError] = useState(false);
   const [isSaveSuccess, setSaveSuccess] = useState(false);
   const [loader, setLoader] = useState(false);
   const [text, setText] = useState('');
   const [isDisabled, setIsDisabled] = useState(true);
   const [list_select_yes_or_no, setListYesOrNo] = useState([]);

   const getSpaces = async () => {
      try {
         debugger
            let res = await api.get(`advertising/spaces`);
            const objAux = [];
            res.data.data.forEach(element => {
               objAux.push({ Id: element.id, Name: element.name})
            });
            setDataSpace(objAux);
      } catch (err) {
            console.log(err);
      }
  }

   const create = async () => {
      setLoader(true)
      setIsError(false)
      try {
         let res = await api.post("/advertising/contract_company", {
            advertisings_space: inputs.advertisings_space,
            validity_start: inputs.validity_start,
            validity_end: inputs.validity_end,
            active: inputs.active
         });
         setText(res.data.message ?? res.statusText);
         setInputs(inputs);
         setShowModal(true);
         setSaveSuccess(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `Erro[${idx}][${member}]: ${error.response.data.errors[member]}\n`;
            idx ++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError);
         setShowModal(true);
         setSaveSuccess(false);
      }
      setLoader(false)
   }

   useEffect(() => {
      if (!showModal && isSaveSuccess) {
         props.history.push('/list-advertising-contract')
      }
   }, [showModal])


   // habilita botao criar
   useEffect(() => {
      setIsDisabled(false)
      if (inputs.advertisings_space === '0' || inputs.validity_start === '' || inputs.validity_end === '') {
         setIsDisabled(true)
      }
   }, [inputs])

   useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])
   useEffect(() => { getSpaces() }, [])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising-contract') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="row-forms">
                              {/* Type */}
                              <div className="form-group">
                                 <label className="text-label">Tipo *</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, advertisings_space: e.target.value })}
                                 >
                                    <option value='0' selected> -- Escolha uma opção -- </option>
                                    {
                                       dataSpace.map((item) =>
                                          <option key={item.Id} value={item.Id}>
                                             {item.Name}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                           <div className="form-group">
                              <label className="text-label">Data inicio validade* </label>
                              <input
                                 type="date"
                                 className="form-control"
                                 value={inputs.validity_start}
                                 onChange={(e) => setInputs({ ...inputs, validity_start: e.target.value })}
                                 setIsDisabled={false}
                              />
                           </div>
                           <div className="form-group">
                              <label className="text-label">Data final validade* </label>
                              <input
                                 type="date"
                                 className="form-control"
                                 value={inputs.validity_end}
                                 onChange={(e) => setInputs({ ...inputs, validity_end: e.target.value })}
                                 setIsDisabled={false}
                              />
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                    // onChange={(e) => console.log(e.target.value)}
                                 >
                                    {
                                       list_select_yes_or_no.map((item, index) =>
                                          <option key={index} value={item.value} selected={item.value === 1}>
                                             {item.text}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabled} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Criar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => setShowModal(false)}

                  >
                     <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

