import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'
import Moment from 'moment'
import utilsSelect from '../../../../utils/selects'
import FileBase64 from 'react-file-base64';

export default function App(props) {

   const title = 'Publicidade';
   const titleName = `Criação da ${title}`;
   const [toggleMenu, setToggleMenu] = useState(false)
   const fields = {
      name: '',
      description: null,
      active: '1'
   }
   const [inputs, setInputs] = useState(fields)
   const [showModal, setShowModal] = useState(false)
   const [isError, setIsError] = useState(false)
   const [loader, setLoader] = useState(false)
   const [text, setText] = useState('')
   const [gruops, setGruops] = useState([])
   const [list_select_yes_or_no, setListYesOrNo] = useState([])
   const [isDisabled, setIsDisabled] = useState(true)

   const create = async () => {
      setLoader(true)
      setIsError(false)

      try {
         let res = await api.post("/advertising/advertisings", {
            name: inputs.name,
            description: inputs.description,
            link: inputs.link,
            show_until: Moment(inputs.show_until).format('YYYY-MM-DD hh:mm:ss'),
            image: inputs.image,
            active: inputs.active,
            id_advertising_group: inputs.id_advertising_group,
            advertising_group: null
         });
         setText(res.data.message ?? res.statusText);
         setInputs(fields);
         setShowModal(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
            idx ++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
         setShowModal(true);
      }
      setLoader(false)
   }

   const getGruops = async () => {
      try {
         const res = await api.get('/advertising/groups')
         if (res.data.data) {
            setGruops(res.data.data);
         }
      }
      catch (error) {
         console.log("error =>", error);
      }
   }

   useEffect(() => { getGruops() }, [])
   useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])

   const handleCancel = async () => {
      setShowModal(false)
      props.history.push('/list-advertising')
   }

   // habilita botao criar
   useEffect(() => {
      setIsDisabled(false)

      if (inputs.name === '' || inputs.link === '' ||
         inputs.id_advertising_group === undefined || inputs.id_advertising_group === '0' ||
         inputs.show_until === undefined) {
         setIsDisabled(true)
      } else {
         if (!inputs.link.includes('http://')) {
            setIsDisabled(true)
         }
      }
   }, [inputs])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Nome*</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome"
                                    value={inputs.name}
                                    onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Link*</label>
                                 <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="http://xpto.com..."
                                    value={inputs.link}
                                    onChange={(e) => setInputs({ ...inputs, link: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Mostrar até*</label>
                                 <input
                                    required
                                    type="datetime-local"
                                    className="form-control"
                                    value={inputs.show_until}
                                    onChange={(e) => setInputs({ ...inputs, show_until: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Grupo*</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, id_advertising_group: e.target.value })}
                                 >
                                    <option value='0'>Escolha uma opção</option>
                                    {
                                       gruops.map((group, index) =>
                                          <option key={index} value={group.id}>
                                             {group.name}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* image */}
                              <div className="form-group">
                                 <label className="text-label">Imagem </label>
                                 <div
                                 className="form-control"
                                 style={{ width: '350px' }}
                                 >
                                 <FileBase64
                                    type="file"
                                    multiple={false}
                                    onDone={({ base64 }) => setInputs({ ...inputs, image: base64 })} />
                                 </div>
                                 
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Description */}
                              <div className="form-group">
                                 <label className="text-label">Descrição</label>
                                 <textarea
                                    className="form-control"
                                    value={inputs.description}
                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                 >
                                    {
                                       list_select_yes_or_no.map((item, index) =>
                                          <option key={index} value={item.value} selected={item.value === 1}>
                                             {item.text}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabled} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Criar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => handleCancel()}

                  >
                     <div className="close-rodal" onClick={() => handleCancel()}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

