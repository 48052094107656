import React, { useState } from 'react'
import "./panel.scss"
import "./panel.css"
import Menu from './menu'
import Chart from 'react-apexcharts'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)

  const options = () => {

    return {

      chart: {

        events: {

          dataPointSelection: function (event, chartContext, config) {

            // location(idx, config.dataPointIndex)

          }
        },
        width: '98%',
        align: 'top',
        dropShadow: {
          enabled: true,
          color: '#ccc',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        },
      },
      title: {

        align: 'center',
        style: {
          fontSize: '1.0vw',
          // offsetX: -2,
          color: '#000',
          fontWeight: 500,
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue"'
        },
      },

      colors: [
        '#fa930d', //tifanny 
        '#626364', //chumbo
        '#9c9a9e', //gray
        '#50086d', //tannat
        '#1b1750', //goiaba
        '#f19f40', //ocre
      ],

      fill: {
        type: 'gradient',
        //   gradient: {
        //     shadeIntensity: 1,
        //     opacityFrom: 0.7,
        //     opacityTo: 0.9,
        //     stops: [0, 90, 100],

        // }
      },
      dataLabels: {

        enabled: true,

        style: {
          fontSize: '13px'
        },
      },
      stroke: {
        width: [1, 1, 1, 1, 1],
        curve: 'straight',
        // dashArray: [0, 0, 0, 0, 0]
      },



      legend: {
        showForSingleSeries: true,
        show: true,
        position: 'bottom',
        // offsetY: -5,
        // height: 230,
        fontSize: '13px',

      },
      labels: [
        ['1 Graph'],
        ['2 Graph'],
        ['3 Graph'],
        ['4 Graph'],
        ['5 Graph'],
      ],
      // here
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
        },


      }
    }
  }
  const data = [44, 55, 13, 43, 22]
  return (
    <div className="wrapper">
      <Menu props={props} />
      <div className="container-panel">
        <div className="body-container">
          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>
          <div className="data">
            <div className="container-dashboards" style={{height: '70px', boxShadow: '0 1px 22px -12px #607d8b'}}>
              <div className="title">Trix - Dashboards</div>
            </div>
            <div className="container-dashboards" style={{marginTop: '20px'}}>
              <div className="dashboards">
                <Chart options={options()} series={data} height="90%" type={'pie'} />
              </div>
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              <div className="dashboards">
                <Chart options={options()} series={data} height="90%" type={'donut'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}