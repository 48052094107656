import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Timeline, TimelineEvent } from 'lv-react-horizontal-timeline'
import {
  FaCheck, FaRegFileAlt, FaDolly, FaTimes, FaRegHandshake, FaTruck, FaClipboardCheck
} from 'react-icons/fa'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import CurrencyFormat from 'react-currency-format'
import ReactStars from 'react-rating-stars-component'
import Pagination from '../../Pagination'
import ModalLoader from '../Loader/modalLoader'

export default function App(props) {

  Moment.locale('pt')

  const [toggleMenu, setToggleMenu] = useState(false)

  const COMPANY_TYPE = localStorage.getItem('COMPANY_TYPE')
  const [loaders, setLoaders] = useState({
    suppliers: false,
    budgets: false,
    budgetsItems: false,
    ratePurched: true
  })

  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showStatus, setShowStatus] = useState(false)
  const [text, setText] = useState('')
  const fields = {
    id: 0,
    name: '',
    description: '',
    response: '',
    status: '',
    value: 0
  }
  const [inputs, setInputs] = useState(fields)
  const [showRating, setShowRating] = useState(false)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [infoRow, setInfoRow] = useState('')
  const [iconClass, setIconClass] = useState("far fa-check-circle green fa-2x")
  const [error, setError] = useState(false)
  const [budgeItems, setBudgeItems] = useState([])
  const [approvedItems, setApprovedItems] = useState([])
  const [showTimeline, setShowTimeline] = useState(false)
  const [showModalDelivery, setShowModalDelivery] = useState(false)
  const deliveryFields = {
    email: '',
    option: 'retirada',
    forecast: '',
    date_receipt: '',
    status: ''
  }
  const [deliveryInfos, setDeliveryInfos] = useState(deliveryFields);
  const [isDisabled, setIsDisabled] = useState(true)

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const [filter, setFilter] = useState({
    name: '',
    tax_code: false,
    status: ''
  })
  // const [filter, setFilter] = useState('')
  // paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.length > 0 ? data.slice(indexOfFirstPost, indexOfLastPost) : 0;
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const [itemValues, setItemValues] = useState([])
  const fieldsTimeLine = {
    created_at: '',
    status: '',
    deliver_option: '',
    receipt_date: '',
    delivery_forecast_date: ''
  }
  const [timelineInfo, setTimelineInfo] = useState(fieldsTimeLine)
  // let itemValues = [];
  const [loaderMessage, setLoaderMessage] = useState(false)

  const tooltips = [
    "O pedido foi recusado, e encontra-se finalizado.",
    "O pedido foi aprovado pelo comprador, e aguarda a confecção pelo Parceiro Fornecedor.",
    "O pedido está aguardando uma ação do Parceiro Fornecedor!",
    "O pedido está aguardando uma ação do Parceiro Comercial!",
    "O pedido está pronto para ser entregue.",
    "O pedido foi entregue, e encontra-se finalizado."
  ]

  const getBudgets = async () => {
    setLoaders({ ...loaders, budgets: true })
    setError(false)
    setData([])

    try {
      let filters = '?'

      if (filter.name) {
        filters += `name=${filter.name}&`
      }
      if (filter.tax_code) {
        filters += `cpf=${filter.cpf}&`
      }
      if (filter.status) {
        filters += `filter=${filter.status}&`
      }

      filters = filters.substring(filters[0], filters.length - 1)
      let res

      if (filters) {
        res = await api.get(`/budget${filters}`)
      } else {
        res = await api.get('/budget')
      }

      if (res.data) {
        setData(res.data)
      }

      if (res.data.error) {
        setError(true)
        setData([])
        setText(res.data.error)
      }
    } catch (error) {
      console.log("error =>", error.response.data.message)
    }
    setLoaders({ ...loaders, budgets: false })
  }

  const toggleStatus = async (row, status) => {
    debugger
    setShowRating(false)
    setLoaders({ ...loaders, update: true })
    setIconClass("")

    let url = COMPANY_TYPE === '3' ? `budget/${row.id}` : `budget-approve/${row.id}`
    let body

    if (COMPANY_TYPE === '3') {
      itemValues.forEach((k, v) => {
      })

      let aux = Object.entries(itemValues);
      let items = [];
      aux.forEach((values, index) => {
        let temp = [];
        if (values[1] == 0 || values[1] == 'R$ 0,00') {
          temp = [values[0], 0, 'reprovado'];
        } else {
          temp = [values[0], values[1], 'aprovado'];
        }
        items.push(temp);
      })

      body = { response: row.response, status: status, value: row.value, items: items }

      if (status == 3) {
        body = {
          response: row.response,
          status: status,
          value: row.value,
          items: items,
          delivery_infos: deliveryInfos,
          approvedItems: approvedItems
        }
      }
    } else {
      if (rating !== 0) {
        body = { status: status, rating: rating, evaluation: comment }
      } else {
        body = { response: row.response ? row.response : '', status: status }
      }
    }
    
    try {
      const res = await api.put(url, body)

      if (res.data.error) {
        setText(res.data.error)
        setIconClass("fas fa-times red fa-2x")
      } else if (res.data.message) {
        setText(res.data.message)
        setIconClass("far fa-check-circle green fa-2x")
      }
      setShowModal(false)
      setShowStatus(true)
      setShowDetails(false)
      setShowModalDelivery(false)
      getBudgets()
    } catch (error) {
      console.log("error =>", error)
    } finally {
      setLoaders({ ...loaders, update: false })
      setInputs(fields)
      setComment('')
      setDeliveryInfos({
        email: '',
        option: 'retirada',
        forecast: '',
        date_receipt: '',
        status: ''
      })
    }
  }

  const getDetails = (row) => {
    setInputs({
      ...inputs,
      id: row.id,
      name: `Orçamento #${row.id}`,
      response: row.response,
      description: row.request,
      value: row.value,
      status: row.status,
      id_selected_budget: row.id_selected_budget,
      hash_invoice: row.hash_invoice,
      extension: row.extension,
      name_file: row.name_file,
      type_file: row.type_file
    })

    setTimelineInfo({
      created_at: row.created_at,
      status: row.status,
      receipt_date: row.receipt_date,
      delivery_option: row.delivery_option,
      delivery_forecast_date: row.delivery_forecast_date,
    });

    getBudgetItems(row.id)
  }

  const handleApprovedChange = (id, event) => {
    let _approvedItemsTmp = [...approvedItems]
    if (event.target.checked) {
      const index = _approvedItemsTmp.findIndex((b) => b.id === id)
      if (index === -1) {
        _approvedItemsTmp.push({
          id: id
        });
      }
    } else {
      _approvedItemsTmp = _approvedItemsTmp.filter((as) => as.id !== id)
    }

    setApprovedItems(_approvedItemsTmp)

  }

  const getStatus = (status) => {
    // visao PC
    if (COMPANY_TYPE === '2') {
      switch (status) {
        // orçamento recem aberto, só pode ser cancelado
        case 2:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                disabled={!inputs.response}
                onClick={() => toggleStatus(inputs, 0)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-warning"
              >
                Rejeitar
              </button>

              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )
        case 3:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                onClick={() => toggleStatus(inputs, 1)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-success"
              >
                Aprovar
              </button>

              <button
                onClick={() => toggleStatus(inputs, 0)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-warning"
              >
                Rejeitar
              </button>

              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )
        case 4:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                onClick={() => handleRating(inputs)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-success"
              >
                Finalizar
              </button>

              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )
        default:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )

      }

    }
    if (COMPANY_TYPE === '3') {
      switch (status) {
        case 1:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                disabled={!inputs.response}
                onClick={() => toggleStatus(inputs, 4)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-success"
              >
                Concluir
              </button>

              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )

        case 2:
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                <button
                  disabled={!inputs.response || !inputs.value}
                  onClick={() => setShowModalDelivery(true)}
                  style={{ marginLeft: 10, width: '150px' }}
                  type="button"
                  className="btn btn-success"
                >
                  Aceitar
                </button>

                <button
                  disabled={!inputs.response}
                  onClick={() => toggleStatus(inputs, 0)}
                  style={{ marginLeft: 10, width: '150px' }}
                  type="button"
                  className="btn btn-warning"
                >
                  Rejeitar
                </button>

                <button
                  onClick={handleTimeline}
                  style={{ marginLeft: 10, width: '140px' }}
                  type="button"
                  className="btn btn-info"
                >
                  <b>Linha do tempo</b>
                </button>
              </div>
            </>
          )

        case 3:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                disabled={!inputs.response}
                onClick={() => toggleStatus(inputs, 0)}
                style={{ marginLeft: 10, width: '150px' }}
                type="button"
                className="btn btn-warning"
              >
                Rejeitar
              </button>
              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )
        default:
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
              <button
                onClick={handleTimeline}
                style={{ marginLeft: 10, width: '140px' }}
                type="button"
                className="btn btn-info"
              >
                <b>Linha do tempo</b>
              </button>
            </div>
          )
        // case 4:
        //     return (
        //         < Dropdown.Item onClick={() => toggleStatus(row, 5)}>
        //             Finalizado <i className="la la-legal blue la-lg"></i>
        //         </Dropdown.Item >
        //     )
      }
    }
  }

  function handleRating(row) {
    setInfoRow(row)
    setShowRating(true);
  }

  const ratingChanged = (newRating) => {
    setRating(newRating)
  };


  function handleCancel() {
    setShowModal(false);
    getBudgets();
  }

  useEffect(() => {
    if (rating > 0 && comment.length >= 5 && comment !== "")
      setLoaders({ ...loaders, ratePurched: false })
    else
      setLoaders({ ...loaders, ratePurched: true })
  }, [rating,comment])

  useEffect(() => {
    getBudgets()
  }, [])

  const getBudgetItems = async (id) => {
    setLoaders({ ...loaders, budgetItems: true })
    setError(false)

    try {

      const res = await api.get(`/items-budget/${id}`)

      if (res.data) {
        setBudgeItems(res.data)

        let arr = [];

        res.data.map(e => {
          let value = e.unity_price;
          value = value.replace('.', ',');
          arr[e.uuid] = value;
        })
        setItemValues(arr);
      }

      if (res.data.error) {
        setError(true)
        setData([])
        setText(res.data.error)
      }
    } catch (error) {
      console.log("error =>", error.response.data.message)
    } finally {
      setShowDetails(true)
      setLoaders({ ...loaders, budgetItems: false })
    }
  }

  const changeItemValue = async (index, value) => {
    let arr = itemValues;
    arr[index] = value
    setItemValues(arr);
  }

  const download = async (row) => {

    try {
      let a = document.createElement("a")
      a.href = row.hash_invoice
      if (row.name_file) {
        a.download = `${row.name_file}.${row.extension}`
      } else {
        a.download = `Orcamento.${row.extension}`
      }
      a.click()
    }
    catch (error) {
      console.log("error =>", error)
    }
  }

  useEffect(() => {
    console.log(deliveryInfos.email, deliveryInfos.option, deliveryInfos.forecast)
    setIsDisabled(true)

    if (deliveryInfos.email !== '' && deliveryInfos.option !== '' && deliveryInfos.forecast !== '') {
      setIsDisabled(false)
    }
  }, [deliveryInfos])

  function handleTimeline() {
    setShowTimeline(true)
  }

  function handleCloseDelivery() {
    setShowModalDelivery(false);
    setDeliveryInfos({
      email: '',
      option: 'retirada',
      forecast: '',
      date_receipt: '',
      status: ''
    })
  }

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>

            <div className="menu-center">
              <div className="filters-menu">

                <div className="input-group-prepend">
                  <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                    <FilterList fontSize="default" className="blue" />
                  </div>
                </div>

                {/* <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                /> */}

                {/* <CurrencyFormat format="##.###.###/####-##" mask="_"
                                    placeholder="CNPJ"
                                    className="form-control"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.tax_code}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, tax_code: formattedValue })
                                    }}
                                /> */}

                <select
                  onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                  style={{ width: '15%' }}
                  className="form-control"
                >
                  <option value="">TODOS</option>
                  <option value="EA">EM ANDAMENTO</option>
                  <option value="FI">FINALIZADOS</option>
                  <option value="AV">AG. VENDEDOR</option>
                  <option value="AC">AG. COMPRADOR</option>
                </select>

                <button
                  disabled={loaders.suppliers}
                  onClick={() => getBudgets()}
                  style={{ marginLeft: 10, width: '120px' }}
                  id="search-charge-back"
                  type="button"
                  className="btn btn-info"
                >
                  {
                    loaders.suppliers
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <i className="fas fa-search fa-rotate-90"></i>
                  }
                </button>
              </div>
            </div>

            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel" style={{ padding: '1%' }}>
              {/* grid de orçamentos */}
              <section
                className="container-trix"
                style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}
              >

                <div className="row-forms">
                  {
                    loaders.suppliers
                      ?
                      <div>
                        Buscando Orçamentos...
                        <ThreeDots
                          color="#0ccbec"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                      </div>
                      :
                      <>
                        <div className="row-forms" style={{ flexDirection: 'row' }}>
                          <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Lista de Orçamentos</label>
                        </div>
                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                          <thead>
                            <tr role='row'>
                              <th className="sorting_asc"> ID </th>
                              <th style={{ textAlign: 'start' }} className="sorting_asc">Orçamentos</th>
                              <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                              <th className="sorting_asc">Data - Hora</th>
                              <th className="sorting_asc">Status</th>
                              <th className="sorting_asc">Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              error ?
                                <p>{text}</p>
                                :
                                currentPosts.length > 0 &&
                                currentPosts.map((row, idx) =>
                                  <tr role="row" key={idx}>
                                    <td className="sorting_asc">OS-{row.id}</td>
                                    <td style={{ textAlign: 'start' }} className="sorting_asc">{`Orçamento #${row.id}`}</td>
                                    <td>{row.company_partner ? row.company_partner.name : row.company_supplier.name}</td>
                                    <td className="sorting_asc">{Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td className="">
                                      {
                                        row.status === 0 ?
                                          <div style={{ color: 'red' }}>
                                            Recusado
                                            <i className="la la-times-circle red la-lg" title={tooltips[row.status]} />
                                          </div>
                                          : row.status === 1 ?
                                            <div style={{ color: 'green' }}>
                                              Comprador Aprovou
                                              <i className="la la-check-circle green la-lg" title={tooltips[row.status]} />
                                            </div>
                                            : row.status === 2 ?
                                              <div style={{ color: 'orange' }}>
                                                Aguardando Vendedor
                                                <i className="la la-info-circle la-lg" title={tooltips[row.status]} />
                                              </div>
                                              : row.status === 3 ?
                                                <div style={{ color: 'gray' }}>
                                                  Aguardando Comprador
                                                  <i className="la la-check-circle green la-lg" title={tooltips[row.status]} />
                                                </div>
                                                : row.status === 4 ?
                                                  <div style={{ color: 'gray' }}>
                                                    Produto Enviado/Pronto
                                                    <i className="la la-check-circle blue la-lg" title={tooltips[row.status]} />
                                                  </div>
                                                  : row.status === 5 ?
                                                    <div style={{ color: 'gray' }}>
                                                      Produto Entregue
                                                      <i className="la la-legal blue la-lg" title={tooltips[row.status]} />
                                                    </div>
                                                    :
                                                    <div>Não Identificado</div>
                                      }
                                    </td>
                                    <td className="sorting_asc" style={{ width: '300px' }} >
                                      <Dropdown >
                                        <Dropdown.Toggle
                                          variant="success"
                                          className="light sharp i-false"
                                          style={{
                                            width: '150px',
                                            height: '20px'
                                          }}
                                        >
                                          {svg1}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => getDetails(row)}>
                                            <i className="la la-info-circle gray la-lg" /> Detalhes
                                          </Dropdown.Item>
                                          {/* {getStatus(row)} */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                )
                            }
                          </tbody>
                        </table>

                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={data.length}
                          paginate={paginate}
                        />
                      </>
                  }
                </div>
              </section>

              {/* Detalhamento */}
              <Rodal
                visible={showDetails}
                animation="slideLeft"
                customStyles={{ width: '60%', height: '80%', overflowY: 'scroll', marginTop: '3%' }}
                showCloseButton={true}
                onClose={() => setShowDetails(false)}>
                <div className="close-rodal" onClick={() => { setShowDetails(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">DETALHAMENTO</div>
                <div className="detal-modal-item">
                  {/* nome orçamento */}
                  <div className="row-forms">
                    <div className="form-group">
                      <label className="text-label blue"> {inputs.name} </label>
                    </div>
                  </div>

                  {/* itens */}
                  <div
                    className="row-forms"
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                    <div className="form-group" >
                      {
                        budgeItems.map((item) => (
                          <div className="row-forms" style={{ marginBottom: -10, flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            {/* grupo */}
                            <div className="form-group" style={{ width: '15%', marginRight: 5 }}>
                              <label className="text-label">Grupo</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Grupo"
                                value={item.group === "P" ? 'Produto' : 'Serviço'}
                                disabled
                              />
                            </div>
                            {/* descrição */}
                            <div className="form-group" style={{ width: '55%', marginRight: 5 }}>
                              <label className="text-label">Descrição</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Descrição"
                                value={item.name}
                                disabled
                              // onChange={(e) => setBudgeItems({ ...budgeItems, name: e.target.value })}
                              />
                            </div>
                            {/* {
                              item.group === "P" ? */}
                            {/* unidade */}
                            <div className="form-group" style={{ width: '10%', marginRight: 5 }}>
                              <label className="text-label">Unidade</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Unidade"
                                value={item.unity}
                                disabled
                              // onChange={(e) => setBudgeItems({ ...budgeItems, unity: e.target.value })}
                              />
                            </div>
                            {/* :
                                <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                  <label className="text-label">Hora</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Hora"
                                    value={item.hour}
                                    disabled
                                  // onChange={(e) => setBudgeItems({ ...budgeItems, hour: e.target.value })}
                                  />
                                </div>
                            } */}
                            {/* quantidade */}
                            <div className="form-group" style={{ width: '10%', marginRight: 5 }}>
                              <label className="text-label">Quantidade</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Quantidade"
                                value={item.quantity}
                                disabled
                              // onChange={(e) => setBudgeItems({ ...budgeItems, quantity: e.target.value })}
                              />
                            </div>
                            {/* valor */}
                            <div className="form-group" style={{ width: '15%' }}>
                              <label className="text-label">Valor Unit.</label>
                              <CurrencyFormat
                                style={{ color: '#4bc019' }}
                                currency="BRL"
                                prefix='R$ '
                                placeholder="Valor"
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="form-control"
                                value={itemValues[item.uuid] || 0}
                                disabled={COMPANY_TYPE === '2' || inputs.status != 2}
                                onChange={(e) => changeItemValue(item.uuid, e.target.value)}
                              />
                            </div>
                            <div className="form-group" style={{ width: '5%', marginRight: 10 }}>
                              <label className="text-label">Aprovado</label>
                              {
                                COMPANY_TYPE == 3 && inputs.status == 2
                                  ?
                                  <input
                                    type='checkbox'
                                    onChange={(e) => handleApprovedChange(item.id, e)}
                                  />
                                  :
                                  <input
                                    type='checkbox'
                                    checked={item.status_item == 'aprovado' ? true : false}
                                    disabled={true}
                                  />
                              }

                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  {/* download pdf */}
                  <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ width: "45%" }}>
                      <div className="form-group" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',

                      }}>
                        {/* <input
                          id='selecao-arquivo'
                          type='file'
                          // style={{ display: 'none' }} 
                          // onChange={(e) => sendFile(e)}
                          onClick={() => download(inputs)}
                          style={{ cursor: 'pointer' }}
                        >
                        </input> */}
                        {
                          inputs.hash_invoice &&
                          <div
                            onClick={() => download(inputs)} style={{ cursor: 'pointer' }}
                          >
                            <i class="las la-file-alt green la-3x"></i>
                            <i className="la la-arrow-down green"></i>
                            <label style={{ fontSize: 12 }}>{inputs.name_file}.{inputs.extension}</label>
                          </div>
                        }
                        {/* <label
                          for='selecao-arquivo'
                          style={{
                            border: 'none',
                            padding: '10px',
                            borderRadius: '3px',
                            backgroundColor: '#daccbd',
                            height: 45,
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}>Upload .PDF</label>
                        <p>{inputs.file.nameFile}</p> */}
                      </div>
                    </div>
                  </div>

                  {/* descrição */}
                  <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                    <label className="text-label">Descrição*</label>
                    <textarea
                      disabled
                      type="text"
                      className="form-control"
                      required
                      value={inputs.description}
                    />
                  </div>
                  {
                    inputs.id_selected_budget != null ?
                      <>
                        <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                          <label className="text-label">Orçamento Vinculado</label>
                          <select style={{ width: '50%' }} className="form-control" disabled>
                            <option value={inputs.id_selected_budget}>Orçamento #{inputs.id_selected_budget}</option>
                          </select>
                        </div>
                      </> : <></>
                  }
                  {/* resposta */}
                  <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                    <label className="text-label">Resposta</label>
                    {
                      inputs.status == 2 ?
                        <textarea
                          type="text"
                          className="form-control"
                          required
                          value={inputs.response}
                          onChange={(e) => { setInputs({ ...inputs, response: e.target.value }) }}
                        /> : inputs.status == 0 ?
                          <textarea
                            disabled
                            type="text"
                            className="form-control"
                            required
                            value={inputs.response}
                          />
                          : <textarea
                            disabled={inputs.response}
                            type="text"
                            className="form-control"
                            required
                            value={inputs.response}
                            onChange={(e) => { setInputs({ ...inputs, response: e.target.value }) }}
                          />
                    }
                  </div>
                  {/* valor */}
                  <br />
                  <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                    <label className="text-label">Valor:</label>
                    {
                      COMPANY_TYPE === '3' && inputs.status == 2 ?
                        <CurrencyFormat
                          style={{ color: '#4bc019' }}
                          currency="BRL"
                          prefix='R$ '
                          placeholder="Valor"
                          thousandSeparator='.'
                          decimalSeparator=','
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="form-control"
                          value={inputs.value || 0}
                          onValueChange={(values) => {
                            const { floatValue } = values
                            setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                          }}
                        />
                        : <CurrencyFormat
                          disabled
                          style={{ color: '#4bc019' }}
                          currency="BRL"
                          prefix='R$ '
                          placeholder="Valor"
                          thousandSeparator='.'
                          decimalSeparator=','
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="form-control"
                          value={inputs.value || 0}
                        />
                    }
                  </div>

                  {/* botoes */}
                  {getStatus(inputs.status)}
                </div>
              </Rodal>

              {/* detalhamento */}
              <Rodal
                visible={showModal}
                animation="slideLeft"
                customStyles={{ width: '60%', height: 'calc(80%)', marginTop: '7%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}>
                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">DETALHAMENTO</div>
                <div className="detal-modal">
                  <div className="row-forms">
                    <div className="form-group">
                      <label className="text-label blue">-- {inputs.name} --</label>
                    </div>
                  </div>
                  <div className="row-forms" style={{ textAlign: 'initial' }}>

                    <label className="text-label">Descrição*</label>
                    <textarea
                      disabled
                      type="text"
                      className="form-control"
                      required
                      value={inputs.description}
                    />
                    <hr />
                  </div>
                  <br />
                  {
                    COMPANY_TYPE === '3' &&
                    <>
                      <div className="row-forms">
                        <div className="form-group">
                          <label className="text-label">Resposta*</label>
                          <textarea
                            type="text"
                            className="form-control"
                            required
                            value={inputs.response}
                            onChange={(e) => setInputs({ ...inputs, response: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="row-forms">
                        <div className="form-group">
                          <label className="text-label">Total</label>
                          {
                            inputs.value !== null && inputs.value !== 0 ?
                              <CurrencyFormat style={{ color: '#4bc019' }}
                                currency="BRL"
                                prefix='R$ '
                                placeholder="Valor..."
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="form-control"
                                value={inputs.value}
                                onValueChange={(values) => {
                                  const { floatValue } = values
                                  setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                }}
                              />
                              :
                              <CurrencyFormat style={{ color: '#4bc019' }}
                                currency="BRL"
                                prefix='R$ '
                                placeholder="Valor..."
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="form-control"
                                value={''}
                                onValueChange={(values) => {
                                  const { floatValue } = values
                                  setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                }}
                              />
                          }

                        </div>
                      </div>
                      <div className="row-forms">
                        <div className="form-group" >
                          <button
                            disabled={inputs.value === 0 || inputs.value === null ? true : false}
                            onClick={() => toggleStatus(inputs, 3)}
                            style={{ width: '40%' }} type="button" className="btn btn-info">

                            {
                              loaders.update
                                ?
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  &nbsp;Processando...
                                </>
                                :
                                'Aceitar'

                            }
                          </button>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </Rodal>

              {/* avaliação */}
              <Rodal
                visible={showRating}
                animation="slideLeft"
                customStyles={{
                  width: '30%',
                  height: 'auto',
                  marginTop: '1%',
                  bottom: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
                showCloseButton={true}
                onClose={() => setShowRating(false)}>
                <div className="close-rodal" onClick={() => setShowRating(false)}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="detal-modal">
                  <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                    <Row>
                      <Col>
                        COMO VOCÊ AVALIA ESTA COMPRA?
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={40}
                          activeColor="#ffd700"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <textarea
                          type="text"
                          className="form-control"
                          required
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Deixe seu comentário"
                        />
                      </Col>
                    </Row>
                  </Container>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      onClick={() => toggleStatus(infoRow, 5)}
                      style={{ marginLeft: 10, width: '150px' }}
                      type="button"
                      className="btn btn-success"
                      disabled={loaders.ratePurched}
                    >
                      Avaliar
                    </button>
                    <button
                      onClick={handleCancel}
                      style={{ marginLeft: 10, width: '120px' }}
                      type="button"
                      className="btn btn-danger"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </Rodal>

              {/* Rodal informações de entrega */}
              <Rodal
                visible={showModalDelivery}
                animation="slideLeft"
                customStyles={{ width: '70%', height: 'auto', marginTop: '1%', bottom: 'auto', top: '50%', transform: 'translateY(-50%)' }}
                showCloseButton={true}
                onClose={() => handleCloseDelivery()}
              >
                <div className="close-rodal" onClick={() => handleCloseDelivery()}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className="title">Informações de Entrega</div>
                <div className="detal-modal">
                  {/* cabeçalho */}
                  <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                    <Row style={{ marginBottom: '16px' }}>
                      {/* area de e-mail */}
                      <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                        <label className="text-label">E-mail</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Digite a informação a ser enviada por e-mail ao cliente..."
                          style={{ height: 150 }}
                          value={deliveryInfos.email}
                          disabled={!inputs.status == 2}
                          onChange={(e) => setDeliveryInfos({ ...deliveryInfos, email: e.target.value })}
                        />
                      </div>

                      <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 105 }}>
                          {/* forma de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                            <label className="text-label">Forma de Entrega</label>
                            <select
                              value={deliveryInfos.option}
                              className='form-control form-control-lg'
                              disabled={!inputs.status == 2}
                              onChange={(e) => setDeliveryInfos({ ...deliveryInfos, option: e.target.value })}
                            >
                              <option value="retirada">Entrega Balcão</option>
                              <option value="correios">Envio Correios</option>
                              <option value="terrestre">Despacho Terrestre</option>
                              <option value="aereo">Despacho Aéreo</option>
                            </select>
                          </div>
                          {/* previsão de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                            <label className="text-label">Previsão de Entrega</label>
                            {/* data ini */}
                            <input style={{ width: '90%', height: 45 }}
                              value={deliveryInfos.forecast}
                              disabled={!inputs.status == 2}
                              onChange={(e) => setDeliveryInfos({ ...deliveryInfos, forecast: e.target.value })}
                              type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
                            &nbsp;&nbsp;
                          </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {/* status */}
                          <div className="form-group" style={{ marginRight: 5, width: '50%' }}>
                            <label className="text-label">Status da Entrega</label>
                            <select
                              value={deliveryInfos.status}
                              disabled
                              className='form-control form-control-lg'
                            // onChange={(e) => setDeliveryInfos({ ...deliveryInfos, status: e.target.value })}
                            >
                              <option value="andamento">Em Andamento</option>
                              <option value="atraso">Atrasado</option>
                              <option value="finalizado">Finalizado</option>
                            </select>
                          </div>
                          {/* data de entrega */}
                          <div className="form-group" style={{ marginRight: 5, width: '45%' }}>
                            <label className="text-label">Data de Entrega</label>
                            {/* data ini */}
                            <input style={{ width: '90%', height: 45 }}
                              value={deliveryInfos.date_receipt}
                              disabled
                              // onChange={(e) => setDeliveryInfos({ ...deliveryInfos, date_receipt: e.target.value })}
                              type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
                            &nbsp;&nbsp;
                          </div>
                        </div>
                      </div>
                    </Row>

                  </Container>
                  {/* botoes */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                      inputs.status == 2 ?
                        <>
                          <button
                            onClick={() => toggleStatus(inputs, 3)}
                            //</>onClick={() => updateSale(inputs.id, '1')}
                            style={{ marginLeft: 10, width: '200px', height: '50px', fontSize: '0.9rem', backgroundColor: '#5e72e4' }}
                            disabled={isDisabled}
                            type="button"
                            className="btn btn-success"
                          >
                            Envia e-mail e Realiza a Venda
                          </button>
                          <button
                            onClick={() => setShowModalDelivery(false)}
                            // setIsReasonRefuse(true)
                            style={{ marginLeft: 10, width: '200px', height: '50px', fontSize: '0.9rem', backgroundColor: '#ff9900' }}
                            type="button"
                            className="btn btn-warning"
                          >
                            Retornar para o menu anterior
                          </button>
                        </> : <></>
                    }
                  </div>
                </div>
              </Rodal>

              {/* modal timeline */}
              <Rodal
                visible={showTimeline}
                animation="flip"
                customStyles={{ width: '70%', height: 'calc(50%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}

              >
                <div className="close-rodal" onClick={() => { setShowTimeline(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">LINHA DO TEMPO</div>

                <div style={{ marginTop: '10px' }}>
                  {
                    timelineInfo.status == 0
                      ?
                      <Timeline minEvents={2} placeholder>
                        <TimelineEvent
                          color='#2BC155'
                          icon={FaRegFileAlt}
                          title='Criado'
                          subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                        />
                        <TimelineEvent
                          color='#ff0000'
                          icon={FaTimes}
                          title='Recusado'
                        // subtitle=""
                        />
                      </Timeline>
                      :
                      <Timeline minEvents={3} placeholder>
                        <TimelineEvent
                          color='#2BC155'
                          icon={FaRegFileAlt}
                          title='Criado'
                          subtitle={Moment(timelineInfo.created_at).format('DD/MM/YYYY HH:mm')}
                        />
                        <TimelineEvent
                          color={(timelineInfo.status == 3 || timelineInfo.status == 1 || timelineInfo.status == 4 || timelineInfo.status == 5)
                            ?
                            '#2BC155'
                            :
                            '#e0e0e0'}
                          icon={FaClipboardCheck}
                          title='Aceito'
                        />
                        <TimelineEvent
                          color={timelineInfo.delivery_option != null ? '#2BC155' : '#e0e0e0'}
                          icon={FaTruck}
                          title='Forma de entrega'
                          subtitle={timelineInfo.delivery_option}
                        />
                        <TimelineEvent
                          color={(timelineInfo.status == 1 || timelineInfo.status == 4 || timelineInfo.status == 5) ? '#2BC155' : '#e0e0e0'}
                          icon={FaRegHandshake}
                          title='Aprovado'
                        />
                        {/* Passo seleciona  de entrega ao realizar a venda */}
                        <TimelineEvent
                          color={(Moment(timelineInfo.delivery_forecast_date).isSameOrBefore(Moment()) && (timelineInfo.status == 1 || timelineInfo.status == 4 || timelineInfo.status == 5)) ? '#2BC155' : '#e0e0e0'}
                          icon={FaDolly}
                          title={timelineInfo.delivery_option == 'retirada' ? 'Aguardando retirada' : 'Aguardando entrega'}
                          subtitle={timelineInfo.delivery_forecast_date != null ? Moment(timelineInfo.delivery_forecast_date).format('DD/MM/YYYY') : ""}
                        />
                        <TimelineEvent
                          color={(timelineInfo.status == '5') ? '#2BC155' : '#e0e0e0'}
                          icon={FaCheck}
                          title={timelineInfo.delivery_option == 'retirada' ? 'Retirado' : 'Entregue'}
                          subtitle={timelineInfo.receipt_date != null ? Moment(timelineInfo.receipt_date).format('DD/MM/YYYY') : ""}
                        />
                      </Timeline>
                  }
                </div>
              </Rodal>

              {/* Rodal loader */}
              <Rodal
                visible={loaders.update}
                animation="slideLeft"
                customStyles={{
                  width: '30%',
                  height: '140px',
                  marginTop: '-5%',
                  bottom: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)',

                }}
                showCloseButton={false}
              >
                <ModalLoader data={loaderMessage} />
              </Rodal>

              {/* retorno api */}
              <Rodal
                visible={showStatus}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowStatus(false)}
              >
                <div className="close-rodal" onClick={() => { setShowStatus(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="modal-error">
                  <div><i className={iconClass}></i></div>
                  <div>{text}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                  <div className="modal-error-buttons" />
                </div>
              </Rodal>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

