import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'
import ThreeDots from 'react-loader-spinner'
import Multiselect from 'multiselect-react-dropdown';

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const fields = {
        company_type: '',
        tax_code: '',
        trading_name: null,
        name: null,
        state_registration: null,
        cep: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        phone_number: '',
        mail: '',
        category: '',
        pay_day: '',
        id_company_partner: null,
        mdr_value: '',
        mdr_percent: '',
        mdr_advertising_value: '',
        inactivity_fee: '',
        list_group_advertising_selected: null
    }
    const [inputs, setInputs] = useState(fields)
    const UF = [
        ["AC", "Acre"],
        ["AL", "Alagoas"],
        ["AP", "Amapá"],
        ["AM", "Amazonas"],
        ["BA", "Bahia"],
        ["CE", "Ceará"],
        ["DF", "Distrito Federal"],
        ["ES", "Espírito Santo"],
        ["GO", "Goiás"],
        ["MA", "Maranhão"],
        ["MT", "Mato Grosso"],
        ["MS", "Mato Grosso do Sul"],
        ["MG", "Minas Gerais"],
        ["PA", "Pará"],
        ["PB", "Paraíba"],
        ["PR", "Paraná"],
        ["PE", "Pernambuco"],
        ["PI", "Piauí"],
        ["RJ", "Rio de Janeiro"],
        ["RN", "Rio Grande do Norte"],
        ["RS", "Rio Grande do Sul"],
        ["RO", "Rondônia"],
        ["RR", "Roraima"],
        ["SC", "Santa Catarina"],
        ["SP", "São Paulo"],
        ["SE", "Sergipe"],
        ["TO", "Tocantins"]
    ]
    const [showModal, setShowModal] = useState(false)
    const [isError, setIsError] = useState(false)
    const [companies, setCompanies] = useState([])
    const [loader, setLoader] = useState(false)
    const [loaderCEP, setLoaderCEP] = useState(false)
    const [text, setText] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [dataGroupAdvertising, setDataGroupAdvertising] = useState([]);
    const ID_PROFILE = localStorage.getItem('ID_PROFILE')
    const ID_COMPANY = localStorage.getItem('ID_COMPANY')

    const getCompanies = async () => {
        try {
            let res

            if (ID_PROFILE === 2) {
                res = await api.get(`/company/${ID_COMPANY}`)
            } else {
                res = await api.get(`/company`)
            }

            if (res.data) {
                if (res.data.length) {

                    let empresas = []
                    res.data.forEach((empresa) => {
                        if (empresa.company_type === 1) {
                            empresas.push(empresa)
                        }
                    })

                    setCompanies(empresas)
                } else {
                    setCompanies([res.data])
                }

            }
        }

        catch (error) {
            console.log("error =>", error)
        }
    }

    const getGroupAdvertising = async () => {
        try {
            const res = await api.get('/advertising/groups')
            if (res.data.data.length > 0)
                setDataGroupAdvertising(res.data.data);

            //console.log("res => ", dataGroupAdvertising);
        } catch (error) {
            console.log("error =>", error);
        }
    }

    const create = async () => {
        setLoader(true)
        setIsError(false)
        try {
            let res = ""

            if (inputs.company_type !== '1' && inputs.company_type !== '') {
                res = await api.post("/company", {
                    name: inputs.name,
                    trading_name: inputs.trading_name,
                    tax_code: inputs.tax_code,
                    company_type: inputs.company_type,
                    address: inputs.address,
                    number: inputs.number,
                    city: inputs.city,
                    state: inputs.state,
                    district: inputs.district,
                    phone_number: inputs.phone_number,
                    complement: inputs.complement,
                    mail: inputs.mail,
                    state_registration: inputs.state_registration,
                    cep: inputs.cep,
                    pay_day: inputs.pay_day,
                    category: inputs.category,
                    id_company_partner: inputs.id_company_partner,
                    mdr_value: inputs.mdr_value.replace(',', '.'),
                    mdr_percent: inputs.mdr_percent.replace(',', '.'),
                    mdr_advertising: inputs.mdr_advertising_value.replace(',', '.'),
                    inactivity_fee: inputs.inactivity_fee.replace(',', '.')
                })

            } else {
                res = await api.post("/company", {
                    name: inputs.name,
                    trading_name: inputs.trading_name,
                    tax_code: inputs.tax_code,
                    company_type: inputs.company_type,
                    address: inputs.address,
                    number: inputs.number,
                    city: inputs.city,
                    state: inputs.state,
                    district: inputs.district,
                    phone_number: inputs.phone_number,
                    complement: inputs.complement,
                    mail: inputs.mail,
                    state_registration: inputs.state_registration,
                    cep: inputs.cep,
                    pay_day: inputs.pay_day,
                    category: inputs.category,
                    mdr_value: inputs.mdr_value.replace(',', '.'),
                    mdr_percent: inputs.mdr_percent.replace(',', '.'),
                    mdr_advertising: inputs.mdr_advertising_value.replace(',', '.'),
                    inactivity_fee: inputs.inactivity_fee.replace(',', '.')
                })
            }
            if (res.status === 200) {
                const reqNewCompany = await api.get(`/company?tax_code=${inputs.tax_code}`);
                try {
                    for (let index = 0; index < inputs.list_group_advertising_selected.length; index++) {
                        await api.post('advertising/company-groups', {
                            id_company: reqNewCompany.data[0].id,
                            id_advertising_group: inputs.list_group_advertising_selected[index].id
                        })
                    }

                } catch (err) {
                    console.log("error =>", err);
                }
            }

            setText(res.data.message)
            setInputs(fields)
            setShowModal(true)
        } catch (error) {
            setIsError(true)
            setText('Erro ao Cadastrar Empresa. ' + error.response.data.message)
            setShowModal(true)
        }
        setLoader(false)
    }

    const getCEP = async (cep) => {

        setLoaderCEP(true)
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, { mode: 'cors' })
        const data = await response.json()

        setInputs({
            ...inputs,
            cep: data.cep,
            address: data.logradouro,
            city: data.localidade,
            district: data.bairro,
            state: data.uf,
        })
        setLoaderCEP(false)
    }

    const handleCancel = async () => {
        setShowModal(false)
        if (isError === true)
            props.history.push('/create-business');
        else
            props.history.push('/list-business');
    }

    const alteraDiaPgto = (input) => {

        let texto = input.target.value;
        texto = texto.replace('.', '');
        if (!isNaN(texto) && texto >= 0) {
            setInputs({ ...inputs, pay_day: texto })
        }
    }

    const changeMDR = async (value) => {

        let aux = value.replace(',', '')

        if (isNaN(aux)) {
            return
        }

        let tamanho_variavel_atual = inputs.mdr_value.length


        if (tamanho_variavel_atual < value.length) {
            if (value.length === 3 && (value[0] !== ',' && value[1] !== ',' && value[2] !== ',')) {
                value = value + ','
            }
        }

        if (tamanho_variavel_atual > value.length) {
            if (value.length === 3) {
                value = value.substring(0, 2)
            }
        }

        setInputs({ ...inputs, mdr_value: value })
    }

    const changeP_MDR = async (e) => {

        let aux = e.replace(',', '')
        if (isNaN(aux)) {
            return
        }

        if (e > 100) {
            return
        }

        let valueP_MDR = inputs.mdr_percent.length

        if (valueP_MDR < e.length) {
            if (e.length === 3 && (e[0] !== ',' && e[1] !== ',' && e[2] !== ',')) {
                e = e + ','
            }
        }

        if (valueP_MDR > e.length) {
            if (e.length === 3) {
                e = e.substring(0, 2)
            }
        }

        setInputs({ ...inputs, mdr_percent: e })
    }

    const changeMDRPublicidade = async (value) => {

        let aux = value.replace(',', '')

        if (isNaN(aux)) {
            return
        }

        let tamanho_variavel_atual = inputs.mdr_advertising_value.length

        if (tamanho_variavel_atual < value.length) {
            if (value.length === 3 && (value[0] !== ',' && value[1] !== ',' && value[2] !== ',')) {
                value = value + ','
            }
        }

        if (tamanho_variavel_atual > value.length) {
            if (value.length === 3) {
                value = value.substring(0, 2)
            }
        }

        setInputs({ ...inputs, mdr_advertising_value: value })
    }
    const changeInactivityFee = async (value) => {

        let aux = value.replace(',', '')

        if (isNaN(aux)) {
            return
        }

        let tamanho_variavel_atual = inputs.inactivity_fee.length

        if (tamanho_variavel_atual < value.length) {
            if (value.length === 3 && (value[0] !== ',' && value[1] !== ',' && value[2] !== ',')) {
                value = value + ','
            }
        }

        if (tamanho_variavel_atual > value.length) {
            if (value.length === 3) {
                value = value.substring(0, 2)
            }
        }

        setInputs({ ...inputs, inactivity_fee: value })
    }

    // valida CEP
    useEffect(() => {
        if (!inputs.cep) setInputs({ ...inputs, cep: '' })
    }, [inputs.cep])

    // habilita botao criar
    useEffect(() => {
        setIsDisabled(false)

        if (inputs.company_type === '' || inputs.cep === '' || inputs.tax_code.replace('_', '').length !== 18 ||
            inputs.trading_name === '' || inputs.name === '' || inputs.pay_day === '' || inputs.phone_number === '' ||
            inputs.mdr_percent === '' || inputs.mdr_value === '') {
            setIsDisabled(true)
        } else {
            if ((inputs.company_type !== '1' && inputs.company_type !== '') && inputs.id_company_partner === '') {
                setIsDisabled(true)
            }
        }
    }, [inputs])

    useEffect(() => { getCompanies() }, [])

    useEffect(() => { getGroupAdvertising() }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <button
                                onClick={() => { props.history.push('/list-business') }}
                                style={{ width: '150px', marginLeft: 10 }}
                                type="button" className="btn btn-info"
                            >
                                Voltar
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>
                                <label className="text-label-title"><i className="fas fa-building white"></i> &nbsp; Criação de Empresas</label>
                                <div className="separator" />

                                <div className="row-forms">
                                    <div className="grid">
                                        {/* tipo OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">Tipo Empresa *</label>
                                            <select
                                                value={inputs.company_type}
                                                className='form-control form-control-lg'
                                                onChange={(e) => setInputs({ ...inputs, company_type: e.target.value })}
                                            >
                                                <option value="">-- TIPO EMPRESA--</option>
                                                {
                                                    ID_PROFILE === 2 ?
                                                        <>
                                                            <option value={2}>Parceiro Comercial</option>
                                                            <option value={3}>Parceiro Fornecedor</option>
                                                        </> :
                                                        <>
                                                            <option value={1}>Master</option>
                                                            <option value={2}>Parceiro Comercial</option>
                                                            <option value={3}>Parceiro Fornecedor</option>
                                                        </>
                                                }
                                            </select>
                                        </div>
                                        {/* master */}
                                        {
                                            inputs.company_type !== '1' && inputs.company_type !== '' &&
                                            <div className="form-group">
                                                <label className="text-label">Empresa Master *</label>
                                                <select
                                                    value={inputs.id_company_partner}
                                                    className='form-control form-control-lg'
                                                    onChange={(e) => setInputs({ ...inputs, id_company_partner: e.target.value })}
                                                >
                                                    <option value="">-- EMPRESA MASTER --</option>
                                                    {
                                                        companies.length > 0 ?
                                                            companies.map(row =>
                                                                <option value={row.id}>{row.id} - {row.name}</option>
                                                            )
                                                            :
                                                            <option>-- Caregando --</option>


                                                    }
                                                </select>
                                            </div>
                                        }
                                        {/* nome fantasia OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">Nome Fantasia*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                placeholder="Nome Fantasia"
                                                value={inputs.name}
                                                onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                            />
                                        </div>
                                        {/* razao social OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">Razão Social*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Razão Social"
                                                required
                                                value={inputs.trading_name}
                                                onChange={(e) => setInputs({ ...inputs, trading_name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row-forms">
                                    <div className="grid">
                                        {/* cnpj OBRIGATÓRIO = */}
                                        <div className="form-group">
                                            <label className="text-label">CNPJ*</label>
                                            <CurrencyFormat format="##.###.###/####-##" mask="_"
                                                placeholder="CNPJ"
                                                className="form-control"
                                                value={inputs.tax_code}
                                                onValueChange={(values) => {
                                                    const { formattedValue } = values
                                                    setInputs({ ...inputs, tax_code: formattedValue })
                                                }}
                                            />

                                        </div>
                                        {/* ie */}
                                        <div className="form-group">
                                            <label className="text-label">Inscrição Estadual</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={10}
                                                placeholder="Inscrição Estadual"
                                                value={inputs.state_registration}
                                                onChange={(e) => setInputs({ ...inputs, state_registration: e.target.value })}
                                            />
                                        </div>
                                        {/* dias pgto OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">Dias de Pagamento*  </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Dias de Pagamento"
                                                value={inputs.pay_day}
                                                onChange={(e) => alteraDiaPgto(e)}
                                            />
                                        </div>
                                        {/* valor mdr */}
                                        <div className="form-group">
                                            <label className="text-label">Valor MDR</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Valor R$ MDR"
                                                value={inputs.mdr_value}
                                                onChange={(e) => changeMDR(e.target.value)}
                                                maxLength="6"
                                            />
                                        </div>
                                        {/* valor P-mdr */}
                                        <div className="form-group">
                                            <label className="text-label">Percentual MDR</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Valor % MDR"
                                                value={inputs.mdr_percent}
                                                onChange={(e) => changeP_MDR(e.target.value)}
                                                maxLength="6"
                                            />
                                        </div>
                                        {/* valor mdr Advertising */}
                                        <div className="form-group">
                                            <label className="text-label">Valor MDR Publicidade</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Valor R$ MDR Publicidade"
                                                value={inputs.mdr_advertising_value}
                                                onChange={(e) => changeMDRPublicidade(e.target.value)}
                                                maxLength="6"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-label">Taxa por Inatividade</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="0.00"
                                                value={inputs.inactivity_fee}
                                                onChange={(e) => changeInactivityFee(e.target.value)}
                                                maxLength="6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row-forms">
                                    <div className="grid">
                                        {/* cep OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">
                                                CEP*
                                                {
                                                    loaderCEP &&
                                                    <ThreeDots
                                                        color="orange"
                                                        height={25}
                                                        width={25}
                                                        type="ThreeDots"
                                                    />
                                                }
                                            </label>
                                            <CurrencyFormat format="#####-###" mask="_"
                                                placeholder="#####-###"
                                                className="form-control"
                                                value={inputs.cep}
                                                onValueChange={(values) => {
                                                    const { value } = values
                                                    if (value.length === 8) {
                                                        getCEP(value)
                                                    }
                                                    setInputs({ ...inputs, cep: value })
                                                }} />


                                        </div>
                                        {/* endereço OBRIGATÓRIO */}
                                        <div className="form-group">
                                            <label className="text-label">Endereço*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                disabled
                                                placeholder="Endereço"
                                                value={inputs.address}
                                                onChange={(e) => setInputs({ ...inputs, address: e.target.value })}
                                            />
                                        </div>
                                        {/* nro */}
                                        <div className="form-group">
                                            <label className="text-label">Número</label>

                                            <input
                                                type="number"
                                                className="form-control"
                                                maxLength="5"
                                                placeholder="Número"
                                                value={inputs.number}
                                                onChange={(e) =>
                                                    setInputs({ ...inputs, number: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row-forms">
                                    <div className="grid">
                                        {/* complemento */}
                                        <div className="form-group">
                                            <label className="text-label">Complemento</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                maxLength={50}
                                                placeholder="Complemento"
                                                value={inputs.complement}
                                                onChange={(e) => setInputs({ ...inputs, complement: e.target.value })}
                                            />
                                        </div>
                                        {/* bairro */}
                                        <div className="form-group">
                                            <label className="text-label">Bairro</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                disabled
                                                placeholder="Bairro"
                                                value={inputs.district}
                                                onChange={(e) => setInputs({ ...inputs, district: e.target.value })}
                                            />
                                        </div>
                                        {/* cidade */}
                                        <div className="form-group">
                                            <label className="text-label">Cidade</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                disabled
                                                placeholder="Cidade"
                                                value={inputs.city}
                                                onChange={(e) => setInputs({ ...inputs, city: e.target.value })}
                                            />
                                        </div>
                                        {/* estado */}
                                        <div className="form-group">
                                            <label className="text-label">Estado</label>
                                            <select
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                value={inputs.state}
                                                disabled
                                            // onChange={(e) => setInputs({ ...inputs, state: e.target.value })}
                                            >
                                                <option >UF</option>
                                                {
                                                    UF.map((value) => (
                                                        <option value={value[0]}>{value[1]}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-forms">
                                    <div className="grid">
                                        {/* fone */}
                                        <div className="form-group">
                                            <label className="text-label">Telefone</label>
                                            <CurrencyFormat format="(##) #####-####" mask="_"
                                                placeholder="(99) 9999-9999"
                                                className="form-control"
                                                value={inputs.phone_number}
                                                onValueChange={(values) => {
                                                    const { formattedValue } = values
                                                    setInputs({ ...inputs, phone_number: formattedValue })
                                                }}
                                            />
                                        </div>
                                        {/* mail */}
                                        <div className="form-group">
                                            <label className="text-label">E-Mail</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="example@example.com"
                                                required
                                                value={inputs.mail}
                                                onChange={(e) => setInputs({ ...inputs, mail: e.target.value })}
                                            />
                                        </div>
                                        {/* categoria */}
                                        <div className="form-group" >
                                            <label className="text-label">Categoria</label>
                                            <select
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                defaultValue={'option'}
                                                value={inputs.category}
                                                onChange={(e) => setInputs({ ...inputs, category: e.target.value })}
                                            >
                                                <option value="">-- CATEGORIA --</option>
                                                <option value="1">Oficinas Mecânicas</option>
                                                <option value="2">Postos de Combustível</option>
                                                <option value="3">Restaurantes</option>
                                                <option value="4">Hotéis</option>
                                                <option value="5">Serviços de Pneu</option>
                                                <option value="6">Centro Automotivo</option>
                                            </select>
                                        </div>
                                        {/* Group Advertising */}
                                        <div className="form-group" >
                                            <label className="text-label">Grupo Publicidade</label>
                                            <Multiselect
                                                hideSelectedList
                                                placeholder='GRUPO'
                                                options={dataGroupAdvertising}
                                                onSelect={(e) => setInputs({ ...inputs, list_group_advertising_selected: e })}
                                                onRemove={(e) => setInputs({ ...inputs, list_group_advertising_selected: e })}
                                                displayValue="name"
                                                showCheckbox
                                                style={{
                                                    searchBox: {
                                                        height: '45px',
                                                        padding: '0px 0px 0px 15px'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* botao */}
                                <div className="row-forms">
                                    <div className="form-group" >
                                        <button disabled={loader || isDisabled} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                            {
                                                loader
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </> : 'Criar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => handleCancel()}

                        >
                            <div className="close-rodal" onClick={() => handleCancel()}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError
                                        ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        :
                                        <div><i className="far fa-check-circle green fa-2x"></i></div>

                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>

                    </div>
                </div>
            </div>
        </div>

    )
}

