import React, { useEffect, useState } from 'react'
import Menu from '../Panel/menu'
import { DateRange } from '@material-ui/icons'
import utils from '../../../../utils/utils.js'
import api from '../../../../services/api'
import ThreeDots from 'react-loader-spinner'

export default function ReportInvoices(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [dateIni, setDateIni] = useState(utils.getFirstDayOfCurrentMonth())
    const [dateEnd, setDateEnd] = useState(utils.getLastDayOfCurrentMonth())
    const [loader, setLoader] = useState(false)

    const [data, setData] = useState([])

    const getData = async () => {
        try {
            const res = await api.post(`/report/evaluation-nps-per-partnersupplier`, {
                "date-start": dateIni,
                "date-end": dateEnd
            })

            if (res.status === 200) {
                let aux = [];
                res.data.map(item => {
                    aux.push({
                        pf: item["Parceiro Fornecedor"],
                        qnt: item["Quantidade de transações"],
                        nota_nps: item["Nota média NPS"],
                        nota_outras: item["Nota média Outras"],
                    })
                })
                setData(aux)
                setLoader(true)
            }else{
                setLoader(false)
            }

        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoader(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center align-left">

                            <DateRange fontSize="large" />
                            <input style={{ width: '15%', height: '35px', 'margin-right': '10px' }}
                                value={dateIni}
                                id="data_inicial"
                                onChange={(e) => setDateIni(e.target.value)}
                                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />

                            <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;

                            <input style={{ width: '15%', height: '35px', 'margin-right': '40px' }}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />

                            <button type="button"
                                className="btn btn-info"
                                onClick={() => getData()}
                                disabled={loader}
                                style={{ width: '100px', height: '38px' }}
                            >
                                {
                                    loader
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <i className="fas fa-search fa-rotate-90"></i>
                                }
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}>

                                <div className={data.length !== 0 ? ' invisible ' : ' row-forms '}>
                                    <div className="">Nenhum resultado encontrado!</div>
                                </div>

                                <div className={data.length === 0 ? ' invisible ' : ' row-forms '}>
                                    {
                                        loader
                                            ?
                                            <div>
                                                Buscando Transações...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <div>
                                                {/* titulo */}
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white " />&nbsp;Relatório da Nota do NPS
                                                    </label>
                                                </div>



                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="">Parceiro Fornecedor</th>
                                                            <th className="">Quantidade de transações</th>
                                                            <th className="">Nota média NPS</th>
                                                            <th className="">Nota média outra</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            data.map((row, idx) =>
                                                                <tr>
                                                                    <td>{row.pf}</td>
                                                                    <td>{row.qnt}</td>
                                                                    <td>{row.nota_nps}</td>
                                                                    <td>{row.nota_outras}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
