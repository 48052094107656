import moment from "moment"
export function RetornaValorPorPerfil(perfil_user, perfil_company, dados) {


  // console.log('dados', dados)
  // console.log('perfil_user', perfil_user)
  // console.log('perfil_company', perfil_company)

  // if (!dados) return
  if (perfil_user === 'SYS') { return dados }

  let data = []
  dados.forEach(dado => {
    if (perfil_company === 1 && data.company_type === 2) {
      data.push(dado)
    }
    if (localStorage.getItem('COMPANY_TYPE') === 2 && data.company_type === 3) {
      data.push(dado)
    }

    let data = []
    dados.forEach(dado => {
        if (perfil_company === 1 && data.company_type === 2) {
            data.push(dado)
        }
        if (localStorage.getItem('COMPANY_TYPE') === 2 && data.company_type === 3) {
            data.push(dado)
        }
    })
    console.log('data', data)
    return data
})
}

function getDate() {

    var date = new Date()

    var today = {
        'year': date.getFullYear(),
        'month': (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
        'day': date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }

    return today.year + '-' + today.month + '-' + today.day

}

function getDateBefore(days) {

    var date = new Date(new Date().getTime() - (days * 24 * 60 * 60 * 1000))

    var today = {
        'year': date.getFullYear(),
        'month': (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
        'day': date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }

    return today.year + '-' + today.month + '-' + today.day

}

/* eslint-disable */
function formatMoneyBRL(n, c, d, t) {

    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d === undefined ? "," : d,
        t = t === undefined ? "." : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

/* eslint-enable */

function listExportFormatReportInvoices() {
    return {
        'txt': 'TXT',
        'xlsx': 'Excel',
        'csv': 'CSV',
        // 'pdf': 'PDF',
        'json': 'JSON',
    }
}

function refreshPage(){
    window.location.reload();
}


function getFirstDayOfCurrentMonth() {
    var date = new Date()
    var today = {
        'year': date.getFullYear(),
        'month': (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
        'day': '01'
    }
    return today.year + '-' + today.month + '-' + today.day
}

function getLastDayOfCurrentMonth() {
    var date = new Date()
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var today = {
        'year': date.getFullYear(),
        'month': (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
        'day': moment(lastDay).format('DD')
    }
    
    return today.year + '-' + today.month + '-' + today.day
}

export default {RetornaValorPorPerfil, formatMoneyBRL, getDate, getDateBefore, listExportFormatReportInvoices, refreshPage, getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth}
