import React from 'react'
import ThreeDots from 'react-loader-spinner'

export default function ModalLoader() {

    return (
        <>
            <ThreeDots
                color="#07aecc"
                height={40}
                width={40}
                type="ThreeDots"
            />
            Carregando...
        </>

    )
}