import React, {useState, useEffect} from 'react'
import Menu from '../Panel/menu'
import Chart from 'react-apexcharts'
import {DateRange} from '@material-ui/icons'
// import Loader from '../Panel/loader'
import utils from '../../../../utils/utils.js'
import {useMediaQuery} from 'react-responsive'
import api from '../../../../services/api'
import ThreeDots from 'react-loader-spinner'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap';


export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [dateIni, setDateIni] = useState(utils.getFirstDayOfCurrentMonth())
    const [dateEnd, setDateEnd] = useState(utils.getLastDayOfCurrentMonth())
    const [loader, setLoader] = useState(false)
    const isBigScreen = useMediaQuery({query: '(min-width: 1824px)'})
    // const mock = {
    //     "grades": {
    //         "Nota 1": "0",
    //         "Nota 2": "0",
    //         "Nota 3": "0",
    //         "Nota 4": "0",
    //         "Nota 5": "0"
    //     },
    //     "average": 0
    // }
    // const [data, setData] = useState({
    //     valueEvaluation: mock
    // })
    const [dataDetails, setDataDetails] = useState({
        average: 0
    })

    const [series, setSeries] = useState([]);


    const getData = async () => {
        setLoader(true)
        try {
            const resValuesEvaluation = await api.post(`/report/evaluation-sales-commercial-partner`, {
                "date-start": dateIni,
                "date-end": dateEnd
            })

            let handleResult = [];

            if (resValuesEvaluation.status === 200) {
                console.log("AAAA")
                // data.valueEvaluation = [];
                // data.valueEvaluation = resValuesEvaluation.data;
                // dataDetails.average = resValuesEvaluation.data.average
                let aux = [];
                resValuesEvaluation.data.map(item => {
                    aux.push({
                        evaluation: item["evaluation"]["key"]
                    })
                })


                console.log('aux', aux)
            }

            setSeries(resValuesEvaluation.data);

        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoader(false)
    }

    const options = {
        chart: {
            width: '100%',
            height: '100%',
            type: "bar",
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
        },
        colors: ['#51C4C7', '#a27871'],
        fill: {
            // type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],

            }

        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '13px'
            },
        },
        stroke: {
            width: [2, 2, 2, 2, 2],
            curve: 'straight',
            // dashArray: [0, 0, 0, 0, 0]
        },

        yaxis: {
            showForNullSeries: true,

            labels: {
                style: {
                    // color: '#0abab5',
                },
            }


        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        // here
        tooltip: {
            enabled: false,
            style: {
                fontSize: '12px',
            },
            x: {
                // legend on hover
                show: false
            },
            y: {}
        },
        xaxis: {
            categories: ['Nota 01', 'Nota 02', 'Nota 03', 'Nota 04', 'Nota 05']
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu}/>
            <div className="container-panel">
                <div className="body-container">
                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                            <DateRange fontSize="large"/>
                            <input style={{width: '15%', height: '35px'}}
                                   value={dateIni}
                                   id="dateIni"
                                   onChange={(e) => setDateIni(e.target.value)}
                                   type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy"/>
                            &nbsp;&nbsp;
                            <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
                            <input style={{width: '15%', height: '35px'}}
                                   value={dateEnd}
                                   onChange={(e) => setDateEnd(e.target.value)}
                                   type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy"/>
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            <button type="button"
                                    className="btn btn-info"
                                    onClick={() => getData()}
                                    disabled={loader}
                                    style={{width: '150px', height: '38px'}}
                            >
                                {
                                    loader
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <i className="fas fa-search fa-rotate-90"></i>
                                }
                            </button>

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>
                    <div className="data">
                        <div className="panel">
                            <div className="indicator-top bold" style={{alignItems: 'center', height: '50px'}}>
                                <i className="la la-bar-chart-o blue fa-lg"></i> &nbsp; DASHBOARD - AVALIAÇÃO DE VENDAS &nbsp;
                                {
                                    loader &&
                                    <ThreeDots
                                        color="#07aecc"
                                        height={40}
                                        width={40}
                                        type="ThreeDots"
                                    />
                                }
                            </div>
                                {series && series.map((serie, index) => {
                                    const gradesData = Object.values(serie.grades).map(Number);


                                    const chartSeries = [{
                                        name: serie.evaluation.name,
                                        data: gradesData
                                    }];

                                    return (
                                        <Row key={index} className="indicator-container block">
                                            <Col md={12}>
                                                <div>{serie.evaluation.name}</div><br />
                                                <div className="dashbord" style={{width: '100%'}}>
                                                    <i className="fas fa-cubes fa-lg blue"></i>&nbsp;
                                                    {
                                                        loader ?
                                                            <ThreeDots
                                                                color="#07aecc"
                                                                height={40}
                                                                width={40}
                                                                type="ThreeDots"
                                                            />
                                                            :
                                                            <> Média: {serie.average}</>
                                                    }
                                                </div><br />

                                                <div key={serie.evaluation.key}>
                                                    <Chart
                                                        options={options}
                                                        series={chartSeries}
                                                        height={isBigScreen ? "100%" : "300px"}
                                                        type={'bar'}
                                                    />
                                                </div>
                                                <br /><br />
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
