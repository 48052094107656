import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'
import utils from '../../../../utils/utils.js'
import Moment from 'moment'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [dataCompanies, setDataCompanies] = useState([])
  const [loaders, setLoaders] = useState({
    companies: false,
    register: false
  })
  const fields = {
    idCompany: '',
    identifycator: '',
    value: 0,
    name: '',
    wallet: 0
  }
  const [inputs, setInputs] = useState(fields)
  const [isError, setIsError] = useState(false)
  const [text, setText] = useState('Crédito lançado com sucesso!')
  Moment.locale('pt')

  const [showModal, setShowModal] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [viewLastCredit, setViewLastCredit] = useState(false)
  const creditFields = {
    id: '',
    identifier: '',
    value: '',
    status: '',
    date: ''
  }
  const [infoLastCredit, setInfoLastCredit] = useState(creditFields)

  const styleInputs = {
    width: '90%'
  }

  const getCompanies = async () => {

    setLoaders({ ...loaders, companies: true })

    try {

      const res = await api.get('/company?filter=2')

      setDataCompanies(res.data)

      console.log(`companies`, res)

    } catch (error) {
      console.log("error =>", error)
    }
    setLoaders({ ...loaders, companies: false })
  }

  const generator = async () => {

    setLoaders({ ...loaders, register: true })
    setText('')
    setIsError(false)

    try {

      const res = await api.post('/wallet-entry', {

        credits: [
          {
            id_company: Number(inputs.idCompany),
            description: inputs.identifycator,
            value: Number(inputs.value)
          }
        ]

      })

      setInputs(fields)
      setText('Crédito lançado com sucesso!')
      setInfoLastCredit(creditFields)
      setViewLastCredit(false)
      console.log(`res=>`, res)


    } catch (error) {

      console.log("error =>", error.response)
      setIsError(true)
      setText(error.response.data.message)
    }
    setShowModal(true)
    setLoaders({ ...loaders, register: false })

  }

  const getLastCreditToCompany = async (idCompany) => {

    try {
      const res = await api.get(`/get-last-credit-company/${idCompany}`)
      console.log(`res aqui`, res.data)

      const resp = await api.get(`/get-commpany-info-wallet/${idCompany}`)
      console.log(`resp`, resp.data)

      if (res.data) {
        setInfoLastCredit({
          id: res.data.id,
          identifier: res.data.description,
          value: res.data.value,
          status: res.data.approved,
          date: res.data.updated_at,
          name: resp.data ? resp.data.name : '',
          wallet: resp.data ? resp.data.wallet : 0
        })

        setViewLastCredit(true)
      }

    } catch (error) {
      console.log("error =>", error)
    }
  }

  useEffect(() => {

    if (inputs.idCompany === '' || inputs.identifycator === '' || inputs.value === 0) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }

  }, [inputs])

  useEffect(() => {

    if (inputs.idCompany == '') {
      setInfoLastCredit(creditFields)
      setViewLastCredit(false)
    } else {
      console.log('inputs.idCompany', inputs.idCompany)
      getLastCreditToCompany(inputs.idCompany)
    }

  }, [inputs.idCompany])

  useEffect(() => { getCompanies() }, [])

  // useEffect(() => { console.log('viewLastCredit', viewLastCredit) }, [viewLastCredit])


  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              <section className="form-create-users" style={{ display: 'flex' }}>
                <label className="text-label-title">
                  <i className="fas fa-coins white"></i>&nbsp; Lançamentos de Créditos
                </label>
                <div className="separator" />

                <div className='two-divs-flex' style={{ display: 'flex' }}>
                  <div className='div-40' style={{ width: '40%' }}>
                    {/* nome */}
                    <div className="row-forms">
                      <div className="form-group">
                        <label className="text-label">Nome *</label>
                        <select
                          value={inputs.idCompany}
                          className='form-control form-control-lg'
                          id='inlineFormCustomSelect'
                          style={styleInputs}
                          onChange={e => setInputs({ ...inputs, idCompany: e.target.value })}
                        >
                          <option value="">{loaders.companies ? 'Carregando...' : '-- EMPRESAS --'}</option>
                          {
                            dataCompanies.map((row, idx) =>
                              <option key={idx} value={row.id}> {row.name}</option>

                            )
                          }

                        </select>
                      </div>
                    </div>
                    {/* identificador */}
                    <div className="row-forms">
                      <div className="form-group">
                        <label className="text-label">Identificador *</label>
                        <input
                          type="text"
                          style={styleInputs}
                          className="form-control"
                          placeholder="Nome..."
                          value={inputs.identifycator}
                          onChange={e => setInputs({ ...inputs, identifycator: e.target.value })}
                        />
                      </div>
                    </div>
                    {/* valor */}
                    <div className="row-forms">
                      <div className="form-group">
                        <label className="text-label">Valor *</label>
                        <CurrencyFormat style={{ color: '#4bc019', width: '90%' }}
                          currency="BRL"
                          prefix='R$ '
                          placeholder="Valor..."
                          thousandSeparator='.'
                          decimalSeparator=','
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="form-control"
                          value={inputs.value}
                          onValueChange={(values) => {
                            const { floatValue } = values
                            setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                          }}
                        />
                      </div>
                    </div>
                    {/* botao gerar saldo */}
                    <div className="form-group" >
                      <button
                        disabled={loaders.register || isDisabled}
                        onClick={() => generator()}
                        style={{ width: '50%' }}
                        type="button"
                        className="btn btn-info"
                      >
                        {
                          loaders.register
                            ?
                            <>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              &nbsp;Processando...
                            </>
                            :
                            'Gerar Crédito'
                        }
                      </button>
                    </div>
                  </div>
                  {
                    viewLastCredit &&
                    <div className='div-50' style={{ width: '60%' }}>
                      {/* nome da empresa */}
                      <div className="row-forms">
                        <div className="form-group">
                          <label className="text-label">Empresa Selecionada:</label>
                          <input
                            type="text"
                            className="form-control"
                            style={{ styleInputs, border: 'none' }}
                            value={infoLastCredit.name}
                          />
                        </div>
                      </div>
                      {/* saldo atual */}
                      <div className="row-forms">
                        <div className="form-group">
                          <label className="text-label">Saldo atual</label>
                          <input
                            type="text"
                            className="form-control"
                            style={{ styleInputs, border: 'none' }}
                            // value='Saldo atual'
                            value={utils.formatMoneyBRL(infoLastCredit.wallet)}
                          />
                        </div>
                      </div>
                      {/* ultimo lançamento */}
                      <div className="row-forms">
                        <label className="text-label">Último lançamento</label>
                        <div className="row-forms" style={{ flexDirection: 'column' }}>
                          <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                            <thead>
                              <tr role='row'>
                                <th className="">ID</th>
                                <th className="">Identificador</th>
                                <th className="">Data</th>
                                <th className="sorting_asc">Valor</th>
                                <th style={{ textAlign: 'start' }} className="sorting_asc">STATUS</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr role="row" key={infoLastCredit.id}>
                                <td className="sorting_asc">{infoLastCredit.id}</td>
                                <td style={{ textAlign: 'start' }} className="sorting_asc">
                                  {infoLastCredit.identifier}
                                </td>
                                <td style={{ textAlign: 'start' }} className="sorting_asc">
                                  {Moment(infoLastCredit.date).format('DD/MM/YYYY')}
                                </td>
                                <td className="sorting_asc bold green">R$ {utils.formatMoneyBRL(infoLastCredit.value)}</td>
                                <td style={{ textAlign: 'start' }} className="sorting_asc">
                                  {
                                    infoLastCredit.status == 0 ?
                                      <div style={{ color: 'red' }}>Negado</div>
                                      : infoLastCredit.status == 1 ?
                                        <div style={{ color: 'green' }}>Aprovado</div>
                                        : <div style={{ color: 'orange' }}>Pendente</div>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </section>

              <Rodal
                visible={showModal}
                animation="flip"
                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                showCloseButton={true}
                onClose={() => setShowModal(false)}

              >
                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="modal-error">
                  {
                    isError
                      ?
                      <>
                        <div><i className="fas fa-times error fa-2x"></i></div>
                        <div> {text}</div>
                      </>
                      :
                      <>
                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                        <div><i className="fas fa-coins green white"></i> {text}</div>
                      </>
                  }

                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                  <div className="modal-error-buttons" />
                </div>
              </Rodal>
            </div>
          </div>
        </div>
      </div >
    </div >

  )
}

