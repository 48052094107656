import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import CurrencyFormat from 'react-currency-format'

export default function App(props) {

    Moment.locale('pt')

    const [toggleMenu, setToggleMenu] = useState(false)
    const [companies, setCompanies] = useState([])
    const fields = {
        value: '',
        description: '',
        id_company: ''
    }
    const [inputs, setInputs] = useState(fields)
    const [showModalSent, setShowModalSent] = useState(false)
    const [text, setText] = useState('')
    const [loaders, setLoaders] = useState({
        suppliers: false,
        credits: false,
    })
    const [showModal, setShowModal] = useState(false)
    const [creditCompany, setCreditCompany] = useState(0)
    // const [partnerWallet, setPartnerWallet] = useState('')
    const [chargebackStatment, setChargebackStatment] = useState([])
    const [showModalHistory, setShowModalHistory] = useState(false)
    const [isError, setIsError] = useState(false)

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )

    const getCompanies = async () => {
        setLoaders({ ...loaders, users: true })

        try {
            const response = await api.get(`/company?filter=2`)
            console.log('response.data', response.data)
            setCompanies(response.data)
        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, users: false })
    }

    const returnCredit = async () => {
        setLoaders({ ...loaders, users: true })
        console.log('inputs', inputs)
        try {
            const res = await api.post(`/wallet-entry`, {
                return: inputs.value,
                id_company: inputs.id_company,
                description: inputs.description
            })
            console.log('res.data', res.data)
            setText('Saldo estornado com sucesso.')
        } catch (err) {
            setText('Não foi possível estornar o saldo. Erro ', err)
            console.log(err)
            setIsError(true)
        }
        setLoaders({ ...loaders, users: false })
        setShowModal(false);
        setShowModalSent(true)
    }

    function handleModal(idCompany, companyCredit) {
        if (showModal) {
            setInputs(fields)
        } else {
            setInputs({ ...inputs, id_company: idCompany })
        }
        setShowModal(!showModal);
        setCreditCompany(companyCredit)
    }

    const getChargebackStatement = async (idCompany) => {

        setLoaders({ ...loaders, users: true })

        try {
            const response = await api.get(`/get-available-balance-only-one-pc/${idCompany}`)
            console.log('response.data', response.data)

            if (response.data) {
                setChargebackStatment(response.data)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoaders({ ...loaders, users: false })
            setShowModalHistory(true)
        }
    }

    function handleCancel() {
        setInputs(fields)
        setShowModal(false);
        setShowModalSent(false);
        getCompanies()
    }

    function closeModalHistory() {
        setShowModalHistory(false)
        setChargebackStatment([])
    }

    useEffect(() => {
        getCompanies()
        // getPartnerWallet()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>
                                <select style={{ width: '15%' }} className="form-control">
                                    <option value="">-- PARCEIRO -- </option>
                                    {
                                        companies.length > 0 &&
                                        companies.map((user) =>
                                            <option value={user.id}>{user.name}</option>
                                        )
                                    }
                                </select>

                                <button disabled={loaders.suppliers} onClick={() => getCompanies()} style={{ marginLeft: 10, width: '120px' }} id="search-charge-back" type="button" className="btn btn-info">
                                    {
                                        loaders.suppliers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section
                                className="container-trix"
                                style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}
                            >

                                <div className="row-forms">
                                    {
                                        loaders.suppliers
                                            ?
                                            <div>
                                                Buscando Usuários...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div
                                                    className="row-forms"
                                                    style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                                >
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white "></i>
                                                        &nbsp;Gestão de Créditos
                                                    </label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc"> ID </th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo Estornado</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            companies.map((company, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{company.id}</td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                                        {company.name}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            company.wallet ?
                                                                                `R$ ${utils.formatMoneyBRL(company.wallet.total)}`
                                                                                : `R$ 0,00`
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            company.wallet ?
                                                                                `R$ ${utils.formatMoneyBRL(company.wallet.returned)}`
                                                                                : `R$ 0,00`
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc" style={{ width: '300px' }} >
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={
                                                                                        () => handleModal(
                                                                                            company.id,
                                                                                            company.wallet ?
                                                                                                company.wallet.total : 0
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="la la-close red la-lg" /> Estornar Saldo
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    onClick={() => getChargebackStatement(company.id)}
                                                                                >
                                                                                    <i className="las la-history green la-lg" /> Histórico
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>

                                                </table>

                                            </>
                                    }
                                </div>
                            </section>

                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => handleModal(null)}
                            >
                                <div className="close-rodal" onClick={() => handleModal(null)}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">Estorno de crédito</div>
                                <div className="detal-modal">
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row style={{ marginBottom: '16px' }}>
                                            <Col>
                                                {/* <label className="text-label">Valor</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.value}
                                                    onChange={(e) => setInputs({ ...inputs, value: e.target.value })}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                /> */}
                                                <label className="text-label">Valor</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    // style={{ color: '#4bc019', fontSize: '1rem', fontWeight: '500' }}
                                                    value={inputs.value}
                                                    onValueChange={(values) => {
                                                        const { floatValue } = values
                                                        setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <label className="text-label">Saldo Parceiro</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    // style={{ color: '#4bc019', fontSize: '1rem', fontWeight: '500' }}
                                                    value={creditCompany}
                                                    disabled
                                                />
                                            </Col>
                                            <Col>
                                                <label className="text-label">Limite</label>
                                                {/* <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={`R$ ${supplierWallet}`}
                                                    // onChange={(e) => setInputs({ ...inputs, valueEtrix: e.target.value })}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                    disabled
                                                /> */}
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    // style={{ color: '#4bc019', fontSize: '1rem', fontWeight: '500' }}
                                                    value={creditCompany - inputs.value}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <label className="text-label">Motivo</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    maxlength="300"
                                                    rows="5"
                                                    value={inputs.description}
                                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                                >
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </Container>

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => returnCredit()}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-success"
                                        >Estornar</button>
                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModalHistory}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => closeModalHistory()}
                            >
                                <div className="close-rodal" onClick={() => closeModalHistory()}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">Histórido de Estorno de crédito</div>
                                <div className="detal-modal container-trix" style={{ overflow: 'auto' }}>
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        {
                                            chargebackStatment.length > 0 ?
                                                <>
                                                    <table className='table display mb-4 dataTablesCard dataTable no-footer'>
                                                        <thead>
                                                            <tr role='row'>
                                                                <th className="sorting_asc"> ID </th>
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo Estornado</th>
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Data Estorno</th>
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Motivo</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                chargebackStatment.map((chargeback, idx) =>
                                                                    <tr role="row" key={idx}>
                                                                        <td className="sorting_asc">{chargeback.id}</td>
                                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                                            {chargeback.name}</td>
                                                                        <td className="sorting_asc">
                                                                            {
                                                                                `R$ ${utils.formatMoneyBRL(chargeback.value)}`
                                                                            }
                                                                        </td>
                                                                        <td className="sorting_asc">
                                                                            {Moment(chargeback.date_movement).format('DD/MM/YYYY HH:mm')}
                                                                        </td>
                                                                        <td className="sorting_asc">{chargeback.description}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                                : <div style={{ textAlign: 'center' }}><h5><strong>Sem dados a exibir</strong></h5></div>
                                        }
                                    </Container>

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => closeModalHistory()}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModalSent}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => handleCancel()}
                            >
                                <div className="close-rodal" onClick={() => { handleCancel() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <>
                                                <div><i className="fas fa-times error fa-2x"></i></div>
                                                <div> {text}</div>
                                            </>
                                            :
                                            <>
                                                <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                <div><i className="fas fa-coins green white"></i> {text}</div>
                                            </>
                                    }

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
