import React, { useState, useRef } from 'react';

const FileInputComponent = ({ onFileSelect }) => {
    const [selectedFile, setSelectedFile] = useState();
    const fileInputRef = useRef(null);

    const handleExcelFileChange = (event) => 
    {
        const file = event.target.files[0];
        setSelectedFile(file);
        onFileSelect(file);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
      };

    return (
        <div>
            <input 
                type="file"
                onChange={handleExcelFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <button onClick={handleButtonClick} style={buttonStyle}>Selecionar arquivo
            </button>
            {selectedFile && <p>Arquivo selecionado: {selectedFile.name}</p>}
        </div>
    );
};

export default FileInputComponent;

const buttonStyle = {
    cursor: 'pointer',
    border: '1px solid #0AD6D9',
    backgroundColor: '1px solid #0AD6D9',
    borderRadius: '5px',
    padding: '5px 5px',
    color: 'black',
    fontWeight: 400,
    lineHeight: 1.5
  };