import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import { Close } from '@material-ui/icons'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [categories, setCategories] = useState([])
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const [data, setData] = useState(props.location.state.data)
    const [filteredData, setFilteredData] = useState({})
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isSaveSuccess, setSaveSuccess] = useState(false)
    const [showFatherCategory, setShowFatherCategory] = useState(false)

    const getCategories = async () => {
        try {
            const res = await api.get('/category')

            if (res.data) {
                setCategories(res.data)
                console.log("Response back->", res.data)
                console.log("Response deleted_at", categories.delete)
            }
        }
        catch (error) {
            console.log("error =>", error)
        }
    }

    const edit = async () => {
        setLoader(true)
        setIsError(false)

        try {
            const res = await api.put(`/category/${filteredData.id}`, {
                name: filteredData.name,
                id_category: filteredData.id_category,
                active: filteredData.active
            })

            console.log(`res =>`, res.data)
            setText(res.data.message)
            setShowModal(true)
            setSaveSuccess(true)
        } catch (error) {
            console.log(error)
            setIsError(true)
            setShowModal(true)
            setText('Erro ao Editar Categoria...')
        }
        setLoader(false)
    }

    useEffect(() => { getCategories() }, [])

    useEffect(() => {
        setFilteredData({
            id: data.id,
            id_category: data.id_category,
            type: data.father ? 'subcategoria' : 'categoria',
            name: data.name,
            active: data.active ? '1' : '0'
        })
        console.log(`filteredData =>`, filteredData)
    }, [data])

    useEffect(() => {
        filteredData.type === 'subcategoria' ? setShowFatherCategory(true) : setShowFatherCategory(false)
    }, [filteredData.type])

    useEffect(() => {
        if (!showModal && isSaveSuccess) {
            props.history.push('/list-categories')
        }
    }, [showModal])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>
                                <label className="text-label-title"><i className="fas fa-user-plus"></i>&nbsp;Edição de Categoria</label>
                                <div className="separator" />
                                {/* linha */}
                                <div className="row-forms">
                                    {/* tipo */}
                                    <div className="form-group">
                                        <label className="text-label">Tipo*</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            value={filteredData.type}
                                            style={{ width: '350px' }}
                                            onChange={(e) => setFilteredData({ ...filteredData, type: e.target.value })
                                            }
                                        >
                                            <option > TIPO </option>
                                            <option value='categoria'>Categoria</option>
                                            <option value='subcategoria'>Subcategoria</option>
                                        </select>
                                    </div>
                                    {/* categoria pai */}
                                    {
                                        showFatherCategory ?
                                            <div className="row-forms">
                                                <div className="form-group">
                                                    <label className="text-label">Categoria Pai</label>
                                                    <select
                                                        className='form-control form-control-lg'
                                                        id='inlineFormCustomSelect'
                                                        defaultValue={'option'}
                                                        value={filteredData.id_category ? filteredData.id_category : '---'}
                                                        style={{ width: '350px' }}
                                                        onChange={(e) => setFilteredData({ ...filteredData, id_category: e.target.value })}
                                                    >
                                                        <option>CATEGORIA</option>
                                                        {
                                                            categories.map((category, index) =>
                                                                <option key={index} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div> : <></>
                                    }
                                </div>

                                {/* linha */}
                                <div className="row-forms">
                                    {/* nome */}
                                    <div className="form-group">
                                        <label className="text-label">Nome*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '350px' }}
                                            placeholder="Nome..."
                                            value={filteredData.name}
                                            onChange={(e) => setFilteredData({ ...filteredData, name: e.target.value })}
                                        />
                                    </div>
                                    {/* status */}
                                    {/* <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label">Ativo</label>
                                            <select
                                                defaultValue={'option'}
                                                className='form-control form-control-lg'
                                                id='inlineFormCustomSelect'
                                                value={data.deleted_at ? '0' : '1'}
                                                style={{ width: '350px' }}
                                                onChange={(e) => setFilteredData({ ...filteredData, active: e.target.value })}
                                            >
                                                <option >-- ATIVO --</option>
                                                <option value='1'>Sim</option>
                                                <option value='0'>Não</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row-forms" style={{ flexDirection: 'row', display: 'contents' }}>
                                    <div className="form-group" >
                                        <button disabled={loader} onClick={() => edit()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                            {
                                                loader
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </>
                                                    : 'Editar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => setShowModal(false)}
                        >
                            <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        : <div><i className="far fa-check-circle green fa-2x"></i></div>

                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div>
        </div>
    )
}

