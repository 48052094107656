import React, { useState, useEffect, ChangeEvent } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { FilterList, Close } from '@material-ui/icons'
import utils from '../../../../utils/utils.js'
import Moment from 'moment'
import { v4 as uuidv4 } from "uuid"
import './open-budgets.scss'

export default function App(props) {

    Moment.locale('pt')

    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        suppliers: false,
        send: false,
        wallet: false,
        budget: false
    })
    const fields = {
        description: '',
        name: '',
        id_company_supplier: null,
        id_selected_budget: '',
        file: {
            hash_invoice: '',
            nameFile: '',
            typeFile: '',
            extension: ''
        },
    }
    const [inputs, setInputs] = useState(fields)
    const [data, setData] = useState([])
    const [dataStates, setDataStates] = useState([])
    const [dataCities, setDataCities] = useState([])
    const [dataServices, setDataServices] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalSent, setShowModalSent] = useState(false)
    const [text, setText] = useState('')
    const [isError, setIsError] = useState(false)
    const [totalBalance, setTotalBalance] = useState({
        available: 0,
        saved: 0,
        percent: 0
    })
    const companyType = localStorage.getItem('COMPANY_TYPE')
    const [listBudgets, setListBudgets] = useState([])
    const [budgeItems, setBudgeItems] = useState([])
    const [showModalAdditionalSuppliers, setShowModalAdditionalSuppliers] = useState(false)
    const [additionalSupplier, setAdditionalSupplier] = useState([])
    const [showValue, setShowValue] = useState(true)
    const [showModalUploadError, setShowModalUploadError] = useState(false)

    // busca o valor total na carteira
    // const getWallet = async () => {

    //     try {
    //         const res = await api.get(`/get-balance`)

    //         if (res.data) {
    //             setTotalBalance({ ...totalBalance, available: res.data })
    //         }
    //     } catch (err) {
    //         console.log("error =>", err)
    //     }
    // }

    // busca o valor total na carteira
    const getWallet = async () => {
        // setLoaders({ ...loaders, wallet: true })

        try {
            const budget = await api.get(`/get-balance`)
            const saved = await getTotalBalanceSaved()
            const percent = await getTotalPercentageSaved()

            setTotalBalance({
                available: budget.data ? budget.data : 0,
                saved: saved ? saved : 0,
                percent: percent ? percent : 0
            })

        } catch (err) {
            console.log("error =>", err)
        }
        // setLoaders({ ...loaders, wallet: false })
    }

    const getTotalBalanceSaved = async () => {

        try {
            const res = await api.get(`/total-balance-saved`)

            if (res.data) {
                return res.data[0].price
            } else {
                return 0
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
            return 0
        }
    }

    const getTotalPercentageSaved = async () => {

        try {
            const res = await api.get(`/total-percentage-saved`)

            if (res.data) {
                return res.data[0].percentage
            } else {
                return 0
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
            return 0
        }
    }

    const getBudgets = async () => {
        setIsError(false)
        setLoaders({ ...loaders, budget: true })

        try {
            const res = await api.get(`budget`)

            if (res.data) {
                setListBudgets(res.data)
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({ ...loaders, budget: false })
    }

    const getSuppliers = async () => {
        console.log('companyType', companyType)
        setLoaders({ ...loaders, suppliers: true })

        try {

            const res = await api.get(`/company/get_my_suppliers`)
            // const res = await api.get(`/company?filter=3&state=${dataStates[0]}&city=${dataCities[0]}&service=${dataServices}`)

            if (res.data) {
                setData(res.data)
            }

        } catch (error) {

            console.log("error =>", error.response.data.message)
        }

        setLoaders({ ...loaders, suppliers: false })

    }

    const sendOperations = async () => {

        setLoaders({ ...loaders, send: true })

        try {
            const res = await api.post('/budget', { inputs, budgeItems, additionalSupplier })
            setShowModal(false)
            setShowModalSent(true)
            setText(res.data.message)
        } catch (error) {
            setIsError(true)
            setText(error.response.data.error)
            setShowModalSent(true)
        }

        setLoaders({ ...loaders, send: false })
        setInputs(fields)
    }

    const listStates = async () => {

        // setLoaders({ ...loaders, approval: true })

        let aux = []
        let inArray = false

        data.forEach((company) => {
            aux.forEach((state) => {
                if (company.state === state) inArray = true
            })
            if (!inArray) aux.push(company.state)
        })
        setDataStates(aux)
    }

    const listCities = async (state) => {


        let aux = []
        let auxCp = []

        data.forEach((company) => {
            if (company.state === state) {
                let inArray = false

                aux.forEach((city) => {
                    if (company.city === city) inArray = true
                })

                if (!inArray) aux.push(company.city)
                auxCp.push(company)
            }
        })
        setDataCities(aux)
    }

    const addNewBudgeItem = async () => {
        let _bugdeItems = [...budgeItems];
        _bugdeItems.push({
            id: uuidv4(),
            description: "",
            unity: "",
            quantity: "",
            unity_price: 0,
            total_price: 0,
            hour: "",
            group: "P"
        });
        setBudgeItems(_bugdeItems);
    }

    const removeBudgeItem = (id) => {

        let _bugdeItems = [...budgeItems]
        _bugdeItems = _bugdeItems.filter((budge) => budge.id !== id)
        setBudgeItems(_bugdeItems)
    }

    const handleBudgeChange = (id, event) => {
        //find the index to be changed
        const index = budgeItems.findIndex((b) => b.id === id)

        let _bugdeItems = [...budgeItems]
        _bugdeItems[index][event.target.name] = event.target.value
        setBudgeItems(_bugdeItems)
    }

    const convertBase64 = async (file) => {

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const sendFile = async (e) => {

        if (e.target.files[0]) {

            const file = e.target.files[0]
            const amountFile = file.size
            const typeFile = file.type
            const [extension, ...nameParts] = file.name.split('.').reverse()
            const base64 = await convertBase64(file)

            if (amountFile > 1024 * 1024) {
                setIsError(true)
                setText('O arquivo deve ter no máximo 1MB')
                setShowModalUploadError(true)
                return
            }

            if (typeFile != 'application/pdf') {
                setIsError(true)
                setText('O arquivo não é um arquivo .PDF válido')
                setShowModalUploadError(true)
                return
            }

            setInputs({
                ...inputs,
                file: {
                    hash_invoice: base64,
                    nameFile: nameParts[0],
                    typeFile: typeFile,
                    extension: extension
                }
            })

        } else {
            setInputs(fields)
            console.log('no file selected....')
        }

    }

    const handleAdditionalSupplierChange = (id, event) => {

        if (id != inputs.id_company_supplier) {
            //find the index to be changed
            let _additionalSupplier = [...additionalSupplier]
            _additionalSupplier = _additionalSupplier.filter((as) => as.id !== id)

            if (event.target.checked) {
                _additionalSupplier.push({
                    id: id
                })
            }

            setAdditionalSupplier(_additionalSupplier)
        }
    }

    function hideValue() {
        if (showValue) {
            setShowValue(false)
        } else {
            setShowValue(true)
        }
    }

    function closeModalNewBudget() {
        setInputs(fields)
        setAdditionalSupplier([])
        setBudgeItems([]);
        setShowModal(false);
        setShowModalSent(false)
    }

    useEffect(() => { listStates() }, [data])

    useEffect(() => {
        getWallet()
        getSuppliers()
        listStates()
        getBudgets()
    }, [])

    useEffect(() => {
        if (!showModalSent) {
            setIsError(false)
        }
    }, [showModalSent])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">
                            <div className="filters-menu">
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>
                                <select onChange={(e) => listCities(e.target.value)} style={{ width: '15%' }} className="form-control">
                                    <option value="">ESTADOS</option>
                                    {
                                        dataStates.map(row =>
                                            <option value={row}>{row}</option>
                                        )
                                    }
                                </select>
                                &nbsp;&nbsp;
                                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                                &nbsp;&nbsp;
                                <select style={{ width: '15%' }} className="form-control">
                                    <option value="">-- CIDADES --</option>
                                    {
                                        dataCities.map(row =>
                                            <option value={row}>{row}</option>
                                        )
                                    }
                                </select>
                                &nbsp;&nbsp;
                                <i className="fas fa-sort fa-rotate-90" fontSize="default" ></i>
                                &nbsp;&nbsp;

                                <select onChange={(e) => setDataServices(e.target.value)} style={{ width: '15%' }} className="form-control">
                                    <option value="">-- SERVIÇOS --</option>
                                    <option >Oficinas Mecânicas</option>
                                    <option >Postos de Combustível</option>
                                    <option >Restaurantes</option>
                                    <option >Hotéis</option>
                                    <option >Serviços de Pneu</option>
                                    <option >Centro Automotivo</option>
                                </select>

                                <button disabled={loaders.suppliers} onClick={() => getSuppliers()} style={{ marginLeft: 10, width: '120px' }} id="search-charge-back" type="button" className="btn btn-info">
                                    {
                                        loaders.suppliers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>

                            <section className="container-trix" style={{ padding: '0.5%' }}>
                                <div className="row-forms" style={{ height: '100%' }}>
                                    <div className="filters" style={{ justifyContent: 'space-around' }}>

                                        {/* saldo disponivel */}
                                        <div className="grid">
                                            <div className="form-group">
                                                <label
                                                    className="text-label green"
                                                    style={{
                                                        marginLeft: '0px',
                                                        marginBottom: '5px'
                                                    }}>
                                                    Saldo Disponível
                                                </label>
                                                <div className="separator-widget" />
                                                <div className="balance">
                                                    <i className="flaticon-381-network green "></i>
                                                    &nbsp;
                                                    {
                                                        showValue ?
                                                            <>
                                                                R$ xxxxxxx
                                                                <i class="fa fa-eye-slash" aria-hidden="true" onClick={e => hideValue()} style={{ color: "#0ccbec" }} />
                                                            </>

                                                            : <>
                                                                R$ {utils.formatMoneyBRL(totalBalance.available)}
                                                                <i
                                                                    class="fa fa-eye"
                                                                    aria-hidden="true"
                                                                    onClick={e => hideValue()}
                                                                    style={{ color: "green" }}
                                                                />
                                                            </>
                                                    }
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        {/* total economizado */}
                                        <div className="grid">
                                            <div className="form-group">
                                                <label
                                                    className="text-label green"
                                                    style={{
                                                        marginLeft: '0px',
                                                        marginBottom: '5px'
                                                    }}> Total   Economizado

                                                </label>
                                                <div className="separator-widget" />
                                                <div className="balance">
                                                    &nbsp;
                                                    <i class='fas' style={{ color: "green" }}>&#xf555;</i>
                                                    &nbsp;
                                                    R$ {utils.formatMoneyBRL(totalBalance.saved)}
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        {/* percentual economizado */}
                                        <div className="grid">
                                            <div className="form-group">
                                                <label
                                                    className="text-label green"
                                                    style={{
                                                        marginLeft: '0px',
                                                        marginBottom: '5px'
                                                    }}> Percentual Economizado

                                                </label>
                                                <div className="separator-widget" />
                                                <div className="balance">
                                                    &nbsp;
                                                    <i class='fa fa-btc' style={{ color: "green" }} />
                                                    &nbsp;
                                                    {totalBalance.percent}<i class='fa fa-percent' />
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>

                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >
                                <div className="row-forms">
                                    {
                                        loaders.suppliers
                                            ?
                                            <div>
                                                Buscando Fornecedores...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            data.length > 0
                                                ?
                                                <>
                                                    <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                        <label className="text-label-title">
                                                            <i className="la la-users la-lg" />
                                                            &nbsp;Lista de Fornecedores
                                                        </label>
                                                    </div>
                                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                        <thead>
                                                            <tr role='row'>
                                                                {/* <th className="sorting_asc"> ID </th> */}
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Fornecedor</th>
                                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Telefone</th>
                                                                <th className="sorting_asc">Data - Hora</th>
                                                                <th className="sorting_asc">Selecionar</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                data.map((row, idx) =>
                                                                    <tr role="row" key={idx}>
                                                                        {/* <td className="sorting_asc">{row.id}</td> */}
                                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">{row.phone_number}</td>
                                                                        <td className="sorting_asc">{Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                                        <td className="sorting_asc" style={{ width: '200px' }} >
                                                                            <div className="items"><i onClick={() => {
                                                                                setInputs({ ...inputs, id_company_supplier: row.id, name: row.name });
                                                                                setShowModal(true);
                                                                            }} style={{ cursor: 'pointer' }} className="la la-plus-circle blue la-2x"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>

                                                    </table>

                                                </>
                                                :
                                                !loaders.suppliers &&
                                                <>
                                                    <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                                    <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                                </>

                                    }

                                </div>
                            </section>

                            {/* modal de criação do orçamento */}
                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{
                                    width: '60%',
                                    height: '80%',
                                    bottom: '80%',
                                    overflowY: 'scroll',
                                    marginTop: '7%'
                                }}
                                showCloseButton={true}
                                onClose={() => { closeModalNewBudget() }}
                            >
                                <div className="close-rodal" onClick={() => { closeModalNewBudget() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">ORÇAMENTO</div>

                                <div className="detal-modal" style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* titulo orçamento */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label blue">
                                                {inputs.name ? `Orçamento ${inputs.name}` : 'Novo Orçamento'}
                                            </label>
                                        </div>
                                    </div>
                                    {/* adicionar item */}
                                    <div className="row-forms" style={{ flexDirection: 'row', marginRight: 20, width: '100%' }}>
                                        {/* botao */}
                                        <div className="form-group" style={{ marginRight: 20 }}>
                                            <div>
                                                <button
                                                    onClick={() => addNewBudgeItem()}
                                                    style={{ width: '100%' }} type="button" className="btn btn-info"
                                                >
                                                    <i className="la la-plus-circle white la-2x" style={{ marginRight: 2 }} />
                                                    Item
                                                </button>
                                            </div>

                                        </div>
                                        {/* form de item */}
                                        <div className="form-group" style={{ width: '80%' }}>
                                            {
                                                budgeItems.map((budge) => (
                                                    <div
                                                        className="row-forms"
                                                        style={{
                                                            border: '1px solid #17bcda',
                                                            borderRadius: 5,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            padding: 3,
                                                            marginBottom: 3,
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <div className="row-forms" style={{ marginBottom: -10, flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                            {/* descrição */}
                                                            <div className="form-group" style={{ width: '100%', marginRight: 5 }}>
                                                                <label className="text-label">Descrição</label>
                                                                <input
                                                                    type="text"
                                                                    name='description'
                                                                    className="form-control"
                                                                    placeholder="Descrição"
                                                                    onChange={(e) => handleBudgeChange(budge.id, e)}
                                                                    value={budge.description}
                                                                    style={{ height: 30 }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                            {/* produto/serviço */}
                                                            <div className="form-group" style={{ width: '30%', marginRight: 5 }}>
                                                                <label className="text-label">Tipo de Compra</label>
                                                                <select name='group'
                                                                    onChange={(e) => handleBudgeChange(budge.id, e)}
                                                                    className="form-control"
                                                                    style={{ height: 30 }}
                                                                // value={budge.description}
                                                                >
                                                                    <option value="P">Produto</option>
                                                                    <option value="S">Serviço</option>
                                                                </select>
                                                            </div>
                                                            {/* unidade/hora */}
                                                            {/* {budge.group === "P" ? */}
                                                            <div className="form-group" style={{ width: '30%', marginRight: 5 }}>
                                                                <label className="text-label">Unidade</label>
                                                                <input
                                                                    type="text"
                                                                    name='unity'
                                                                    className="form-control"
                                                                    placeholder="Unid."
                                                                    onChange={(e) => handleBudgeChange(budge.id, e)}
                                                                    value={budge.unity}
                                                                    style={{ height: 30 }}
                                                                />
                                                            </div>
                                                            {/* :
                                <div className="form-group" style={{ width: '30%', marginRight: 5 }}>
                                  <label className="text-label">Hora</label>
                                  <input
                                    type="text"
                                    name='hour'
                                    className="form-control"
                                    placeholder="Hora"
                                    onChange={(e) => handleBudgeChange(budge.id, e)}
                                  />
                                </div> */}
                                                            {/* } */}
                                                            {/* quantidade */}
                                                            <div className="form-group" style={{ width: '30%', marginRight: 5 }}>
                                                                <label className="text-label">Quantidade</label>
                                                                <input
                                                                    type="text"
                                                                    name='quantity'
                                                                    className="form-control"
                                                                    placeholder="Qtde."
                                                                    onChange={(e) => handleBudgeChange(budge.id, e)}
                                                                    value={budge.quantity}
                                                                    style={{ height: 30 }}
                                                                />
                                                            </div>
                                                            {/* valor unitario */}
                                                            {/* <div className="form-group" style={{ width: '20%', marginRight: 5 }}>
                                <label className="text-label">Valor Unit.</label>
                                <input
                                  type="text"
                                  name='unity_price'
                                  className="form-control"
                                  placeholder="Valor Unit."
                                  disabled
                                // onChange={(e) => handleBudgeChange(budge.id, e)}
                                />
                              </div> */}
                                                            {/* valor total */}
                                                            {/* <div className="form-group" style={{ width: '20%', marginRight: 5 }}>
                                <label className="text-label">Valor Tot.</label>
                                <input
                                  type="text"
                                  name='total_price'
                                  className="form-control"
                                  placeholder="Total"
                                  // onChange={(e) => handleBudgeChange(budge.id, e)}
                                  disabled
                                />
                              </div> */}
                                                            {/* botao remover item */}
                                                            <div className="form-group" style={{ width: '15%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                <button
                                                                    onClick={(e) => removeBudgeItem(budge.id, e)}
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    style={{ width: 'auto', marginTop: 10 }}
                                                                >
                                                                    <i className="la la-trash white" style={{ fontSize: 20 }}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                                    {/* upload pdf, orçamento e add fornecedor */}
                                    <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                        {/* upload pdf */}
                                        <div style={{ width: "20%" }}>
                                            <label className="text-label">Upload em .PDF*</label>
                                            <div className="form-group" style={{ width: '100%' }}>
                                                <input id='selecao-arquivo'
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => sendFile(e)}
                                                />
                                                <label
                                                    for='selecao-arquivo'
                                                    className='btn-upload-pdf'
                                                    style={{
                                                        border: '1px solid darkgrey',
                                                        height: 40,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: 11
                                                    }}
                                                >
                                                    {/* <p style={{ fontSize: 11 }}> */}
                                                    {inputs.file.nameFile ?
                                                        inputs.file.nameFile + '.' + inputs.file.extension
                                                        : 'Sem Arquivo'}
                                                    {/* </p> */}
                                                </label>
                                            </div>
                                        </div>
                                        {/* orçamento */}
                                        <div style={{ width: "20%" }}>
                                            {/* <div className="form-group" style={{ width: '15%' }}> */}
                                            <label className="text-label">Orçamento*</label>
                                            <select
                                                onChange={(e) =>
                                                    setInputs({ ...inputs, id_selected_budget: e.target.value })
                                                }
                                                value={inputs.id_selected_budget}
                                                className="form-control"
                                            >
                                                <option value="">Selecionar</option>
                                                {
                                                    listBudgets.length > 0 &&
                                                    listBudgets.map(row =>
                                                        <option value={row.id}>{row.id + ' - ' + row.request}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        {/* add fornecedores */}
                                        <div style={{ width: "20%" }}>
                                            {/* <div style={{ marginTop: 25, width: '18%' }}> */}
                                            <label className="text-label">Outros Fornecedores</label>
                                            <button onClick={() => setShowModalAdditionalSuppliers(true)} type="button" className="btn btn-info">
                                                <i className="la la-plus-circle white la-2x" />
                                                Fornecedores
                                            </button>
                                        </div>
                                    </div>

                                    {/* descrição */}
                                    <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5, width: '100%' }}>
                                        {/* descrição */}
                                        <div className="form-group" style={{ width: '100%' }}>
                                            <label className="text-label">Descrição*</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Descrição..."
                                                value={inputs.description}
                                                onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    {/* botao de enviar orçamento */}
                                    <div
                                        className="row-forms"
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginTop: 15
                                        }}
                                    >
                                        <div className="form-group" >
                                            <button
                                                disabled={loaders.send || inputs.description === ""}
                                                onClick={() => sendOperations()}
                                                type="button" className="btn btn-info">

                                                {
                                                    loaders.send
                                                        ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;Processando...
                                                        </>
                                                        :
                                                        'Enviar Orçamento'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Rodal>

                            {/* modal mensagem de retorno da api */}
                            < Rodal
                                visible={showModalSent}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => closeModalNewBudget()}
                            >
                                <div className="close-rodal" onClick={() => { closeModalNewBudget() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <div><i className="fas fa-times red fa-2x"></i></div>
                                            :
                                            <div><i className="far fa-check-circle green fa-2x"></i></div>
                                    }
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            {/* modal mensagem de erro de upload */}
                            < Rodal
                                visible={showModalUploadError}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModalUploadError(false)}
                            >
                                <div className="close-rodal" onClick={() => { setShowModalUploadError(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    <div><i className="fas fa-times red fa-2x"></i></div>
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            {/* modal de fornecedores adicionais */}
                            <Rodal
                                visible={showModalAdditionalSuppliers}
                                animation="flip"
                                customStyles={{ width: '50%', height: '80%', overflowY: 'scroll', }}
                                showCloseButton={true}
                                onClose={() => setShowModalAdditionalSuppliers(false)}
                            >
                                <div className="close-rodal" onClick={() => { setShowModalAdditionalSuppliers(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">Adicionar Fornecedores</div>

                                <div className="detal-modal">

                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                        <thead>
                                            <tr role='row'>
                                                {/* <th className="sorting_asc"> ID </th> */}
                                                <th className="sorting_asc">Selecionar</th>
                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                <th style={{ textAlign: 'start' }} className="sorting_asc">Cidade</th>
                                                <th className="sorting_asc">Estado</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                data.map((row, idx) =>
                                                    <tr role="row" key={idx}>
                                                        {/* <td className="sorting_asc">{row.id}</td> */}
                                                        <td className="sorting_asc" style={{ width: '200px' }} >
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => handleAdditionalSupplierChange(row.id, e)}
                                                            />
                                                        </td>
                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">{row.city}</td>
                                                        <td className="sorting_asc">{row.state}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div >
        </div >
    )
}

