import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Loader from '../Panel/loader';
import Rodal from 'rodal';
import { FilterList, Close } from '@material-ui/icons';
import Pagination from '../../Pagination';
import util from '../../../../utils/utils';

export default function App(props) {

    const title = 'Cashback - Conversão';
    const titleName = `Gerenciar ${title}`;
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        conversion: false,
        delete: false
    })
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState([])
    const [update, setUpdate] = useState({})
    const [filter, setFilter] = useState({
        date_start: util.getFirstDayOfCurrentMonth(),
        date_final: util.getLastDayOfCurrentMonth()
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const get = async (filter) => {
        setLoaders({ ...loaders, conversion: true })
        try {
            let filters = '?'

            if (filter.date_start) {
                filters += `date-start=${filter.date_start}&`
            }
            if (filter.date_final) {
                filters += `date-end=${filter.date_final}&`
            }
            filters = filters.substring(filters[0], filters.length - 1)
            let res = await api.get(`cashback/conversion${filters}`)
            setData(res.data);
            setCurrentPage(1);
        } catch (err) {
            console.log(err);
        }

        setLoaders({ ...loaders, conversion: false });
    }

    useEffect(() => { get(filter) }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">

                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <input
                                    type="Date"
                                    className="form-control"
                                    required
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.date_start}
                                    onChange={(e) => setFilter({ ...filter, date_start: e.target.value })}
                                />

                                <input
                                    type="Date"
                                    className="form-control"
                                    required
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.date_final}
                                    onChange={(e) => setFilter({ ...filter, date_final: e.target.value })}
                                />

                                <button disabled={loaders.business} onClick={() => get()} style={{
                                    width: '150px',
                                    marginLeft: 10
                                }} type="button" className="btn btn-info">
                                    {
                                        loaders.business ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Processando...
                                            </>
                                            : 'Pesquisar'
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            {
                                loaders.conversion ?
                                    <div className="loader">
                                        <h5>
                                            <Loader />
                                            <br />
                                            Carregando {title}...
                                        </h5>
                                    </div>
                                    /* voltar para data.length > 0 */
                                    : data.length === 0 ?
                                        <section className="form-create-users" style={{ display: 'flex' }}>
                                            <label className="text-label-title"><i className="la la-money-bill-wave"></i>&nbsp;{titleName}</label>
                                            <section style={{ display: 'flex', marginBottom: '5px' }}>
                                                <button className="btn btn-dark mb-1 ml-1">Empresa</button>
                                                <button className="btn btn-dark mb-1 ml-1">Programa</button>
                                            </section>
                                            {/* <div className="separator" /> */}
                                            <div style={{ display: 'flex' }} >
                                                <div style={{ width: '85%', flex: 1 }}>
                                                    <div className="row-forms" >
                                                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                            <thead>
                                                                <tr role='row'>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Selecionar</th>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Programa</th>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Pontos</th>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Taxa</th>
                                                                    <th style={{ textAlign: 'start' }} className="sorting_asc">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    currentPosts.map((row, idx) =>
                                                                        <tr role="row" key={idx}>
                                                                            <td className="sorting_asc"><input type='checkbox' /></td>
                                                                            <td className="sorting_asc">{row.company}</td>
                                                                            <td className="sorting_asc">{row.company_trading_name}</td>
                                                                            <td className="sorting_asc">{row.mdr_percent}</td>
                                                                            <td className="sorting_asc">{row.total_mdr_percent}</td>
                                                                            <td className="sorting_asc">{row.total}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div style={{ width: '15%', marginLeft:'5px', marginTop:'10%' }}>
                                                <button className="btn btn-primary mb-1 ml-1">Converter</button>
                                                <button className="btn btn-danger mb-1 ml-1">Cancelar</button>
                                                </div>
                                                
                                            </div>
                                            <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />
                                        </section>
                                        :
                                        <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                            <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                        </section>
                            }

                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        update.deleted_at ?
                                            <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" /> :
                                            <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                    }
                                    <p>Tem certeza que deseja alterar o estado do {title} {update.name}?</p>
                                    <br />

                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        update.deleted_at ?
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" />
                                                Tem certeza que deseja REATIVAR a {title}
                                                <br />
                                                <h4 className="green">{update.name}</h4> ?
                                            </> :
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                                Tem certeza que deseja DESATIVAR esse {title}?
                                                <br />
                                                <h4 className="red">{update.name}</h4>
                                            </>
                                    }

                                    <br />
                                    <button
                                        disabled={loaders.delete}
                                        onClick={() => console.log(update.id)}
                                        style={{ width: '25%' }}
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        {
                                            loaders.delete
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                update.deleted_at ? 'Sim, reativar' : 'Sim, desativar'
                                        }
                                    </button>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}