import React, { useEffect, useState } from 'react';
import Menu from '../Panel/menu';
import { DateRange, Close } from '@material-ui/icons';
import utils from '../../../../utils/utils.js';
import api from '../../../../services/api';
import ThreeDots from 'react-loader-spinner';
import Moment from 'moment';
import Rodal from 'rodal';
import ReactStars from 'react-rating-stars-component';

export default function ReportInvoices(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [dateIni, setDateIni] = useState(utils.getFirstDayOfCurrentMonth())
    const [dateEnd, setDateEnd] = useState(utils.getLastDayOfCurrentMonth())
    const [loader, setLoader] = useState(false)
    const [showModalEvaluation, setShowModalEvaluation] = useState(false)
    const fieldsRatings = {
        rating_price: 0,
        rating_satisfaction: 0,
        rating_experience: 0,
        rating_quality: 0,
        rating_speed_response: 0,
        rating_time: 0
      }

    const [data, setData] = useState([])
    const [dataEvaluation, setDataEvaluation] = useState(fieldsRatings)

    const getData = async () => {
        try {
            const res = await api.post(`/report/evaluation-listing`, {
                "date-start": dateIni,
                "date-end": dateEnd
            })
            if (res.status === 200) {
                // debugger
                setData(res.data.data)
                setLoader(true)
            } else {
                setLoader(false)
            }

        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoader(false)
    }

    function handleModalEvaluation(row, showModal) {
        setShowModalEvaluation(showModal);
        if (showModal) {
            setDataEvaluation({
                ...fieldsRatings,
                id_da_compra: row.id_da_compra,
                rating_price: row.rating_price,
                rating_satisfaction: parseInt(row.rating_satisfaction),
                rating_experience: row.rating_experience,
                rating_quality: row.rating_quality,
                rating_speed_response: row.rating_speed_response,
                rating_time: row.rating_time
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center align-left">

                            <DateRange fontSize="large" />
                            <input style={{ width: '15%', height: '35px', 'margin-right': '10px' }}
                                value={dateIni}
                                id="data_inicial"
                                onChange={(e) => setDateIni(e.target.value)}
                                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />

                            <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;

                            <input style={{ width: '15%', height: '35px', 'margin-right': '40px' }}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />

                            <button type="button"
                                className="btn btn-info"
                                onClick={() => getData()}
                                disabled={loader}
                                style={{ width: '100px', height: '38px' }}
                            >
                                {
                                    loader
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <i className="fas fa-search fa-rotate-90"></i>
                                }
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}>

                                <div className={data.length !== 0 ? ' invisible ' : ' row-forms '}>
                                    <div className="">Nenhum resultado encontrado!</div>
                                </div>

                                <div className={data.length === 0 ? ' invisible ' : ' row-forms '}>
                                    {
                                        loader
                                            ?
                                            <div>
                                                Buscando Avaliações...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <div>
                                                {/* titulo */}
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white " />&nbsp;Relatório de avaliações
                                                    </label>
                                                </div>



                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="">#ID Compra</th>
                                                            <th className="">Data e hora compra</th>
                                                            <th className="">Parceiro Comercial</th>
                                                            <th className="">Nome do Produto</th>
                                                            <th className="">Valor Compra</th>
                                                            <th className="">Avaliações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            data.map((row, idx) =>
                                                                <tr>
                                                                    <td>{row.id_da_compra}</td>
                                                                    <td>{Moment(row.data_da_compra).format('DD/MM/YYYY hh:mm:ss')}</td>
                                                                    <td>{row.fornecedor}</td>
                                                                    <td>{row.produto}</td>
                                                                    <td>R$ {utils.formatMoneyBRL(row.current_price)}</td>
                                                                    <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i onClick={() => { handleModalEvaluation(row, true); }}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="fa fa-star fa-fw yellow la-2x"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </div>
                            </section>
                            <Rodal
                                visible={showModalEvaluation}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(50%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModalEvaluation(false)}

                            >
                                <div className="close-rodal" onClick={() => {
                                    setShowModalEvaluation(false)
                                }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">Avaliação - Id Compra #{dataEvaluation.id_da_compra}</div>
                                <div className="detal-modal">
                                    <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                        <thead>
                                            <tr role='row'>
                                                <th className="sorting_asc">Preço</th>
                                                <th className="sorting_asc">Satisfação</th>
                                                <th className="sorting_asc">Experiência</th>
                                                <th className="sorting_asc">Qualidade</th>
                                                <th className="sorting_asc">Velocidade</th>
                                                <th className="sorting_asc">Tempo</th>
                                            </tr>
                                            <tr role="row">
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={fieldsRatings.rating_price}
                                                        size={15}
                                                        edit={false}
                                                        activeColor={'#ffd700'}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={dataEvaluation.rating_satisfaction}
                                                        size={15}
                                                        edit={false}
                                                        activeColor={'#ffd700'}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={dataEvaluation.rating_experience}
                                                        size={15}
                                                        edit={false}
                                                        activeColor={'#ffd700'}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={dataEvaluation.rating_quality}
                                                        size={15}
                                                        edit={false}
                                                        activeColor={'#ffd700'}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={dataEvaluation.rating_speed_response}
                                                        size={15}
                                                        edit={false}
                                                        activeColor={'#ffd700'}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        value={dataEvaluation.rating_time}
                                                        size={15}
                                                        edit={false}
                                                        color={'#e4e5e9'}
                                                    />
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                                {/* botoes */}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        onClick={() => setShowModalEvaluation(false)}
                                        style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
