export const TOKEN_KEY = "@random-Token"
export const MAIL_USER = "@trix"
export const NAME_PROFILE = "@user"
export const COMPANY_ID = "0"
export const isAuthenticated = () => localStorage.getItem('TOKEN_KEY') !== null

export const getToken = () => localStorage.getItem('TOKEN_KEY')
export const getProfile = () => localStorage.getItem('NAME_PROFILE')
export const getIdProfile = () => localStorage.getItem('ID_PROFILE')
export const getMail = () => localStorage.getItem('MAIL_USER')
export const getCompanyType = () => localStorage.getItem('COMPANY_TYPE')
export const getCompanyId = () => localStorage.getItem('COMPANY_ID')
export const getMasterId = () => localStorage.getItem('ID_MASTER')
export const getCompanyAdvertisiments = () => localStorage.getItem('COMPANY_ADVERTASIMENT')

export const login = (token, user) => {
  localStorage.setItem('TOKEN_KEY', token)
  localStorage.setItem('MAIL_USER', user.email)
  localStorage.setItem('NAME_PROFILE', user.name)
  localStorage.setItem('ID_PROFILE', user.profile_user.id)
  localStorage.setItem('COMPANY_TYPE', user.company.company_type)
  localStorage.setItem('ID_COMPANY', user.id_company)
  localStorage.setItem('ID_MASTER', user.company.id_company_partner)
  localStorage.setItem('ID_USER', user.id)
  localStorage.setItem('COMPANY_ADVERTASIMENT', JSON.stringify(user.company.advertisings))
}

export const logout = (props) => {
  localStorage.removeItem('TOKEN_KEY')
  localStorage.removeItem('MAIL_USER')
  localStorage.removeItem('NAME_PROFILE')
  localStorage.removeItem('COMPANY_TYPE')
  localStorage.removeItem('COMPANY_ID')
  localStorage.removeItem('ID_MENU')
  localStorage.removeItem('ID_ITEM')
  localStorage.removeItem('ID_COMPANY')
  localStorage.removeItem('ID_MASTER')
  localStorage.removeItem('ID_USER')
  localStorage.removeItem('COMPANY_ADVERTASIMENT')

  props.props.history.push('/login')

}
