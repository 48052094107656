import React, { useState, useEffect } from 'react';
import Menu from '../Panel/menu';
import api from '../../../../services/api';
import Rodal from 'rodal';
import { Close } from '@material-ui/icons';
import utilsSelect from '../../../../utils/selects';

export default function App(props) {
   
   const title = 'Plano';
   const titleName = `Criação da ${title}`;
   const [toggleMenu, setToggleMenu] = useState(false);
   const fields = {
      name: '',
      period: '',
      value: 0,
      description: '',
      active: '1'
   }
   const [inputs, setInputs] = useState(fields);
   const [dataPeriod, setDataPeriod] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [isError, setIsError] = useState(false);
   const [isSaveSuccess, setSaveSuccess] = useState(false)
   const [loader, setLoader] = useState(false);
   const [text, setText] = useState('');
   const [isDisabled, setIsDisabled] = useState(true);
   const [list_select_yes_or_no, setListYesOrNo] = useState([]);

   const getPeriod = async () => {
      try {
         let res = await api.get(`advertising/plans-period`)
         const objAux = [];
         for (var member in res.data) {
            objAux.push({ Name: member, Value: res.data[member] })
         }
         setDataPeriod(objAux);
         //console.log("res period: ", res.data);
      } catch (error) {
         console.log("Error: ", error);
      }

   }

   const create = async () => {
      setLoader(true)
      setIsError(false)

      try {
         let res = await api.post("/advertising/plans", {
            name: inputs.name,
            period: inputs.period,
            value: inputs.value,
            description: inputs.description,
            active: inputs.active
         });
         setText(res.data.message ?? res.statusText);
         setInputs(fields);
         setShowModal(true);
         setSaveSuccess(true);

      } catch (error) {
         setIsError(true);
         let msgError = '';
         let idx = 1;
         for (var member in error.response.data.errors) {
            msgError += `\n Erro[${idx}][${member}]: ${error.response.data.errors[member]}`;
            idx ++;
         }
         setText(`Erro ao cadastrar ${title}. \n` + msgError ?? error.response.statusText);
         setShowModal(true);
         setSaveSuccess(false);
      }
      setLoader(false)
   }

   useEffect(() => {
      if (!showModal && isSaveSuccess) {
          props.history.push('/list-advertising-plan')
      }
  }, [showModal])


   // habilita botao criar
   useEffect(() => {
      setIsDisabled(false)

      if (inputs.name === '' || inputs.description === '' || (inputs.value === '' || inputs.value < 0) || inputs.period === '0') {
         setIsDisabled(true)
      }
   }, [inputs])

   useEffect(() => { getPeriod() }, [])
   useEffect(() => { setListYesOrNo(utilsSelect.SelectYesOrNo) }, [])

   return (
      <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
         <Menu props={props} toggleMenu={toggleMenu} />
         <div className="container-panel">
            <div className="body-container">

               <nav className="menu-container">
                  <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                     <div className="bar">
                        <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                        <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                        <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                     </div>
                  </div>

                  <div className="menu-center">
                     <button
                        onClick={() => { props.history.push('/list-advertising-plan') }}
                        style={{ width: '150px', marginLeft: 10 }}
                        type="button" className="btn btn-info"
                     >
                        Voltar
                     </button>
                  </div>

                  <div className="menu-right">
                     <i className="fas fa-bell orange fa-lg"></i>
                  </div>
               </nav>

               <div className="data">
                  <div className="panel">
                     <section className="form-create-users" style={{ height: '95%' }}>
                        <label className="text-label-title"><i className="fas fa-plus white"></i> &nbsp; {titleName}</label>
                        <div className="separator" />

                        <div className="row-forms">
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Nome*  </label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome..."
                                    value={inputs.name}
                                    onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <div className="form-group">
                                 <label className="text-label">Valor R$*  </label>
                                 <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Valor R$..."
                                    value={inputs.value}
                                    onChange={(e) => setInputs({ ...inputs, value: e.target.value })}
                                    setIsDisabled={false}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Description */}
                              <div className="form-group">
                                 <label className="text-label">Descrição*</label>
                                 <textarea
                                    className="form-control"
                                    value={inputs.description}
                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                 />
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Period */}
                              <div className="form-group">
                                 <label className="text-label">Período *</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, period: e.target.value }) }
                                 >
                                    <option value='0' selected> -- Escolha uma opção -- </option>
                                    {
                                       dataPeriod.map((item) =>
                                          <option key={item.Name} value={item.Name}>
                                             {item.Value}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                           <div className="row-forms">
                              {/* Active */}
                              <div className="form-group">
                                 <label className="text-label">Ativo</label>
                                 <select
                                    defaultValue={'option'}
                                    className='form-control'
                                    id='inlineFormCustomSelect'
                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                 >
                                    {
                                       list_select_yes_or_no.map((item, index) =>
                                          <option key={index} value={item.value} selected={item.value === 1}>
                                             {item.text}
                                          </option>
                                       )
                                    }
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* botao */}
                        <div className="row-forms">
                           <div className="form-group" >
                              <button disabled={loader || isDisabled} onClick={() => create()} style={{ width: '17%' }} type="button" className="btn btn-info">
                                 {
                                    loader
                                       ?
                                       <>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          &nbsp;Processando...
                                       </> : 'Criar'
                                 }
                              </button>
                           </div>
                        </div>
                     </section>
                  </div>

                  <Rodal
                     visible={showModal}
                     animation="flip"
                     customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                     showCloseButton={true}
                     onClose={() => setShowModal(false)}

                  >
                     <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                     </div>
                     <div className="modal-error">
                        {
                           isError
                              ?
                              <div><i className="fas fa-times red fa-2x"></i></div>
                              :
                              <div><i className="far fa-check-circle green fa-2x"></i></div>

                        }
                        <div>{text}</div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                        <div className="modal-error-buttons" />
                     </div>
                  </Rodal>

               </div>
            </div>
         </div>
      </div>

   )
}

